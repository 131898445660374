import styled from "@emotion/styled"
import { Box, Checkbox, Radio, TextField, Typography } from "@mui/material"

export const ReviewModalTitle = styled(Typography)`
color: #000;
text-align: right;
font-family: Tajawal;
font-size: 28px;
font-style: normal;
font-weight: 500;
line-height: normal;
@media (max-width: 768px) {
  font-size: 20px;
  width: 150px;
}
`

export const ReviewModalProfileCard = styled(Box)`
width: 361px;
height: 132px;
flex-shrink: 0;
border-radius: 10px;
background: rgba(180, 151, 90, 0.08);
margin-top: 26px;
@media (max-width: 768px) {
  margin-top: 30px;
  width: 100vw;
}
`

export const ReviewModalCity = styled(Typography)`
color: rgba(52, 49, 42, 0.70);
text-align: right;
font-family: Tajawal;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
`
export const ReviewModalVerifiedLawyer = styled(Typography)`
color: var(--Main, #B4975A);
text-align: right;
font-family: Tajawal;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
`
export const ReviewModalName = styled(Typography)`
color: var(--Dark, #1F2732);
text-align: right;
font-family: Tajawal;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
`

export const ReviewModalRequired = styled(Typography)`
color: #3A3A3A;
text-align: right;
font-family: Tajawal;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
`

export const ReviewModalHired = styled(Typography)`
color: #000;
text-align: right;
font-family: Tajawal;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
`
export const StyledCheckbox = styled(Checkbox)({
    '& .MuiSvgIcon-root': { 
      width: '30px',
      height: '30px',
      color: '#ccc'
    },
  })

export const CommentCount = styled(Typography)`
color: #000;
text-align: right;
font-family: Tajawal;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
`  

export const ReviewCommentInput = styled(TextField)`
max-width: 960px;
border-radius: 4px;
height: 120px;
margin-top: 12px;
background-color: #FFF;
border: ${({ isFocused }) => isFocused ? "2px solid var(--Secondary,#825925);" : '2px solid var(--Secondary,#E3E1DC);'} 
& fieldset{
  height: 120px;
}
& .MuiInputLabel-root {
  color:  ${({ value }) => value ? "#1F2732" : 'rgba(31, 39, 50, 0.50)'};
  text-align: right;
  font-family: Tajawal;
  font-size:  ${({ value }) => value ? "12px" : '18px'};18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transform: ${({ value }) => value ? 'translate(9px, 3px)' : 'translate(10px, 13px)'}; translate(10px, 13px);

  &.Mui-focused {
    text-align: right;
    color: #1F2732;
            font-family: Tajawal;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            transform: translate(9px, 3px);
            height: '16px' 
  }
}

& .MuiOutlinedInput-root {
  & fieldset {
    border: 0px solid #E3E1DC; /* Apply border style from CustomTextField */
  }
  &:hover fieldset {
    border: 0px solid #E3E1DC; /* Maintain border on hover */
  }
  &.Mui-focused fieldset {
    border: 0px solid #825925; // If you want to keep the border consistent, use the same color as the normal state
  }
  input {
    font-family: Tajawal;
    padding: 16.5px 14px 0;
    box-shadow: 200px 200px 100px white inset; /* From CustomTextField */
  }
@media (max-width: 768px) {
  width: 320px;
  height: 190px;
  & fieldset{
    height: 10px;
    width: 320px;
  }
}
`

export const ReviewModalNoName = styled(Typography)`
color: #000;
text-align: right;
font-family: Tajawal;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
@media (max-width: 768px) {
  font-size: 16px;
}
`

export const ReviewModalRadioText = styled(Typography)`
color: #000;
text-align: right;
font-family: Tajawal;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 5px;
`

export const StyledRadio = styled(Radio)({
    'width': '20px',
    'height': '20px',
    '& .MuiSvgIcon-root': { 
      width: '20px',
      height: '20px',
      color: '#B4975A'
    },
  })

export const ReviewModalTerms =styled(Typography)`
color: #3A3A3A;
text-align: right;
font-family: Tajawal;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
`

export const ReviewModalSuccess = styled(Typography)`
color: #000;
text-align: center;
font-family: Tajawal;
font-size: 38px;
font-style: normal;
font-weight: 500;
line-height: normal;
@media (max-width: 768px) {
  font-size: 28px;
}
`

export const ReviewModalTermsLink = styled.a`
color: var(--Secondary, #825925);
font-family: Tajawal;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
text-decoration: none;
`