import React, { use, useEffect, useState } from 'react'
import { Grid, Typography, useMediaQuery } from "@mui/material"
import { getTopLawyerList } from 'api/LawyerGuide'
import TopLawyer from "./TopLawyer"
import { TopLawyersGrid, TitleTopLawyers ,TopLawyersWrapper } from 'components/QuestionsList/QuestionsListStyles'
import { useSearchParams } from 'react-router-dom'

const TopLawyersBox = ({ setError }) => {
  const [lawyersList, setLawyersList] = useState([])
  const isMobile = useMediaQuery('(max-width:768px)')
  const [searchParams] = useSearchParams()
  const isTest = searchParams.get('isTest') === 'true'
  useEffect(() => {
    const fetchLawyersList = async () => {
      try {
        const list = await getTopLawyerList(isTest)
        setLawyersList(list)

      } catch (err) {
        setError(err.message)
      }
    }

    fetchLawyersList()
  }, [])

  return (

    <TopLawyersWrapper >
      <TitleTopLawyers mt='55px' mb={isMobile? '37px':'57px'}>
      المحامين الأكثر مشاركة!
      </TitleTopLawyers>  
      <TopLawyersGrid mr={isMobile? '-20px': '0px'} >
        {lawyersList?.result?.map((lawyer, index) => (
          <TopLawyer key={index} lawyer={lawyer} />
        ))}
      </TopLawyersGrid>
    </TopLawyersWrapper>

  )
}
export default TopLawyersBox