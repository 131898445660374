import React from 'react'
import rtlPlugin from "stylis-plugin-rtl"
import { CacheProvider } from "@emotion/react"
import createCache from "@emotion/cache"
import { prefixer } from 'stylis'

const cacheRTL = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin]
})

const RTLProvider = ({ children }) => {
    return (
        <>
        <CacheProvider value={cacheRTL}>
            <div dir="rtl">
                {children}
            </div>
        </CacheProvider>
        </>
    )
}

export default RTLProvider
