import React from 'react'
import Layout from '../Layout/Layout'
import HomeSection from '../components/Home'
import FooterSection from 'Layout/Footer'

const Home = () => {
  return (
    <Layout backgroundC={'#f1f1f1'}>
      <HomeSection/>
      <FooterSection/>
    </Layout>
  )
}
export default Home
