import { ContentItem, GridItem, TitleItem, DateItem, QuestionLink } from 'components/NewQuestions/NewQuestionsStyles'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import { is } from '@babel/types'

const QuestionItem = ({ title, content, date, questionLinkText, isQuestionList, questionLink }) => {

    const isMobile = useMediaQuery('(max-width:768px)')
    const navigate = useNavigate()
    const truncateContent = (str, maxLength) => {
        return str.length > maxLength ? str.substring(0, maxLength - 1) + '...' : str
    }
    const truncatedContent = truncateContent(content, isMobile ? 45 : 120 )

    return (
        <GridItem maxWidth = {isQuestionList ? '861px !important' : 'unset'} minWidth = {isQuestionList ? 'unset!important' : '100% !important'}>
            <Grid display='flex' gap='18px'>
                <img src='./NavbarLogo.svg' alt='logo' width='70px'/>
                <DateItem>|  {date}</DateItem>
            </Grid>
            <TitleItem mt='29px' onClick={() => {
                navigate(`../question/${questionLink}`)
                window.scrollTo(0, 0)
            }}>{title}</TitleItem>
            <ContentItem mt='14px'>{truncatedContent}</ContentItem>
            <QuestionLink  onClick={() => {
                navigate(`../question/${questionLink}`)
                window.scrollTo(0, 0)
            }}display='flex' gap='14px' mt={isMobile? '30.5px':'20px'}>
                {questionLinkText}
                <img src='./arrow-left.svg' alt='arrow' width='7px' />
            </QuestionLink>
        </GridItem>
    )
}

export default QuestionItem