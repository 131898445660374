import { useMediaQuery } from '@mui/material'
import FooterSection from 'Layout/Footer'
import Layout from 'Layout/Layout'
import LawyersProfileScreen from 'components/LawyersProfile/LawyersProfileScreen'
import React from 'react'

const LawyersProfile = () => {
    const isMobile = useMediaQuery('(max-width:768px)')
    return (
        <Layout isWhiteBackground={true}>
            <LawyersProfileScreen />
            <FooterSection />
        </Layout>
    )
}

export default LawyersProfile