import { Box, Grid, useMediaQuery } from '@mui/material'
import PasswordTextField from 'components/FormComponents/PasswordTextField'
import { ButtonText, Container, CustomButton } from 'components/Login/LoginStyles'
import React, { useState } from 'react'
import { ResetPasswordText } from './ResetPasswordStyles'
import LoadingBackDrop from 'components/Loading/LoadingBackDrop'
import { useLocation, useNavigate } from 'react-router-dom'
import { resetPassword } from 'api/auth'

const ResetPasswordBox = ({ setError, setSuccess, onLoaded }) => {
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const navigate = useNavigate()
    const location = useLocation()
    const gridStyles = { display: 'flex', justifyContent: 'center', alignItems: 'center' }
    const isMobile = useMediaQuery('(max-width:600px)')

    const onResetPassword = async () => {
        if (password !== confirmPassword || password === '' || confirmPassword === '') {
            setError('كلمة المرور غير متطابقة')
            return false
        }
        if (password.length < 8) {
            setError('كلمة المرور يجب الا تقل عن 8 احرف')
            return false
        }
        setLoading(true)
        try {
            await resetPassword({ mobile_number: location.state.mobile_number, otp: location.state.otp, newPassword: password })
            setSuccess('تم تغيير كلمة المرور بنجاح')
            setTimeout(() => {
                navigate('/login')
            }, 3000)
            setLoading(false)
        } catch (error) {
            setError(error.message)
            setLoading(false)
        }
    }

    return (
        <Box position='relative' maxWidth="790px" maxHeight="100" mx='auto' zIndex={10}>
            <Container sx={{ zIndex: '10 !important', position: 'relative' }}>
                <Grid container justifyContent='center' alignItems='center' paddingX={isMobile ? '32px' : 0}>
                    {!isMobile && <Grid item xs={12} md={12} justifyContent='center' marginTop='80px' sx={gridStyles}>
                        <ResetPasswordText>يرجى إدخال كلمة المرور الجديدة الخاصة بك</ResetPasswordText>
                    </Grid>}
                    <Grid item xs={12} md={12} justifyContent='center' marginTop='65px' sx={gridStyles}>
                        <PasswordTextField label='كلمة المرور الجديدة' value={password} setValue={(e) => setPassword(e.target.value)} CustomWidth={isMobile ? '300px' : '380px'} />
                    </Grid>
                    <Grid item xs={12} md={12} mt='40px' sx={gridStyles}>
                        <PasswordTextField label='تأكيد  كلمة المرور الجديدة' value={confirmPassword} setValue={(e) => setConfirmPassword(e.target.value)} CustomWidth={isMobile ? '300px' : '380px'} />
                    </Grid>

                    <Grid item xs={12} md={12} mt='48px' mb='196px' sx={gridStyles}>
                        <CustomButton onClick={onResetPassword} CustomColor='#825925' CustomWidth={isMobile ? '300px' : '380px'}>
                            <ButtonText>تأكيد كلمة المرور</ButtonText>
                            <img src='login-arrow.svg' />
                        </CustomButton>
                    </Grid>
                </Grid>
            </Container>
            {!useMediaQuery('(max-width:990px)') && <Box overflow={'overlay'} mt={-12} mr={-30}>
                <img src='login-background.svg' alt='forgetPassword' onLoad={onLoaded} />
            </Box>}
            <LoadingBackDrop loading={loading} />
        </Box>
    )
}

export default ResetPasswordBox