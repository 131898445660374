import React from 'react'
import { LawyerGrid, LawyerTitle, SignupSuccessBox, SignupSuccessHeading, SignupSuccessSubHeading } from './LawyerStyles'
import { Grid, useMediaQuery, Typography, Box } from '@mui/material'
import { Container } from 'react-bootstrap'
import LawyerBoxItem from '../LawyerComponents/Menubox'
import { useAuth } from 'context/auth'

const LawyerScreen = () => {
  const gridStyles = { display: 'flex', justifyContent: 'center', alignItems: 'center' }
  const isMobile = useMediaQuery('(max-width:768px)')
  const { user } = useAuth()
  const isUserApproved = user?.status === 'active'
  const message = user?.status === 'suspended'? "الحساب تم ايقافه لمعرفة السبب برجاء التواصل معنا" : "سوف نقوم بدراسة الطلب والتواصل معك عبر البريد الالكتروني."
  const header = user?.status === 'suspended'? "الحساب معلق" : "تم إرسال الطلب !"
  return (
    <Box style={{ margin: (isMobile ? "" : '0 60px')}} >
      <Grid container minHeight={isMobile ? 'calc(100vh - 599px)' : 'calc(100vh - 266px)'} alignContent={'flex-start'} mb={'106px'} paddingTop={isMobile ? '60px' : '100px'}>
        <LawyerTitle mb={isMobile ? "" : "88px"}>حسابي</LawyerTitle>
        <Box width='100%'>
          <Box display='flex' justifyContent='center' alignItems='center'>
            {(!isUserApproved) && <SignupSuccessBox mb={isMobile ? '20px' : '117px'} mt={isMobile ? '31px' : '54px'}>
              <SignupSuccessHeading m='auto' mb={isMobile ? '14px' : '16px'}>{header}</SignupSuccessHeading>
              <SignupSuccessSubHeading m='auto'>{message}</SignupSuccessSubHeading>
            </SignupSuccessBox>}
          </Box>
        </Box>
        <LawyerGrid container>
          <LawyerBoxItem disabled={(!isUserApproved)} navigateTo='../account' icon={'../user-lawyer.svg'} text={'إعدادات الحساب'} mobileIcon={32} desktopIcon={36} />
          <LawyerBoxItem disabled={(!isUserApproved)} navigateTo='../edit-account'  icon={'../clipboard.svg'} text={'ملفي التعريفي'} mobileIcon={30} desktopIcon={36} />
          <LawyerBoxItem disabled={(!isUserApproved)} navigateTo='../answered-questions'  icon={'../pencil.svg'} text={'مشاركاتي'} mobileIcon={30} desktopIcon={46} />
          <LawyerBoxItem disabled={(!isUserApproved)} navigateTo='../new-questions' icon={'../logo_min.svg'} text={'أسئلة تنتظرك!'} mobileIcon={28} desktopIcon={42} />
        </LawyerGrid>
      </Grid>
    </Box>
  )
}
export default LawyerScreen