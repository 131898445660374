import styled from "@emotion/styled"
import { Button, TextField, Typography, Grid, Link, Select, InputLabel, Box, MenuItem } from "@mui/material"

export const TitleItem = styled(Typography)`
font-family: Tajawal;
font-size: 20px;
font-weight: 700;
line-height: 24px;  
letter-spacing: 0em;
text-align: right;
color: #000000;
`

export const TitleItemLight = styled(TitleItem)`
font-weight: 400;
font-size: 15px;
line-height: 18px;  
`

export const LinkNav = styled(Link)`
font-family: Tajawal;
font-size: 18px;
font-weight: 500;
line-height: 21px;
color: #B4975A;
text-decoration: none;
cursor: pointer;
@media (max-width: 768px) {

  }
`

export const LinkNavDarker = styled(LinkNav)`
font-size: 16px;
line-height: 19px;
color: #825925;
@media (max-width: 768px) {

  }
`

export const SelectSpeciality = styled(Select)`
background: #FFF;
border-radius: 4px;
font-family: Tajawal;
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0px;
text-align: right;
color: #1F273280;
width: calc(100vw - 77px);
max-width: 302px;
height: 50px;
margin: 0;
& fieldset{
  border: 2px solid #E3E1DC;
  top: -2px;
  & legend {
    display: none;
  }
}
& svg {
  color: #DADADA;
}
& .MuiSelect-select{
  display: grid;
  align-items: center;
  justify-content: start;
  padding-top: 24px;
  color: #1F2732;
  font-weight: 500;
  font-size: 18px;
}
@media (max-width: 768px) {
  max-width: 402px;
}
@media (max-width: 360px) {
  width: calc(100vw - 60px);
}
`

export const CustomInputLabel = styled(InputLabel)`
overflow: unset;
font-family: Tajawal;
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0px;
text-align: right;
color: #1F273280;
&.MuiInputLabel-shrink {
  transform: translate(10px, 0px);
  font-size: 12px;
}
`

export const MenuItemCustom = styled(MenuItem)`
font-family: Tajawal;
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0px;
text-align: right;
color: #1F2732;
}
`

export const GridItem = styled(Grid)`

display: grid;
justify-content: flex-start;
gap: 10px;
padding: 40px;
margin-top: 40px;
width: 100% !important;
max-width: 100%;
background-color: #FBFAF8;
@media (max-width: 768px) {
  padding: 30px;
  margin-top: 30px;
}
@media (max-width: 360px) {
  padding: 20px;
}
`

export const PageTitle = styled(Typography)`
color: #1F2732;
font-family: Tajawal;
font-size: 30px;
font-weight: 700;
line-height: 36px;
letter-spacing: 0em;
margin-top: 29px;
@media (max-width: 768px) {
    margin-top: 33px;
    font-size: 20px;
    font-weight: 500;
}
`

export const GridContainer = styled(Grid)`
display: grid;
margin-bottom:20px;
align-items: center;
align-content: center;
@media (max-width: 768px) {

}
`

export const SpecialityItem = styled(Box)`
width: fit-content;
padding: 0 20px;
height: 42px;
border-radius: 40px;
border: 1px solid #B4975A;
gap: 15px;
color: #B4975A;
backgroud: transparent;
font-family: Tajawal;
font-size: 14px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: center;
display: flex;
align-items: center;
justify-content: space-between;
@media (max-width: 768px) {
  width: 100%;
  max-width: 140px;
}

`

export const AddButton = styled(Button)`
width: 106px;
height: 48px;
border-radius: 40px;  
color: #FFFFFF;
background: #B4975A;
font-family: Tajawal;
font-size: 18px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: center;
:hover{
  background: #B4975A;
}
@media (max-width: 768px) {
  width: 100%;
}
`

export const ButtonSubmit = styled(Button)`
width: 380px;
height: 48px;
border-radius: 40px;  
color: #FFFFFF;
background: #825925;
font-family: Tajawal;
font-size: 20px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
margin: 26px 0 100px;
:hover{
  background: #825925;
}
@media (max-width: 768px) {
  width: calc(100vw - 77px);
  margin: 26px auto 100px;
}
@media (max-width: 360px) {
  width: calc(100vw - 60px);
}
`

export const InputLarge = styled(TextField)`
width: calc(100vw - 300px);
max-width: 960px;
height: 190px;
margin-top: 12px;
background-color: #FFF;
& fieldset{
  border: 2px solid #E3E1DC;
  height: 195px;
  top: -2px;
  & legend {
    display: none;
  }
}
& .MuiFormLabel-root{
  font-family: Tajawal;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: right;
  color: #1F273280; 
}
& input, textarea {
  font-family: Tajawal;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #1F2732;
  font-weight: 500;
  font-size: 18px;
}
&  textarea{
  padding: 16.5px 0;
}
& .MuiInputLabel-shrink {
  transform: translate(10px, 3px);
  font-size: 12px;
}
@media (max-width: 768px) {
  & .MuiInputBase-root{
    height: 100%;
    textarea{
     height: 100%!important;
    }
  }
  width: calc(100vw - 120px);
  height: 430px;
  & fieldset{
    height: 435px;
  }
}
@media (max-width: 360px) {
  width: calc(100vw - 60px);
}
`

export const InputSmall = styled(InputLarge)`
width: calc(100vw - 77px);
max-width: 302px;
height: 50px;
margin: 0;
& input {
  padding: 16.5px 14px 0 14px;
  -webkit-box-shadow:200px 200px 100px white inset; 
  box-shadow: 200px 200px 100px white inset;
}
& fieldset{
  height: 52px;
}
& .MuiInputLabel-shrink {
  transform: translate(10px, 3px);
}
@media (max-width: 768px) {
  max-width: 402px;
  height: 50px;
  & fieldset{
    height: 55px;
  }
}
@media (max-width: 360px) {
  width: calc(100vw - 60px);
}
`

export const CertificateItem = styled(Grid)`
display: grid;
width: 100%;
justify-content: space-between;
`

export const CertificateText = styled(Typography)`
font-family: Tajawal;
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0em;
text-align: right;
color: #1F2732;
word-break: normal;
`

export const CertificateTextLight = styled(Typography)`
font-family: Tajawal;
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: right;
color: #00000066;
@media (max-width: 768px) {
  margin-right: 20px;
}
`

export const AddCertTextLight = styled(Typography)`
font-family: Tajawal;
font-size: 13px;
font-weight: 400;
line-height: 15px;
text-align: right;
color: #000000;
margin: 38px 0 12px;
`

export const CertificateDelete = styled(Button)`
border: none;
color: #B4975A;
font-family: Tajawal;
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0em;
text-align: right;
gap: 3px;
@media (max-width: 768px) {
  margin-right: 12px;
}
`