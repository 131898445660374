import React from 'react'
import { Grid, useMediaQuery } from '@mui/material'
import { CertificateItem, CertificateText, CertificateTextLight, CertificateDelete } from 'components/EditAccount/EditAccountStyles'

const Experience = ({ company, position, startDate, endDate, onDelete }) => {

    const isMobile = useMediaQuery('(max-width:768px)')
    return (
        <CertificateItem >
            <Grid display={isMobile ? 'grid' : 'flex'} justifyContent='flex-start' gap={isMobile ? '10px' : '5vw'} alignItems='center' width='fit-content' justifyItems='start'>
                <Grid display='flex' gap='10px' width='200px'>
                    <img src='./arrow-bolditem.svg' alt='ArrowBold' width={10} />
                    <CertificateText >{company}</CertificateText>
                </Grid>
                <CertificateText mr={isMobile?'20px':''}  width='170px'>{position}</CertificateText>
                <CertificateTextLight minWidth='100px' >{startDate} - {endDate}</CertificateTextLight>
                <CertificateDelete onClick={onDelete} >
                    <img src='./delete.svg' alt='DeleteIcon' width={14} />
                    حذف 
                </CertificateDelete>
            </Grid>
            <hr style={{ borderTop: '1px solid #E3E1DC', opacity: 1, margin: '13px 0' }} />
        </CertificateItem>
    )
}

export default Experience