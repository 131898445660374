import React, { use, useEffect, useState } from 'react'
import {
    Box,
    Button,
    ThemeProvider,
    createTheme,
    useMediaQuery,
    MenuItem,
    FormControl,
    Grid,
    Typography,
    Checkbox,
    InputLabel,
    Select,
    ListItemText,
    Paper,
    List,
    ListItem
} from '@mui/material'

import { Container } from 'react-bootstrap'
import {
    FilterApplyButton,
    TitleGridPopup,
    PopupTitle
} from 'components/LawyersListing/LawyersListingStyle'
import { DeletePopupWrapper } from 'components/Account/AccountStyles'
import { CustomInputLabel } from 'components/EditAccount/EditAccountStyles'
import RTLProvider from 'helpers/RTLProvider'
import QuestionSearch from 'components/QuestionComponents/QuestionSearch'
import { getQuestionList, getAnsweredQuestionsCountFilter } from 'api/questions'
import QuestionItem from 'components/QuestionComponents/Question'
import TopLawyersBox from 'components/QuestionComponents/TopLawyersBox'
import { TopLawyersQuestion, FilterPopup, ListItemCustom, SelectFilter, InputLabelCustom, PaginationText, HelpButton, HelpText, FirstButton, NextButton, PreviousButton, TitleText, TitleSubText, BottomSection, HelpTextLight, HelpButtonLong } from 'components/QuestionsList/QuestionsListStyles'
import { categories } from 'constants/user'
import { useNavigate, useSearchParams } from 'react-router-dom'
import LoadingBackDrop from 'components/Loading/LoadingBackDrop'

const guideTheme = createTheme({
    typography: {
        fontFamily: 'Tajawal, Arial',
    },
})

const QuestionsListSection = () => {
    const [questionsList, setQuestionsList] = useState([])
    const [searchSpecializations, setSearchSpecializations] = useState(JSON.parse(sessionStorage.getItem('quesitonsSpecializations')) || [])
    const [filterChanged, setFilterChanged] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(16)
    const [totalQuestions, setTotalQuestions] = useState(0)
    const [searchValue, setSearchValue] = useState(sessionStorage.getItem('quesitonsSearchValue') || '')
    const [error, setError] = useState('')
    const [anchor, setAnchor] = useState(null)
    const [loading, setLoading] = useState(false)
    const isMobile = useMediaQuery('(max-width:768px)')
    const open = Boolean(anchor)
    const [searchParams] = useSearchParams()
    const isTest = searchParams.get('isTest') === 'true'
    const id = open ? 'simple-popup' : undefined
    const navigate = useNavigate()
    const deletePopupOpen = async (event) => {
        event.stopPropagation()
        setAnchor(anchor ? null : event.currentTarget)
        document.body.style.setProperty('overflow', (anchor ? 'unset' : 'hidden'))
    }
    const handleClose = () => {
        setAnchor(null)
    }
    const handleChange = (event) => {
        const value = event.target.value
        setSearchSpecializations(typeof value === 'string' ? value.split(',') : value)
    }

    const handleToggle = (value) => {
        const currentIndex = searchSpecializations.indexOf(value)
        const newChecked = [...searchSpecializations]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        setSearchSpecializations(newChecked)
    }

    useEffect(() => {
        if (open) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = 'auto'
        }
        return () => {
          document.body.style.overflow = 'auto'
        }
      }, [open])
      
    useEffect(() => {
        const fetchQuestionList = async () => {
            setLoading(true)
            try {
                const params = {
                    isTest
                }
                if (searchValue !== '') {
                    params.question = searchValue
                }
                if (searchSpecializations !== '' && searchSpecializations.length !== 0) {
                    params.specialization = searchSpecializations
                }

                const totalCountFetch = await getAnsweredQuestionsCountFilter(params)
                setTotalQuestions(totalCountFetch[0].count)
                setLoading(false)
            } catch (err) {
                setLoading(false)
                setError(err.message)
            }
        }

        fetchQuestionList()

    }, [searchValue, filterChanged])

    useEffect(() => {
        const fetchQuestionList = async () => {
            setLoading(true)
            try {
                const params = {
                    "pageIndex": currentPage,
                    "pageSize": pageLimit,
                    isTest
                }

                if (searchValue !== '') {
                    params.question = searchValue
                }
                if (searchSpecializations !== '' && searchSpecializations.length !== 0) {
                    params.specialization = searchSpecializations
                }

                const list = await getQuestionList(params)
                setQuestionsList(list.result)
                setLoading(false)

            } catch (err) {
                setError(err.message)
                setLoading(false)
            }
        }

        fetchQuestionList()
    }, [currentPage, pageLimit, searchValue, filterChanged])

    const applyFilters = async () => {
        setFilterChanged(prev => !prev) // Toggle the state to trigger useEffect
        sessionStorage.setItem('quesitonsSpecializations', JSON.stringify(searchSpecializations))
    }

    const handleFirstPage = () => {
        setCurrentPage(1)
    }

    const handlePreviousPage = () => {
        setCurrentPage(current => Math.max(1, current - 1))
    }

    const handleNextPage = () => {
        setCurrentPage(current => current + 1)
    }

    const handleSearch = async (value) => {
        setSearchValue(value)
        setCurrentPage(1)
    }

    const clearFilter = async () => {
        setSearchSpecializations([])
        setSearchValue('')
        applyFilters()
        sessionStorage.removeItem('quesitonsSpecializations')
        sessionStorage.removeItem('quesitonsSearchValue')
    }
    const formattedQuestions = questionsList.map(question => {
        const date = new Date(question.created_at)
        const day = date.toLocaleDateString('en-US', { day: 'numeric' })
        const month = date.toLocaleDateString('ar-EG', { month: 'long' })
        const year = date.toLocaleDateString('en-US', { year: 'numeric' })
        const formattedDate = `${month} ${day}-${year}`
        return {
            ...question,
            created_at: formattedDate
        }
    })

    let startIndex = (currentPage - 1) * pageLimit + 1
    if (totalQuestions == 0) {
        startIndex = 0
    }
    const endIndex = Math.min(startIndex + pageLimit - 1, totalQuestions)
    const isLastPage = currentPage * pageLimit >= totalQuestions
    return (
        <ThemeProvider theme={guideTheme}>
            <Box  style={{
                margin: (isMobile ? "" : '0 60px'),
                padding: (isMobile ? "0 20px" : ""),  
                minHeight: (isMobile ? 'calc(100vh - 604px)' : 'calc(100vh - 258px)')
                }}>
                <Box display='flex' mt={isMobile? '48px': '98px'} mb={'50px'} justifyContent={isMobile ? 'space-between' : 'flex-start'} gap='10px' alignItems='center'>
                    <TitleText>الاسئلة والأجوبة</TitleText>
                    <TitleSubText onClick={() => navigate('/add-question')}> + اسأل محامي مجاناً</TitleSubText>
                </Box>

                <Grid display={isMobile ? 'block' : 'flex'} justifyContent='space-between'>
                    <Box
                        sx={{
                            ml: { lg: '20px', xs: '20px' },
                        }}
                        width={'100%'}
                    >
                        <QuestionSearch
                            onSearch={handleSearch}
                            onFilterClick={deletePopupOpen}
                            onFilterClearClick={clearFilter}
                            isFilterClearVisible={searchSpecializations.length != 0 || searchValue}
                        />
                        <PaginationText>
                            عرض {startIndex} - {endIndex} من {totalQuestions} سؤال و جواب
                        </PaginationText>
                        {Array.isArray(formattedQuestions) ? formattedQuestions?.map((question, index) => (
                            <QuestionItem
                                key={index}
                                questionLinkText={'اقرأ إجابة محامي مرخص'}
                                title={question.question}
                                content={question.other_details}
                                date={question.created_at}
                                questionLink={question.question_id}
                                isQuestionList />
                        )) : {}}
                        <Box mt={'24px'} mb={isMobile ? '67px' : '81px'} display={'flex'} flexWrap={'nowrap'}>
                            {currentPage !== 1 && currentPage !== 2 && <FirstButton disabled={currentPage === 1} onClick={handleFirstPage}>الأولى</FirstButton>}
                            {currentPage !== 1 && <PreviousButton disabled={currentPage === 1} onClick={handlePreviousPage}>السابق</PreviousButton>}
                            <NextButton disabled={isLastPage} onClick={handleNextPage}>التالى</NextButton>
                        </Box>
                        <BottomSection >
                            <HelpTextLight>لم تجد إجابة على سؤالك؟</HelpTextLight>
                            <HelpButtonLong onClick={() => {
                                navigate('/add-question')
                                window.scrollTo(0, 0)
                            }}>اسأل !</HelpButtonLong>
                        </BottomSection>
                    </Box>
                    <Box display='grid' height='fit-content' >
                        <TopLawyersBox setError={setError} />
                            <TopLawyersQuestion>
                                <HelpText>تحتاج مساعدة؟</HelpText>
                                <HelpButton onClick={() => {
                                    navigate('/lawyer-guide')
                                    window.scrollTo(0, 0)
                                }}>ابحث عن محامي!</HelpButton>
                            </TopLawyersQuestion>
                    </Box>

                </Grid>

                <DeletePopupWrapper id={id} open={open} anchor={anchor} onClick={handleClose} >
                    <FilterPopup onClick={(e) => e.stopPropagation()}>
                        <TitleGridPopup>
                            <PopupTitle>تصفية حسب التخصص</PopupTitle>
                            <img onClick={deletePopupOpen} src="cross.svg" alt="cross" width={18} style={isMobile ? { order: '-1' } : { cursor: 'pointer' }} />
                        </TitleGridPopup>
                        <RTLProvider>
                            <Grid display='grid' width='100%' justifyContent='center' mt={isMobile ? '16px' : '110px'}>
                                {!isMobile && (
                                    <FormControl sx={{ m: 0, minWidth: 120 }}>
                                        <InputLabelCustom id="demo-multiple-checkbox-label">التخصصات</InputLabelCustom>
                                        <SelectFilter
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={searchSpecializations}
                                            onChange={handleChange}
                                            renderValue={(selected) => selected.join(', ')}
                                        >
                                            {categories.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    <Checkbox checked={searchSpecializations.indexOf(name) > -1} />
                                                    <ListItemText primary={name} />
                                                </MenuItem>
                                            ))}
                                        </SelectFilter>
                                    </FormControl>
                                )}
                                {isMobile && (
                                    <Paper style={{ maxHeight: 250, overflow: 'auto', boxShadow: 'none', direction: 'ltr', width: '90vw', padding: '0' }}>
                                        <List style={{ display: 'grid', gap: '20px' }}>
                                            {categories.map((category) => (
                                                <ListItemCustom
                                                    key={category}
                                                    role={undefined}
                                                    dense
                                                    button
                                                    onClick={() => handleToggle(category)}
                                                >
                                                    <Checkbox
                                                        edge="start"
                                                        checked={searchSpecializations.indexOf(category) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                    <ListItemText primary={category} />
                                                </ListItemCustom>
                                            ))}
                                        </List>
                                    </Paper>
                                )}

                                <FilterApplyButton style={isMobile ? { marginTop: '20px' } : { marginTop: 0 }} onClick={(e) => { e.stopPropagation(); applyFilters(); deletePopupOpen(e) }}>عرض النتائج</FilterApplyButton>
                            </Grid>
                        </RTLProvider>
                    </FilterPopup>
                </DeletePopupWrapper>
            </Box>
            <LoadingBackDrop loading={loading}/>
        </ThemeProvider>
    )
}

export default QuestionsListSection