import React from 'react'
import { useMediaQuery, Box } from '@mui/material'
import { BioSubTitle, BioTextLight, BioText } from '../LawyersProfile/LawyersProfileStyles'

const Experience = ({ title, year, company }) => {

  const isMobile = useMediaQuery('(max-width:768px)')
  return (
    <Box mt='27px' mr={isMobile? '0px':'28px'}>
      <BioSubTitle >{title}</BioSubTitle>
      <BioText mt='10px'>{company}</BioText>
      <BioTextLight mt='8px'>{year}</BioTextLight>
    </Box>
  )
}

export default Experience