import React from 'react'
import { Grid, useMediaQuery, Box, Button } from '@mui/material'
import { BioSubTitleLight, ReviewShowMore } from '../LawyersProfile/LawyersProfileStyles'
import Review from './Review'
import { formatDate } from 'helpers/formatTime'

const ReviewSection = ({reviews, fetchMoreReviews, hasMoreReviews, totalReviews, lawyer}) => {
    const isMobile = useMediaQuery('(max-width:508px)')

    return (
        <>
            <BioSubTitleLight mt='5px' mr='24px' >({reviews?.length} تعليقات من {totalReviews})</BioSubTitleLight>
            <Grid display='grid' mt='46px' ml={isMobile? '42px': '62px'} >
                {reviews?.map((review, index) => (
                <><Review lawyer = {lawyer} key={index} date={formatDate(review?.created_at)} rate={review?.rating?? 0} name={review?.review_name?? ''} review={review?.comment} /><hr style={{ borderTop: '#EEEEEE 1px solid', opacity: 1, marginRight: '60px' }} /></>                      
                ))}
            </Grid>
            <hr style={{ borderTop: '#EEEEEE 1px solid', opacity: 1, marginTop: '30px' }} />
            <Box display='flex' justifyContent='center'  >
                {hasMoreReviews && <Button onClick={fetchMoreReviews}>
                    <img src={`${process.env.PUBLIC_URL}/arrow_down_black.svg`}></img>
                    <ReviewShowMore mr='8px'>عرض المزيد</ReviewShowMore>
                </Button>}
            </Box>

        </>

    )
}

export default ReviewSection