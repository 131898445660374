import { Box, Dialog, DialogContent, DialogContentText, useMediaQuery } from '@mui/material'
import { ReviewModalSuccess } from 'components/LawyerComponents/ReviewModalStyles'
import { ButtonText, CustomButton } from 'components/Login/LoginStyles'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const AddQuestionSuccess = ({ openModal, setOpenModal, specialities}) => {
    const isMobile = useMediaQuery('(max-width:768px)')
    const navigate = useNavigate()
    const handleClose = () => {
        sessionStorage.setItem('quesitonsSpecializations', JSON.stringify(specialities))
        navigate('/questions-list')
        setOpenModal(false)
      }

  return (
    <Dialog fullScreen={isMobile} open={openModal} onClose={handleClose} maxWidth='lg' sx={{
        ".MuiDialog-paperWidthLg": {
          width: "765px !important",
          borderRadius: isMobile ? '0' : '30px'
        },
        '& .MuiDialogContent-root': {
          overflowY: 'auto',
          scrollbarWidth: 'none', 
          msOverflowStyle: 'none', 
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }
      }} >
        <DialogContent>
          <DialogContentText>
            <Box display='flex' justifyContent='space-between' gap={isMobile ? '182px' : "300px"} mt='20px'>
              <Box display='flex' justifyContent='left' ml="20px">
                <img width="18px" height="18px" onClick={handleClose} src={`${process.env.PUBLIC_URL}/cross.svg`} alt="cross" />
              </Box>
            </Box>
            <Box>
                <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: "center", width: "100%" }} mt={isMobile ? '129px' : '50px'}>
                  <img src={`${process.env.PUBLIC_URL}/reviewSuccess.svg`} alt='success' width='159px' height='159px' />
                  <ReviewModalSuccess mt='55px'>تم إرسال السؤال !</ReviewModalSuccess>
                  <Box mt={isMobile ? '226px' : '69px'} mb='88px'>
                    <CustomButton onClick={handleClose} CustomColor='#B4975A' CustomWidth={isMobile ? '320px' : '380px'}>
                      <ButtonText>إغلاق النافذة</ButtonText>
                    </CustomButton>
                  </Box>
                </Box>
              </Box>
              </DialogContentText>
        </DialogContent>
      </Dialog>
  )
}

export default AddQuestionSuccess