import styled from "@emotion/styled"
import { Box, Typography } from "@mui/material"

export const QuestionPageHeader = styled(Typography)`
color: var(--Dark, #1F2732);
text-align: right;
font-family: Tajawal;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
@media (max-width: 768px) {
    font-size: 20px;
    font-weight: 500;
  }

`

export const QuestionPageSubHeader = styled(Typography)`
color: var(--Main, #B4975A);
text-align: right;
font-family: Tajawal;
font-size: 16px;
padding-top: 6px;
font-style: normal;
font-weight: 500;
line-height: normal;
cursor: pointer;
@media (max-width: 768px) {
    font-size: 15px;
  }
`

export const QuestionWrapper = styled(Box)`
width: 90%;
background: #FBFAF8;
flex-shrink: 0;
`
export const QuestionText = styled(Typography)`
color: #000;
text-align: right;
font-family: Tajawal;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
@media (max-width: 768px) {
    font-size: 18px;
  }
`
export const OtherDetails = styled(Typography)`
color: #000;
text-align: right;
font-family: Tajawal;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 28px; /* 155.556% */
`

export const SpecialityWrapper = styled(Box)`
display: flex;
width: 170px;
height: 42px;
border-radius: 40px;
border: 1px solid var(--Main, #B4975A);
padding: 12px 14px 8px 14px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
`
export const SpecialityText = styled(Typography)`
color: var(--Main, #B4975A);
text-align: center;
font-family: Tajawal;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 112.5% */
`