import React from 'react'
import { CardMedia, Rating, Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { NameText, DescriptionText, StyledCardMedia, CityText, RatingBox, StyledCardContent, } from '../QuestionsList/QuestionsListStyles'
import { useNavigate } from 'react-router-dom'
import Stars from 'components/LawyerComponents/Stars'

const TopLawyer = ({ lawyer }) => {
  const reviewScore = lawyer.rating
  const navigate = useNavigate()
  const totalScore = 5
  const isSmallScreen = useMediaQuery('(max-width:990px) and  (min-width: 768px)')
  return (
    <Grid onClick={() => navigate(`/profile/${lawyer?.id}`)} sx={{cursor: 'pointer'}}>
      <StyledCardContent >
        <RatingBox >
          <StyledCardMedia
            component="img"
            image={`https://storage.googleapis.com/qanooni-lawyer-image/${lawyer?.id}` || "top-lawyer-default.svg"}
            alt="lawyer"
            onError={(e) => {
              e.target.onerror = null // Prevent future triggering of the error handler
              e.target.src = "top-lawyer-default.svg" // Set default image
            }}
          />
          <Box display='grid' gap='6px' width={isSmallScreen ? '150px' : '200px'} padding='0'>
            <NameText onClick={() => {
              navigate(`/profile/${lawyer.id}`)
              window.scrollTo(0, 0)
          }}>{lawyer.full_name || ' عبدالرحمن الشمري'}</NameText>
            <CityText color="#B4975A">محامي مرخص</CityText>
            <CityText color="text.secondary">المدينة: {lawyer?.city}</CityText>
            {reviewScore && <Box sx={{ display: 'flex', alignItems: 'center'}}>
              <Stars rate={reviewScore} size={'14.8px'} gap={'3.3px'}></Stars>
              <Typography sx={{ mr: "6px", mt: "2px", fontWeight: '500', fontSize: '15px', lineHeight: 'normal' }}>
                {`${Math.round(reviewScore)}/${totalScore}`}
              </Typography>
            </Box>}
            <DescriptionText>
              عدد الإجابات: ({lawyer.total_answers}) إجابة
            </DescriptionText>
          </Box>
        </RatingBox>

      </StyledCardContent>
    </Grid>
  )
}
export default TopLawyer