import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import { is } from '@babel/types'
import { LicenceBoxGrid, LicenseBoxNumber, LicenseBoxTitle } from '../LawyersProfile/LawyersProfileStyles'

const LicenseBox = ({ lawyer }) => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:508px)')

    return (
        <LicenceBoxGrid >
            <LicenseBoxTitle>رقم الترخيص:</LicenseBoxTitle>
            <hr style={{ borderTop: '2px solid #F1EFE7' }} />
            <LicenseBoxNumber>{lawyer?.license_number}</LicenseBoxNumber>
        </LicenceBoxGrid>
    )
}

export default LicenseBox