import { Box, useMediaQuery } from '@mui/material'
import FooterSection from 'Layout/Footer'
import Layout from 'Layout/Layout'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import ForgetPasswordBox from 'components/ForgetPassword/ForgetPasswordBox'
import { Heading } from 'components/Login/LoginStyles'
import SignupBox from 'components/Signup/SignupBox'
import { SubHeading } from 'components/Signup/SignupStyles'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const ForgetPassword = () => {
	const [error, setError] = useState(false)
	const navigate = useNavigate()
	const isMobile = useMediaQuery('(max-width:600px)')
	const [isContentLoaded, setIsContentLoaded] = useState(false)
	
	const onContentLoaded = () => {
		setIsContentLoaded(true)
	}

	return (
		<Layout navbarClass={'popup'} isWhiteBackground={isMobile}>
			<Box minHeight={isMobile ? 'calc(100vh - 456px)' : 'calc(100vh - 160px)'} >
				{error && <ErrorMessage message={error} />}
				{isMobile && (
					<Box display="flex" flexDirection="column">
						<Box display='flex' justifyContent='left' mt="40px" ml="20px">
							<img onClick={() => navigate('/')} src="cross.svg" alt="cross" />
						</Box>
						<Box mx="auto" mt="50px">
							<img alt="logo" src="auth-logo.svg" />
						</Box>
					</Box>
				)}
				<Heading>استرجاع كلمة المرور</Heading>
				<ForgetPasswordBox setError={setError} onLoaded={onContentLoaded}/>
			</Box>
      		{isContentLoaded && <FooterSection />} 
		</Layout>
	)
}

export default ForgetPassword