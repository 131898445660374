import '../styles/footer.css'
import React from 'react'
import { Container, Nav } from 'react-bootstrap'
import { Typography } from '@mui/material'

const FooterSection = () => {
    return (
        <>
            <Container className='footer-container home'>
                <div className='footer-section right'>
                    <Nav.Link href='/privacy-policy'>سياسة الخصوصية</Nav.Link>
                    <Nav.Link href='/terms-conditions'>شروط الاستخدام</Nav.Link>
                    <Nav.Link href='/about-us'>حول قانوني</Nav.Link>
                    <Nav.Link href='/contact-us'>تواصل معنا</Nav.Link>
                </div>
                <div className='footer-section left'>
                    <div className='footer-section-wrapper'>
                        <Nav.Link className='footer-bold'>تابعنا</Nav.Link>
                        <div className='footer-section-social'>
                            <Nav.Link href='https://www.linkedin.com/company/%D9%85%D9%86%D8%B5%D8%A9-%D9%82%D8%A7%D9%86%D9%88%D9%86%D9%8A/'>
                                <img src='../LinkedIn.svg' alt = "social-logo" className='footer-social-logo' width={30} />
                            </Nav.Link>
                            <Nav.Link href='https://x.com/Qanoonico'>
                                <img src='../Twitter.svg' alt = "social-logo" className='footer-social-logo' width={30} />
                            </Nav.Link>
                        </div>
                    </div>
                    <Nav className='footer-credits'>كافة الحقوق محفوظة © شركة الأذرع المتحدة للخدمات التسويقية </Nav>
                </div>
                <div className='footer-section mobile'>
                    <Nav.Link href='/contact-us'>تواصل معنا</Nav.Link>
                    <Nav.Link href='/about-us'>حول قانوني</Nav.Link>
                    <Nav.Link href='/terms-conditions'>شروط الاستخدام</Nav.Link>
                    <Nav.Link href='/privacy-policy'>سياسة الخصوصية</Nav.Link>
                    <Nav className='footer-credits-mobile'>كافة الحقوق محفوظة © شركة الأذرع المتحدة للخدمات التسويقية</Nav>
                </div>
            </Container>
        </>
    )
}

export default FooterSection
