import styled from '@emotion/styled'
import { Box, Card, CardContent, CardMedia, InputBase, Typography, Select, Grid, Autocomplete } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'

export const TitleText = styled(Typography)`
	color: #1f2732;
	font-size: 30px;
	font-weight: 700;
	margin-top: 98px;
	margin-bottom: 50px;
	@media (max-width: 768px) {
		font-size: 20px;
		font-weight: 500;
		line-height: 24px;
		margin-top: 20px;
		margin-bottom: 34px;
	}
`
export const NameText = styled(Typography)`
	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
	margin-top: 23px;
	cursor: pointer;
	@media (max-width: 768px) {
		font-size: 18px;
		font-weight: 500;
		line-height: 22px;
		margin-top: 13px;
	}
`

export const CityText = styled(Typography)`
	margin-bottom: 8px;
	font-size: 15px;
	font-weight: 500;
	line-height: 18px;
	margin-top: 4px;
	cursor: pointer;
`
export const Qualification = styled(CityText)`
	margin-bottom: 4px;
`

export const DescriptionText = styled(Typography)`
	font-size: 16px;
	font-weight: 500;
	line-height: 25px;
	margin-bottom: 16px;
	color: #000000;
	word-wrap: break-word;
	word-break: break-word; 
	white-space: normal; 
	text-overflow: ellipsis; 
	overflow: hidden; 
	display: inline-block;
	@media (max-width: 768px) {
		margin-bottom: 25px;
	}
`

export const SearchContainer = styled('div')`
	display: flex;
	align-items: center;
	border-radius: 26px;
	border: 1px solid #d7d7d7;
	background-color: #fff;
	padding: 0px; 
	width: 50%;
	@media (max-width: 768px) {
		width: 100%;
	}
	height: 46px;

	justify-content: space-between;
	
`

export const StyledSearchIcon = styled(SearchIcon)`
	color: #d7d7d7; 
	margin-right: 10px; 
`

export const StyledInputBase = styled(InputBase)`
	color: inherit;
	flex: 1;
	padding-block: 7px;
	margin-right: 21.17px;
	width: 100%;
	@media (max-width: 768px) {
		font-size: 15px;
	}
`

export const SearchButton = styled(Button)`
	background-color: #825925;
	color: white;
	padding: 18px 36.29px 14px 36.29px;
	border-radius: 40px 0px 0px 40px;
	height: inherit;
	margin-right: auto;
	@media (max-width: 768px) {
		width: 71.78px;
		font-size: 15px;
	}

	&:hover {
		background-color: #825925; 
	}
`

export const FilterButton = styled(Button)`
color: white;
background-color: #B4975A;
padding: 12px 35px;
border-radius: 40px;
min-width: ${({ minWidth }) => minWidth };
height: 48px;
margin-right: 39px;
display:flex;
align-items: center;
@media (max-width: 1000px) {
	padding: 5px 8px;
	min-width: 150px;
}
@media (max-width: 768px) {
	background-color: white;
	color: #B4975A;
	font-size: 16px;
	margin: 18px -5px 0px 0px;
	padding: 5px;
	height: 25px;
	width: auto;
	min-width: unset;
	&:active {
		background-color: white; 
	}
	&:hover {
		background-color: white!important; 
	}
}

&:hover {
	background-color: #825925;
}    
`
export const ClearButton = styled(Button)`
color: #B4975A;
padding: 0;
margin-right: 21px;
font-size: 16px;
font-weight: 500;
line-height: 19px;
@media (max-width: 768px) {
	height: 25px;
	margin-top: 18px;
	&:hover {
		background-color: white!important; 
	}
}
`

export const PaginationText = styled(Typography)`
	color: rgba(0, 0, 0, 0.4);
	font-size: 17px;
	font-weight: 500;
	padding: 0px;
	margin-bottom: 64px;
	@media (max-width: 768px) {
		margin-bottom: 20px;
	}
`
export const StyledCard = styled(Card)`
  box-shadow: none;
  border: none;
  border-radius: 0;
  padding: 0;
`

export const StyledCardContent = styled(CardContent)`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
  margin-top: 30px;
  border-bottom: 1px solid #E3E1DC;
  @media (max-width: 768px) {
	margin-top: 40px;
}
`

export const StyledCardMedia = styled(CardMedia)`
  width: 100.8px;
  height: 120px;
  margin-bottom: 2px;
  cursor: pointer;
  @media (min-width: 600px) {
    margin-bottom: 37px; 
  }
  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
  margin-left: 17.14px;
  border-radius: 4px;
`

export const RatingBoxWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  
`

export const LawyerInfoBox = styled(Box)`
  flex-grow: 1;
  padding: 0px;
`

export const SelectFilter = styled(Select)`
background: #FFF;
border-radius: 4px;
font-family: Tajawal;
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0px;
text-align: right;
color: #1F273280;
width: calc(100vw - 60px);
max-width: 380px;
height: 50px;
margin-bottom: 40px;
& fieldset{
  border: 2px solid #E3E1DC;
  top: -2px;
	legend {
	  display: none;
	}
}
& svg {
  color: #DADADA;
}
& .MuiSelect-select{
	display: grid;
	align-items: center;
	justify-content: start;
	padding-top: 24px;
	color: #1F2732;
	font-weight: 500;
	font-size: 18px;
}
@media (max-width: 768px) {
  max-width: 402px;
  margin-bottom: 20px;
}
`

export const AutocompleteFilter = styled(Autocomplete)`
  background: #FFF;
  border-radius: 4px;
  font-family: Tajawal;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: right;
  color: #1F273280;
  width: calc(100vw - 60px);
  max-width: 380px;
  height: 100%;
  margin-bottom: 40px;

  & .MuiAutocomplete-inputRoot {
    padding: 9px;
    height: 9%;
	padding-top: 9px;
	padding-bottom: 2px;
	color: #1F2732;
	font-weight: 500;
	font-size: 18px;
  }
  
  & fieldset {
    border: 2px solid #E3E1DC;
	// top: 15px;
    legend {
      display: none;
    }
  }
  & svg {
    color: #DADADA;
  }

  & .MuiAutocomplete-root {
    height: 10px;
  }

  @media (max-width: 768px) {
    max-width: 402px;
    margin-bottom: 20px;
  }
`

export const FilterPopup = styled.div(`
width: 765px;
height: 729px;
max-height: 100vh;
background-color: #FFF;
box-shadow: 0px 4px 40px rgba(130, 89, 37, 0.05);
border-radius: 30px;
margin: 5vh auto;
padding: 40px 49px 0px 40px;
overflow-y: auto;
scrollbar-width: thin;
scrollbar-color: transparent transparent;
&::-webkit-scrollbar {
  width: 6px;
}
&::-webkit-scrollbar-thumb {
  background-color: transparent;
}
@media (max-width: 768px) {
	width: 100%;
    height: 500px!important;
    border-radius: 0;
    margin: 0 auto!important;
    padding: 20px;
    position: fixed;
    bottom: 0;
    border-radius: 20px 20px 0 0;
}
@media (max-height: 729px) {
	height: 90vh;
	margin: 2vh auto;
  }
`)

export const FilterApplyButton = styled(Button)`
width: 380px;
height: 48px;
border-radius: 40px;  
color: #FFFFFF;
background: #B4975A;
font-family: Tajawal;
font-size: 18px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: center;
margin-top: 20px;
:hover{
  background: #B4975A;
}
@media (max-width: 768px) {
  width: 100%;
  margin-top: 30px;
}
`

export const TitleGridPopup = styled(Grid)`
display:flex;
justify-content:space-between;
@media (max-width: 768px) {
  direction: ltr;
  margin: 10px 10px 0 10px;
}
`

export const PopupTitle = styled(Typography)`
font-family: Tajawal;
font-size: 28px;
font-weight: 500;
margin: 0;
letter-spacing: 0em;
text-align: center;
color: #000;
@media (max-width: 768px) {
	font-size: 18px;	
}
`