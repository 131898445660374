import { Box, useMediaQuery } from '@mui/material'
import FooterSection from 'Layout/Footer'
import Layout from 'Layout/Layout'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import { Heading } from 'components/Login/LoginStyles'
import ResetPasswordBox from 'components/ResetPassword/ResetPasswordBox'
import SuccessMessage from 'components/SuccessMessage/SuccessMessage'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const ResetPassword = () => {
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:600px)')
    const [isContentLoaded, setIsContentLoaded] = useState(false)

    const onContentLoaded = () => {
        setIsContentLoaded(true)
    }
    return (
        <Layout navbarClass={'popup'} isWhiteBackground={isMobile}>
            <Box minHeight={isMobile ? 'calc(100vh - 456px)' : 'calc(100vh - 160px)'}>
                {error && <ErrorMessage message={error} />}
                {success && <SuccessMessage message={success} />}
                {isMobile && (<Box display='flex' flexDirection='column'><Box display='flex' justifyContent='left' mt="40px" ml="20px">
                    <img onClick={() => navigate('/')} src="cross.svg" alt="cross" />
                </Box><Box mx='auto' mt='50px'>
                        <img alt='logo' src='auth-logo.svg' />
                    </Box></Box>)}
                <Heading>استرجاع كلمة المرور</Heading>
                <ResetPasswordBox setError={setError} setSuccess={setSuccess} onLoaded={onContentLoaded} />
            </Box>
            {isContentLoaded && <FooterSection />}
        </Layout>
    )
}

export default ResetPassword