import styled from "@emotion/styled"
import { Box, TextField, Typography } from "@mui/material"

export const AnswerHeading = styled(Typography)`
color: var(--Secondary, #825925);
text-align: right;
font-family: Tajawal;
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: normal;
@media (max-width: 768px) {
  font-size: 20px;
}
`

export const AnswerWrapper = styled(Box)`
width: 90%;
flex-shrink: 0;
background: #F5F4F0;
@media (max-width: 768px) {
  width: 100%;
}
`

export const UserPhotoContainer = styled(Box)`
width: 100px;
height: 120px;
flex-shrink: 0;
border-radius: 4px;
`

export const AnswerUsername = styled(Typography)`
color: var(--Dark, #1F2732);
text-align: right;
font-family: Tajawal;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;`

export const AnswerUserVerified = styled(Typography)`
color: var(--Main, #B4975A);
text-align: right;
font-family: Tajawal;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;`

export const AnswerUserLocation = styled(Typography)`
color: rgba(52, 49, 42, 0.70);
text-align: right;
font-family: Tajawal;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;`

export const AnswerUserAnswers = styled(Typography)`
color: rgba(52, 49, 42, 0.70);
text-align: right;
font-family: Tajawal;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;`

export const AnswerUserBox = styled(Box)`
display: inline-flex;
flex-direction: column;
align-items: flex-start;
gap: 6px;
`

export const AnswerDate = styled(Typography)`
color: rgba(0, 0, 0, 0.40);
text-align: right;
font-family: Tajawal;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;`

export const AnswerText = styled(Typography)`
color: #000;
text-align: right;
font-family: Tajawal;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 28px; /* 155.556% */`

export const AnswerUseful = styled(Typography)`
color: var(--Secondary, #825925);
text-align: right;
font-family: Tajawal;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 28px; /* 155.556% */`

export const AnswerUsefulCount = styled(Typography)`
color: var(--Secondary, #825925);
text-align: right;
font-family: Tajawal;
font-size: 18px;
font-style: normal;
font-weight: 500;
`
export const NeedHelp = styled(Typography)`
color: #1F2732;
text-align: right;
font-family: Tajawal;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
width: 240px;
@media (max-width: 768px) {
  font-size: 20px;
  width: 180px;
}
`
export const HireLawyerWrapper = styled(Box)`
display: flex;
width: 191px;
height: 48px;
padding: 12px 14px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
cursor: pointer;
border-radius: 40px;
background: var(--Dark, #1F2732);
@media (max-width: 768px) {
  width: 166px;
}
`
export const HireLawyerText = styled(Typography)`
color: #FFF;
text-align: center;
font-family: Tajawal;
font-size: 19px;
font-style: normal;
font-weight: 400;
line-height: normal;
@media (max-width: 768px) {
  font-size: 15px;
}
`
export const WriteAnswer = styled(Typography)`
color: #000;
text-align: right;
font-family: Tajawal;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
`

export const AnswerTextField = styled(TextField)`
  width: 90%;
  margin-top: 29px;
  background-color: #FFF;
  border: ${({ isFocused }) => isFocused ? "2px solid var(--Secondary,#825925);" : '2px solid var(--Secondary,#E3E1DC);'} 

  & .MuiInputLabel-root {
    color:  ${({ value }) => value ? "#FFF" : 'rgba(31, 39, 50, 0.50)'};
    text-align: right;
    font-family: Tajawal;
    font-size:  ${({ value }) => value ? "12px" : '18px'};18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transform: ${({ value }) => value ? 'translate(9px, 3px)' : 'translate(10px, 13px)'}; translate(10px, 13px);

    &.Mui-focused {
      color: #FFF;
      font-size: 12px;
      transform: translate(9px, 3px);
    }
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border: 0px solid #E3E1DC; /* Apply border style from CustomTextField */
    }
    &:hover fieldset {
      border: 0px solid #E3E1DC; /* Maintain border on hover */
    }
    &.Mui-focused fieldset {
      border: 0px solid #825925; // If you want to keep the border consistent, use the same color as the normal state
    }
    input {
      font-family: Tajawal;
      padding: 16.5px 14px 0;
      box-shadow: 200px 200px 100px white inset; /* From CustomTextField */
    }
  }

  @media (max-width: 768px) {
    width: 300px;
    height: 315px;
    & fieldset {
      height: 315px;
      width: 300px;
    }
  }
`

export const AnswerEditTextField = styled(AnswerTextField)`
width: 60%;
height: 420px;
margin-top: 12px;
background-color: #FFF;

@media (max-width: 768px) {
  width: 300px;
  height: 311px;
  & fieldset{
    height: 315px;
  }
}`

export const AnswerEditButton = styled(Typography)`
color: var(--Secondary, #825925);
text-align: right;
font-family: Tajawal;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 28px; /* 155.556% */
`