import React, { useState, useEffect } from 'react'
import { GridContainer, PageTitle, GridNav, TitleItem, TitleItemLight, PopupTitle, DeletePopupWrapper, DeleteButton, DeletePopup, TitleGridPopup, ButtonGrid, TextItemPopup} from './AccountStyles'
import { useMediaQuery, Grid, Box } from '@mui/material'
import { Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import PageNav from '../AccountComponents/PageNav'
import { deleteAccount } from 'api/profile'
import { useAuth } from 'context/auth'

const AccountSection = ({ setError , setSuccess, successMessage, emailUpdated }) => {

    const {user, setUser} = useAuth() 
    const [anchor, setAnchor] = useState(null)
    document.body.style.setProperty('background-color', '#FFFFFF', 'important')
    const [loading, setLoading] = useState(false)
    const isMobile = useMediaQuery('(max-width:768px)')
    const navigate = useNavigate()
    const deletePopupOpen = async (event) => {
        event.stopPropagation() 
        setAnchor(anchor ? null : event.currentTarget)
    }
    const handleClose = () => {
        setAnchor(null)
    }

    const onDeleteAccount = async ()=>{
        try {
            await deleteAccount({id: user.id})
            setSuccess('account deleted!')
            localStorage.removeItem('token')
            setUser(null)
            setTimeout(() => {
                navigate('/login')
            }, 3000)
            setLoading(false)
          } catch (error) {
            setError(error.message)
            setLoading(false)
          }
    }

    const open = Boolean(anchor)
    const id = open ? 'simple-popup' : undefined

    useEffect(() => {
        if (successMessage){
            setLoading(true)        
            setSuccess(successMessage)
            const timer = setTimeout(() => {
                setSuccess('') // Clear the success message
                setLoading(false)
            }, 5000)
            return () => clearTimeout(timer) 
        }

    }, [setSuccess, setLoading]) // Dependencies
    return (
        <Box style={{ margin: (isMobile ? "" : '0 60px'), minHeight:isMobile ? 'calc(100vh - 556px)' : 'calc(100vh - 160px)', padding: (isMobile ? "0 20px" : "")}}> 
            <GridContainer  paddingTop={isMobile ? '24px' : '50px'}>
                <PageNav parentText={'حسابي'} childText={'إعدادات حسابي'} navigateTo='../lawyer'></PageNav>
                <PageTitle>إعدادات الحساب</PageTitle>
                <Grid mt={isMobile ? '14px' : '50px'}>
                    <GridNav justifyContent={'flex-start!important'} mt={isMobile ? '48px' : '75px'}>
                        <img src='./arrow-bolditem.svg' alt='ArrowBold' width={12} />
                        <TitleItem >البريد الالكتروني:</TitleItem >
                    </GridNav>
                    <TitleItemLight m={'11px 37px 0 0 '} >{emailUpdated ? emailUpdated : user?.email}</TitleItemLight > 
                    <TitleItemLight style={{ cursor: 'pointer', color: '#B4975A', marginRight: '37px' }} onClick={() => navigate('/change-email')}>تغيير البريد الالكتروني</TitleItemLight>
                    <GridNav justifyContent={'flex-start!important'} mt={isMobile ? '48px' : '75px'}>
                        <img src='./arrow-bolditem.svg' alt='ArrowBold' width={12} />
                        <TitleItem >السرية والأمان</TitleItem >
                    </GridNav>
                    <TitleItemLight style={{ cursor: 'pointer', color: '#B4975A', margin: '11px 37px 0 0' }} onClick={() => navigate('/change-password')}>تغيير كلمة المرور</TitleItemLight>
                    <GridNav justifyContent={'flex-start!important'} mt={isMobile ? '48px' : '92px'}>
                        <img src='./arrow-bolditem.svg' alt='ArrowBold' width={12} />
                        <TitleItemLight style={{ cursor: 'pointer', color: '#B4975A' }} onClick={deletePopupOpen}>هل ترغب بحذف حسابك؟</TitleItemLight>
                    </GridNav>
                    <GridNav justifyContent={'flex-start!important'} m={isMobile ? '72px 0 37px 0' : '81px 0 100px 0'}>
                        <img src='./arrow-bolditem.svg' alt='ArrowBold' width={12} />
                        <TitleItemLight  >في حال الرغبة بتغيير رقم الجوال المسجل <a style={{ cursor: 'pointer', color: '#B4975A' }} onClick={() => navigate('/contact-us')}>تواصل معنا</a></TitleItemLight>
                    </GridNav>
                </Grid>
            </GridContainer>
            <DeletePopupWrapper id={id} open={open} anchor={anchor} onClick={handleClose} >
                <DeletePopup onClick={(e) => e.stopPropagation()}>
                    <TitleGridPopup>
                        <PopupTitle>تأكيد</PopupTitle>
                        <img onClick={deletePopupOpen} src="cross.svg" alt="cross" width={18} style={isMobile ? {order: '-1'} : {cursor:'pointer'}}/>
                    </TitleGridPopup>
                    <Grid display='flex' justifyContent='center' mt={isMobile ? '50px' : '115px'} paddingX='20px'>
                        <TextItemPopup >هل أنت متأكد من رغبتك في حذف الحساب؟</TextItemPopup >
                    </Grid>
                    <ButtonGrid >
                        <DeleteButton onClick={deletePopupOpen}>لا</DeleteButton>
                        <DeleteButton reverseColor onClick={(e)=>{onDeleteAccount(), deletePopupOpen(e)}}>نعم</DeleteButton>
                    </ButtonGrid>
                </DeletePopup>
            </DeletePopupWrapper>
        </Box>
    )
}
export default AccountSection