import FooterSection from 'Layout/Footer'
import Layout from 'Layout/Layout'
import PrivacyPolicyComponent from 'components/Aboutus/PrivacyPolicyComponent'
import React from 'react'

const PrivacyPolicy = () => {
  return (
    <Layout>
        <PrivacyPolicyComponent />
        <FooterSection />
    </Layout>
  )
}

export default PrivacyPolicy