import styled from "@emotion/styled"
import { Button, TextField, Typography, Grid, Link } from "@mui/material"
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup'

export const ButtonGrid = styled(Grid)`
display:flex;
wrap:wrap;
justify-content: space-between;
margin-top: 121px;
padding: 0 20px;
@media (max-width: 688px) {
  margin-top: 106px;
  padding: 0;
  display: grid;
  justify-content: center;
  gap: 40px;
}
`

export const TitleGridPopup = styled(Grid)`
display:flex;
justify-content:space-between;
@media (max-width: 768px) {
  display: grid;
  justify-content:end;
  justify-items: end;
}
`

export const DeleteButton = styled(Button)`
background: ${({ reverseColor }) => reverseColor ? '#FFFFFF' : '#B4975A'};
color: ${({ reverseColor }) => reverseColor ? '#B4975A' : '#FFFFFF'};
width: 300px;
height: 46px;
font-family: Tajawal;
font-size: 20px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
border-radius: 40px;
border: 1px solid #B4975A;
:hover{
  background: ${({ reverseColor }) => reverseColor ? '#FFFFFF' : '#B4975A'};
  color: ${({ reverseColor }) => reverseColor ? '#B4975A' : '#FFFFFF'};
}
`

export const PopupTitle = styled(Typography)`
font-family: Tajawal;
font-size: 28px;
font-weight: 500;
margin: 0;
letter-spacing: 0em;
text-align: center;
color: #000;
@media (max-width: 768px) {
  width: 100vw;
  margin-left: -20px;
  margin-top: 140px;
}
`

export const DeletePopupWrapper = styled(BasePopup)`
position: fixed!important;
height: 100%!important;
width: 100%!important;
transform: none!important;
z-index:20;
::before{
  content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -10;
    background: black;
    opacity: 50%;
}
@media (max-width: 768px) {
  z-index: 11;
}
`

export const DeletePopup = styled.div(`
width: 765px;
height: 462px;
background-color: #FFF;
box-shadow: 0px 4px 40px rgba(130, 89, 37, 0.05);
border-radius: 30px;
margin: 20vh auto;
padding: 40px;
@media (max-width: 768px) {
  width: 100%;
  height: 100%;
  border-radius: 0;
  margin: 0 auto;
  padding: 20px;
}
`)

export const TextItemPopup = styled(Typography)`
font-family: Tajawal;
font-size: 24px;
font-weight: 500;
line-height: 28.8px;
color: #000000;
text-align: center;
@media (max-width: 768px) {
  font-size: 18px;
  line-height: 21.6px;
}
`

export const TitleItem = styled(Typography)`
font-family: Tajawal;
font-size: 18px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
text-align: right;
color: #1F2732;
`

export const TitleItemLight = styled(TitleItem)`
font-weight: 500;
`

export const LinkNav = styled(Link)`
cursor: pointer;
font-family: Tajawal;
font-size: 16px;
font-weight: 500;
line-height: 19px;
color: #1F2732;
text-decoration: none;

`

export const GridNav = styled(Grid)`

display: flex;
justify-content: flex-start;
gap: 20px;
align-items: center;

`

export const PageTitle = styled(Typography)`
color: #1F2732;
font-family: Tajawal;
font-size: 30px;
font-weight: 700;
line-height: 36px;
letter-spacing: 0em;
margin-top: 29px;
@media (max-width: 768px) {
    margin-top: 33px;
    font-size: 20px;
    font-weight: 500;

}

`

export const GridContainer = styled(Grid)`
display: grid;
margin-bottom:20px;
align-items: center;
justify-content: start;
align-content: center;

`

export const PopupBody = styled.div(`
  width: max-content;
  padding: 12px 16px;
  margin: 8px;
  border-radius: 8px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  z-index: 1;
`)