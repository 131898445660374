import { DropdownItem } from 'components/Navbar/NavbarStyles.js'
import React from 'react'
import { Nav } from 'react-bootstrap'

import { Typography, useMediaQuery } from '@mui/material'

export const MenuItemLawyer = ({ text, onClick }) => {

    const isMobile = useMediaQuery('(max-width:768px)')
    return (
        <DropdownItem onClick={onClick}>
            {text}
        </DropdownItem>
    )
}

export const MenuItemLawyerMobile = ({ text, onClick }) => {

    const isMobile = useMediaQuery('(max-width:990px)')
    return (
        <Nav.Link href="#" onClick={onClick} className='navbar-button guide'>{text}<img src='../arrow-mobile.svg' hidden={isMobile ? false : true} /></Nav.Link>
    )
}

