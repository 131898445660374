import styled from "@emotion/styled"
import { Button, TextField, Typography, Grid, Box, css } from "@mui/material"

export const CustomTextField = styled(Typography)`
font-family: Tajawal;
font-size: 28px;
font-weight: 500;
line-height: 34px;
@media(max-width: 1330px){
  font-size: 20px;
}
@media (max-width: 508px) {
    font-size: 16px;
    line-height: 19px;    
  }
`

export const LawyerBoxWrapper = styled(Button)`
	width: calc(25% - 12px);
	min-width: 220px;
	height: 233px;
	display: grid;
	flex-shrink: 0;
	border-radius: 10px;
	background: #fff;
	margin-top: 20px;
	justify-items: center;
	align-items: center;
	align-content: space-evenly;
	color: #000000;
	@media (max-width: 1330px) {
		height: 200px;
	}
	@media (max-width: 768px) {
		min-width: 200px;
		height: 200px;
		margin-top: 30px;
	}
	@media (max-width: 508px) {
		min-width: 150px;
		height: 150px;
	}
	@media (max-width: 350px) {
		min-width: 120px;
		height: 120px;
	}
	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.99;
			cursor: not-allowed;
			color: #000000 !important;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(
					255,
					255,
					255,
					0.6
				); /* Semi-transparent white overlay */
				border-radius: 10px;
				pointer-events: none; /* Allows clicks to pass through the overlay */
				z-index: 1; /* Ensure the overlay is above the button content */
			}
		`}
`

export const LawyerGrid = styled(Grid)`

justify-content: space-between;

@media (max-width: 920px) {
 display: grid;
 grid-template-columns: 1fr 1fr;
 justify-items: center
}


`

export const LawyerTitle = styled(Typography)`
color: #1F2732;
font-family: Tajawal;
font-size: 30px;
font-weight: 700;
line-height: 36px;
letter-spacing: 0em;
font-weight: 700;
@media (max-width: 768px) {
  font-size: 20px;
  margin-right: 20px;
  font-weight: 500;
}
`

export const SignupSuccessBox = styled(Box)`
width: 90%;
height: 233px;
flex-shrink: 0;
fill: #FFF; 
background: #FFF;
border-radius: 10px;
box-shadow: 0px 4px 40px 0px rgba(130, 89, 37, 0.05);
filter: drop-shadow(0px 4px 40px rgba(130, 89, 37, 0.05));
@media (max-width: 768px) {
  font-size: 18px;
  height: 149px;
  width: 90%;
}
`

export const SignupSuccessHeading = styled(Typography)`
color: #1F2732;
text-align: center;
font-family: Tajawal;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-top: 81px;
@media (max-width: 768px) {
  font-size: 18px;
  padding-top: 39px;
}
`
export const SignupSuccessSubHeading = styled(Typography)`
color: #1F2732;
text-align: center;
font-family: Tajawal;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
@media (max-width: 768px) {
  font-size: 15px;
  width: 257px;
}
`