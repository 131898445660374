import React, { useState } from 'react'
import { ClearButton, FilterButton, SearchButton, SearchContainer, StyledInputBase, StyledSearchIcon } from './LawyersListingStyle'
import { Box, Button, Typography, useMediaQuery } from '@mui/material'

const LawyerSearch = ({onSearch, onFilterClick, onFilterClearClick, isFilterClearVisible, noOfFilters}) => {
    const [searchValue, setSearchValue] = useState(sessionStorage.getItem('searchValueLawyersGuide') || "")
    const isMobile = useMediaQuery('(max-width:768px)')

    const handleInputChange = (event) => {
        setSearchValue(event.target.value)
        sessionStorage.setItem('searchValueLawyersGuide', event.target.value)

    }
    const handleSearchClick = () => {
        onSearch(searchValue) 
    }
    const handleFilterClick = (event) => {
        onFilterClick(event) 
    }
    const handleFilterClearClick = (event) => {
        onFilterClearClick(event) 
        setSearchValue('')
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearchClick()
        }
    }

    return (
        <Box display="flex" sx={{ backgroundColor: "", alignItems: "center", marginBottom: "37px", flexWrap: isMobile ? "wrap" : "nowrap", width: "100%" }}>
            <SearchContainer sx={{}}>
                <img src='./search-icon.svg' alt='icon-search' style={{margin: '0 15px 0 0'}}/>
                <form onSubmit={e => e.preventDefault()} action="." style={{width: '100%'}}>
                    <StyledInputBase
                        placeholder="ابحث عن اسم المحامي"
                        value={searchValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        type='search'
                    />
                </form>
                <SearchButton onClick={handleSearchClick}>البحث</SearchButton>
            </SearchContainer>

            <Box sx={isMobile ? {display: 'flex', justifyContent: 'space-between', width: '100%'} : { display: "flex", backgroundColor: "" }}>
                <FilterButton onClick={handleFilterClick} minWidth={noOfFilters !== 0 ? '207px': '153px'}>
                    <img src={isMobile ? '/filter-mobile.svg' : '/filter.svg'} width='18px'></img>
                    <Typography sx={{
                        fontSize: "19px",
                        fontWeight: 400,
                        marginRight: "10px",
                        marginBlock: 0,
                    }} > تصفية {noOfFilters > 0 && `(${noOfFilters})`} </Typography>
                </FilterButton>
                <ClearButton 
                    onClick={handleFilterClearClick} 
                    style={{ display: isFilterClearVisible ? 'block' : 'none' }}
                >حذف خيارات التصفية
                </ClearButton>
            </Box>
        </Box>

    )
}

export default LawyerSearch
