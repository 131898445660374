/* eslint-disable no-useless-escape */
import { Box, Grid, useMediaQuery } from '@mui/material'
import CustomPlainTextField from 'components/FormComponents/CustomPlainTextField'
import PhoneTextField from 'components/FormComponents/PhoneTextField'
import React, { useState } from 'react'
import { SignupHeader, SignupPhoneRule, TermsAndConditions, TermsAndConditionsLink } from './SignupStyles'
import { ButtonText, Container, CustomButton } from 'components/Login/LoginStyles'
import PasswordTextField from 'components/FormComponents/PasswordTextField'
import { signup } from 'api/auth'
import { useNavigate, useSearchParams } from 'react-router-dom'
import LoadingBackDrop from 'components/Loading/LoadingBackDrop'
import { rules, validateFields } from 'helpers/validation'

const SignupBox = ({setError, onLoaded}) => {
    const [mobile, setMobile] = useState('')
    const [password, setPassword] = useState('')
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const fields = { fullName, mobile, email, password }
    const [searchParams] = useSearchParams()
    const isTest = searchParams.get('isTest') === 'true'
    const navigate = useNavigate()
    const gridStyles = { display: 'flex', justifyContent: 'center', alignItems: 'center' }
    const isMobile = useMediaQuery('(max-width:600px)')

    const validationRules = {
      fullName: [
          rules.required('الاسم الكامل'),
          /* eslint-disable no-control-regex */
          rules.regex(
            'الاسم الكامل',
            /^(?!.*(<.*?>|javascript:|(\b(on[a-zA-Z]*=))|(\b(SELECT|INSERT|UPDATE|DELETE|DROP|CREATE|ALTER|EXEC|UNION|TRUNCATE|REPLACE)\b|['";]|--|\/\*|\*\/)))([\u0600-\u06FFa-zA-Z\s?]+|[\u0600-\u06FFa-zA-Z\s?]+(?: [\u0600-\u06FFa-zA-Z\s?]+)*)$/,
            'الاسم الكامل يجب ألا يحتوي على رموز خاصة أو نصوص غير مسموحة ويجب أن يحتوي فقط على أحرف عربية أو إنجليزية'
        ),
          rules.length('الاسم الكامل', 1, 155)
      ],
      mobile: [
          rules.required('رقم الجوال'),
          rules.regex('رقم الجوال', /^\d{9}$/, 'رقم الجوال يجب أن يكون 9 أرقام ويحتوي على أرقام فقط')
      ],
      email: [
          rules.required('البريد الالكتروني'),
          rules.regex('البريد الالكتروني', /^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'صيغة البريد الالكتروني غير صحيحة')
      ],
      password: [
          rules.required('كلمة المرور'),
          rules.length('كلمة المرور', 8),
          rules.custom('كلمة المرور', (val) => val.trim().length >= 8, 'كلمة المرور يجب ألا تكون فارغة أو تحتوي فقط على مسافات')
      ]
  }
    const onSignup = async () => {
        setLoading(true)
        const errorMessage = validateFields(fields, validationRules)
        if (errorMessage) {
            setError(errorMessage)
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
            setLoading(false)
            return
        }
        try {
          await signup({ mobile_number: mobile, email })
          navigate('/confirm-account', { state: { mobile_number: mobile, password, full_name: fullName, email, isTest } })
          setLoading(false)
        } catch (error) {
          setError(error.message)
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
          if (error.message.includes("Error: تم التسجيل سابقا بنفس رقم الجوال")) {
            setError("تم التسجيل سابقا بنفس رقم الجوال")
          } else if (error.message.includes("Error: تم التسجيل سابقا بنفس البريد الالكتروني")) {
            setError('تم التسجيل سابقا بنفس البريد الالكتروني')
          } else {
            setError(error.message)
          }
          setLoading(false)
        }
      }
    
    return (
        <Box position='relative' maxWidth="790px" maxHeight="100" mx='auto' zIndex={10}>
            <Container width={isMobile?'380px': '100%'} sx={{ zIndex: '10 !important', position: 'relative'}} marginTop="40px" customHeight="765px">
                <Grid container justifyContent='center' alignItems='center' paddingX={isMobile ? '45px' : 0}>
                    {!isMobile && <Grid item xs={12} md={12} justifyContent='center' marginTop='65px' sx={gridStyles}>
                        <SignupHeader>كافة الحقول مطلوبة</SignupHeader>
                    </Grid>}
                    <Grid item xs={12} md={12} justifyContent='center' marginTop={isMobile? '0px':'16px'} sx={gridStyles}>
                        <CustomPlainTextField label='الاسم  الكامل' value={fullName}  setValue={(e) => setFullName(e.target.value)} CustomWidth={isMobile ? '300px' : '380px'}/>
                    </Grid>
                    <Grid item xs={12} md={12} mt={isMobile?'40px':'30px'} sx={gridStyles}>
                        <PhoneTextField value={mobile} setValue={(e) => setMobile(e.target.value)} CustomWidth={isMobile ? '300px' : '380px'}/>
                    </Grid>
                    <Grid item xs={12} md={12} mt={isMobile?'40px':'30px'} sx={gridStyles}>
                        <CustomPlainTextField label='البريد الالكتروني' value={email} setValue={(e) => setEmail(e.target.value)}  CustomWidth={isMobile ? '300px' : '380px'}/>
                    </Grid>
                    <Grid item xs={12} md={12} mt={isMobile?'40px':'30px'} sx={gridStyles}>
                        <PasswordTextField label='كلمة المرور' value={password} setValue={(e) => setPassword(e.target.value)} CustomWidth={isMobile ? '300px' : '380px'}/>
                    </Grid>
                    <Grid item xs={12} md={12} sx={gridStyles}>
                    </Grid>
                    <Grid item xs={12} md={12} mt={isMobile? '12px':'24px'} pb={0} mb={0} sx={gridStyles}>
                        <SignupPhoneRule>يجب أن يكون رقم الجوال المستخدم نفس الرقم المسجّل للمحامي لدى وزارة العدل</SignupPhoneRule>
                    </Grid>
                    <Grid item container xs={12} md={12} mt={isMobile? '47px': '90px'} sx={gridStyles}>
                        <TermsAndConditions onClick={() => navigate('/terms-and-conditions')} style={{ textAlign: 'right' }}> بالضغط على إرسال فإنك توافق على <TermsAndConditionsLink >سياسة الخصوصية و شروط الاستخدام </TermsAndConditionsLink> </TermsAndConditions>

                    </Grid>
                    <Grid item xs={12} md={12} mt={isMobile? '24px':'23px'} mb={isMobile? '93':'86px'} sx={gridStyles}>
                        <CustomButton onClick={onSignup} CustomWidth={isMobile ? '300px' : '380px'} CustomColor='#B4975A'>
                            <ButtonText customFontSize='18px'>إرسال طلب التسجيل</ButtonText>
                        </CustomButton>
                    </Grid>
                </Grid>
            </Container>
            {!useMediaQuery('(max-width:990px)') && <Box overflow={'overlay'} mt={-12} mr={-30}>
                <img src='login-background.svg' alt='login' onLoad={onLoaded} />
            </Box>}
            <LoadingBackDrop loading={loading} />
        </Box>
    )
}

export default SignupBox