import React from 'react'
import { Grid, useMediaQuery } from '@mui/material'
import { CertificateItem, CertificateText, CertificateTextLight, CertificateDelete } from 'components/EditAccount/EditAccountStyles'

const Language = ({ language, marginBottom, onDelete }) => {

    const isMobile = useMediaQuery('(max-width:768px)')
    return (
        <CertificateItem marginBottom={marginBottom ? marginBottom : '0'} >
            <Grid display='flex' justifyContent='space-between' gap='10px' alignItems='center' width={isMobile ? 'calc(100vw - 80px);' : '420px'} maxWidth='402px' >
                <Grid display='flex' gap='10px'>
                    <img src='./arrow-bolditem.svg' alt='ArrowBold' width={10} />
                    <CertificateText ml={isMobile? '0' : '116px'}>{language}</CertificateText>
                </Grid>
                <CertificateDelete onClick={onDelete}>
                    <img src='./delete.svg' alt='DeleteIcon' width={14} />
                    حذف
                </CertificateDelete>
            </Grid>
            <hr style={{ borderTop: '1px solid #E3E1DC', opacity: 1, margin: '13px 0' }} />
        </CertificateItem>
    )
}

export default Language