import { getUserStatus, login } from 'api/auth'
import React, { createContext, useContext, useEffect, useState } from 'react'

const AuthContext = createContext()

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null)

  useEffect(() => {
    const token = localStorage.getItem('authToken')
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (token) {
      setUser(userData)    
    }
  }, [user])

 const authLogin = async (userData) => { 
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await login(userData)
      if (response.result && response.result.success) {
        const { token } = await response
        setUser(response.result.user)
        localStorage.setItem('authToken', token)
        localStorage.setItem('userData', JSON.stringify(response.result.user))
        return true
      } else {
        throw Error(response.message)
      }
    } catch (error) {
      throw error
    }
  }
  const logout = () => {
    localStorage.removeItem('token')
    setUser(null)
  }

  const isAuthenticated = () => {
    return user !== null
  }

  return (
    <AuthContext.Provider value={{ user, authLogin, logout, isAuthenticated, setUser }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

