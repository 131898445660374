import { useMediaQuery } from '@mui/material'
import '../../styles/home.css'
import React from 'react'
import { Container, ModalTitle, Button } from 'react-bootstrap'
import { padding } from '@mui/system'
import { useNavigate } from 'react-router-dom'

const HomeSection = () => {

    const isMobile = useMediaQuery('(max-width:600px)')
    const navigate = useNavigate()

    return (
        <Container className='home-container'>
            <div className='home-section'>
                <div className='home-section-inner right'>
                    <img src='../background-right.png' className='home-section-back right'></img>
                    <div className='home-section-content'>
                        <h4 className='home-section-title'>دَليل المُحامين</h4>
                        <p className='home-section-text'>محامون ذوي خبرة وفي الخدمة</p>
                        <Button className='home-section-button' onClick={()=>{navigate("lawyer-guide")}}>
                            تصفح الآن
                            <img src='../arrow.svg' alt='arrow' width={20}></img>
                        </Button>
                    </div>

                </div>
                <div className='home-section-inner left'>
                    <img src='../background-left.png' alt='background' className='home-section-back left'></img>
                    <div className='home-section-content'>
                        <h4 className='home-section-title left'>
                            اسأل محامي
                            <strong style={{ color: '#fff' }}> مجاناً</strong>
                        </h4>
                        <img className='home-section-title-img' src='../underline.svg' alt='underline' />
                        <p className='home-section-text left'>احصل على أفضل الاجابات</p>
                        <Button className='home-section-button left' onClick={()=>{navigate("add-question"); window.scrollTo(0, 0)}}>
                            اسأل الآن
                            <img src='../arrow_brown.svg' alt='arrow' width={20}></img>
                        </Button>
                        {isMobile && (
                            <Button className='home-section-button left bottom' onClick={()=>{navigate("questions-list"); window.scrollTo(0, 0)}}>
                                <img src='../arrow_white.svg' alt='arrow' width={18}></img>
                                أو تصفح الأسئلة والأجوبة
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default HomeSection