import FooterSection from 'Layout/Footer'
import Layout from 'Layout/Layout'
import AboutusComponent from 'components/Aboutus/AboutusComponent'
import React from 'react'

const Aboutus = () => {
  return (
    <Layout>
        <AboutusComponent/>
        <FooterSection />
    </Layout>
  )
}

export default Aboutus