import styled from "@emotion/styled"
import { Button, TextField, Typography, Grid, Box, css } from "@mui/material"

export const LawyerName = styled(Typography)`
font-family: Tajawal;
font-size: 28px;
font-weight: 700;
line-height: 34px;
letter-spacing: 0em;
text-align: right;
@media (max-width: 768px) {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
}
`
export const LawyerSpec = styled(Typography)`
font-family: Tajawal;
font-size: 18px;
font-weight: 500;
line-height: 22px;
letter-spacing: 0em;
text-align: right;
color: #B4975A;
@media (max-width: 768px) {
  font-size: 15px;
  line-height: 18px;
  text-align: center;
}
`

export const LawyerCity = styled(Typography)`
font-family: Tajawal;
font-size: 19px;
font-weight: 500;
line-height: 23px;
letter-spacing: 0em;
text-align: right;
color: #34312A;
opacity: 70%;	
@media (max-width: 768px) {
  font-size: 15px;
  line-height: 18px;
  text-align: center;
}
`

export const LawyerItemTitle = styled(Typography)`
font-family: Tajawal;
font-size: 26px;
font-weight: 700;
line-height: 31px;
letter-spacing: 0em;
text-align: right;
color: #825925;
@media (max-width: 768px) {
  font-size: 20px;
  line-height: 24px;
}
`

export const AboutLawyerText = styled(Typography)`
font-family: Tajawal;
font-size: 20px;
font-weight: 500;
line-height: 35px;
letter-spacing: 0em;
text-align: right;
color: #000;
line-break: normal;
@media (max-width: 768px) {
  font-size: 16px;
  line-height: 24px;
}
`

export const SpecializationText = styled(Typography)`
font-family: Tajawal;
font-size: 16px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
text-align: center;
color: #B4975A;
@media (max-width: 768px) {
  font-size: 14px;
}
`

export const BioTitle = styled(Typography)`
font-family: Tajawal;
font-size: 22px;
font-weight: 700;
line-height: 26px;
letter-spacing: 0em;
text-align: right;
color: #000;
@media (max-width: 768px) {
  font-size: 20px;
  line-height: 24px;
}
`

export const BioSubTitle = styled(Typography)`
font-family: Tajawal;
font-size: 20px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
text-align: right;
color: #000;
@media (max-width: 768px) {
  font-size: 17px;
  line-height: normal;
}
`

export const RatingTitle = styled(BioSubTitle)`
@media (max-width: 768px) {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}
`

export const BioText = styled(Typography)`
font-family: Tajawal;
font-size: 18px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
text-align: right;
color: #000000B2;

`

export const RatingCount = styled(Typography)`
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 17px;
letter-spacing: 0.02em;
text-align: right;
color: #757575;
`

export const BioSubTitleLight = styled(RatingCount)`
font-family: Tajawal;
color: #999999!important;
letter-spacing: 0px!important;

`

export const RatingValueChart = styled(Typography)`
font-family: Inter;
font-size: 14px;
font-weight: 600;
line-height: 17px;
letter-spacing: 0.02em;
text-align: left;
color: #222222;
`

export const RatingValueText = styled(Typography)`
font-family: Inter;
font-size: 25px;
font-weight: 700;
line-height: 30px;
letter-spacing: 0.02em;
text-align: left;
color: #222222;
`

export const ReviewDateText = styled(Typography)`
font-family: Tajawal;
font-size: 15px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0em;
text-align: right;
color: #34312AB2;
`

export const ReviewText = styled(Typography)`
font-family: Tajawal;
font-size: 16px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.02em;
text-align: right;
color: #757575;
`

export const ReviewName = styled(Typography)`
font-family: Tajawal;
font-size: 16px;
font-weight: 700;
line-height: 19px;
letter-spacing: 0.02em;
text-align: right;
color: #454545;
`

export const ReviewShowMore = styled(Typography)`
font-family: Tajawal;
font-size: 14px;
font-weight: 700;
line-height: 17px;
letter-spacing: 0px;
text-align: left;
color: #000;
`

export const Twitter = styled(Typography)`
font-family: Tajawal;
font-size: 26px;
font-weight: 500;
line-height: 31px;
letter-spacing: 0em;
text-align: right;
color: #1F2732;
`

export const LinkedIn = styled(Typography)`
font-family: Tajawal;
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0em;
text-align: right;
text-transform: none;
color: #1F2732;
`

export const BioTextLight = styled(BioText)`
color: #00000066;
@media (max-width: 768px) {
  font-size: 16px;
  line-height: normal;
}
`

export const SpecializationBox = styled(Box)`
padding: 10px 35px;
border: 1px solid #B4975A;
border-radius: 40px;
width: fit-content;
cursor: pointer;
@media (max-width: 768px) {
  padding: 10px 20px;  
}
`

export const VerticalLine = styled(Box)`
border-left: 1px solid #EEEEEE;
margin-top: 148px;
margin-bottom: 50px;
`

export const RatingBoxWrapper = styled(Grid)`
border: 2px solid #E3E1DC;
margin-top: 15px;
height: 100px;
padding: 22px 13px 18px 0;
width: 90%;
`

export const NavbarItem = styled(Button)`
	padding: 10px 35px;
	border-radius: 20px;
	background: #fff;
	color: #000;
	text-align: center;
	font-family: Tajawal;
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: right;
	@media (max-width: 768px) {

  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
	}

`

export const LawyerInfoBox = styled(Box)`
justify-content: space-between;
width: 100%;
background-color: #F5F4F0;
border-radius: 10px;
position: relative;
::before{
	content: ' ';
    background-image: url(../login-background.svg);
    width: 100%;
    position: absolute;
    height: 188px;
    left: 0;
    background-repeat: no-repeat;
    bottom: 0;
}
@media (max-width: 768px) {
  justify-content: center;
  border-radius: 0px;
}
`

export const ContactInfoBox = styled(Box)`
justify-content: space-between;
width: 100%;
background-color: #F5F4F0;
border-radius: 10px;
@media (max-width: 768px) {
  border-radius: 0px;
}
`

export const LawyerBasicInfo = styled(Box)`
display: grid;
width: 100%;
align-content: center;
gap: 6px;
margin: 20px 40px 0 0;
@media (max-width: 768px) {
  margin: 20px 0 0 0;
}
`

export const LicenceBoxGrid = styled(Grid)`
display: grid;
width: 240px;
height: 118px;
background-color: #FEFDF9;
border-radius: 10px;
align-content: center;
position: relative;
z-index: 10;
@media (max-width: 768px) {
  margin-top: 38px;
}
`

export const LicenseBoxTitle = styled(Typography)`
font-family: Tajawal;
font-size: 18px;
font-weight: 500;
line-height: 22px;
letter-spacing: 0em;
text-align: center;
`

export const LicenseBoxNumber = styled(Typography)`
font-family: Tajawal;
font-size: 24px;
font-weight: 500;
line-height: 29px;
letter-spacing: 0.08em;
text-align: center;
`

export const LawyerTitle = styled(Typography)`
color: #1F2732;
font-family: Tajawal;
font-size: 30px;
font-weight: 700;
line-height: 36px;
letter-spacing: 0em;
font-weight: 700;
@media (max-width: 768px) {
  font-size: 20px;
  margin-right: 20px;
  font-weight: 500;
}
`

export const SignupSuccessBox = styled(Box)`
width: 90%;
height: 233px;
flex-shrink: 0;
fill: #FFF; 
background: #FFF;
border-radius: 10px;
box-shadow: 0px 4px 40px 0px rgba(130, 89, 37, 0.05);
filter: drop-shadow(0px 4px 40px rgba(130, 89, 37, 0.05));
@media (max-width: 768px) {
  font-size: 18px;
  height: 149px;
  width: 90%;
}
`

export const SignupSuccessHeading = styled(Typography)`
color: #1F2732;
text-align: center;
font-family: Tajawal;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-top: 81px;
@media (max-width: 768px) {
  font-size: 18px;
  padding-top: 39px;
}
`
export const SignupSuccessSubHeading = styled(Typography)`
color: #1F2732;
text-align: center;
font-family: Tajawal;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
@media (max-width: 768px) {
  font-size: 15px;
  width: 257px;
}
`