import { LinkNav, GridNav } from 'components/Account/AccountStyles'
import { useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import React from 'react'

const PageNav = ({ parentText, childText, navigateTo }) => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:768px)')

    return (
        <GridNav>
            {(!isMobile) && (
                <>
                    <LinkNav onClick={() => navigate(navigateTo)} >{parentText}</LinkNav >
                    <img src='./arrow-account.svg' alt='Arrow' width={6} />
                    <LinkNav color={'#B4975A!important'} style={{cursor: 'default'}}>{childText}</LinkNav >
                </>
            )}
            {(isMobile) && (
                <>
                    <img src='./arrow-right.svg' alt='Arrow' width={18} />
                    <LinkNav color={'#B4975A!important'} onClick={() => navigate(navigateTo)} >{parentText}</LinkNav >
                </>
            )}
        </GridNav>

    )
}

export default PageNav