import React, { useState } from 'react'

import { StyledSearchIcon } from '../LawyersListing/LawyersListingStyle'
import {
    Box, Button, Typography, useMediaQuery
} from '@mui/material'
import { ClearButton, FilterButton, SearchButton, SearchContainer, StyledInputBase } from 'components/QuestionsList/QuestionsListStyles'

const QuestionSearch = (props) => {
    const [searchValue, setSearchValue] = useState(sessionStorage.getItem('quesitonsSearchValue') || '')
    const isMobile = useMediaQuery('(max-width:768px)')

    const handleInputChange = (event) => {
        setSearchValue(event.target.value)
        sessionStorage.setItem('quesitonsSearchValue', event.target.value)
    }
    const handleSearchClick = () => {
        props.onSearch(searchValue) 
    }
    const handleFilterClick = (event) => {
        props.onFilterClick(event) 
    }
    const handleFilterClearClick = (event) => {
        props.onFilterClearClick(event) 
        setSearchValue('')
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearchClick()
        }
    }

    return (
        <Box display="flex" sx={{ backgroundColor: "", alignItems: "center", marginBottom: "37px", flexWrap: "wrap", width: "100%" }}>
            <SearchContainer>
                <img src='./search-icon.svg' alt='icon-search' style={{margin: '0 15px 0 0'}}/>
                <form onSubmit={e => e.preventDefault()} action="." style={{width: '100%'}}>
                <StyledInputBase
                    placeholder="ابحث عن سؤالك"
                    value={searchValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    type='search'
                />
                </form>
                <SearchButton onClick={handleSearchClick}>البحث</SearchButton>
            </SearchContainer>

            <Box sx={isMobile ? {display: 'flex', justifyContent: 'space-between', width: '100%'} : { display: "flex", backgroundColor: "" }}>
                <FilterButton onClick={handleFilterClick}>
                    <img src={isMobile ? '/filter-mobile.svg' : '/filter.svg'}></img>
                    <Typography sx={{
                        fontSize: "19px",
                        fontWeight: 400,
                        marginRight: "10px",
                        marginBlock: 0,
                    }} >تصفية</Typography>
                </FilterButton>
                <ClearButton 
                    onClick={handleFilterClearClick} 
                    style={{ display: props.isFilterClearVisible ? 'block' : 'none' }}
                >حذف خيارات التصفية</ClearButton>
            </Box>
        </Box>

    )
}

export default QuestionSearch
