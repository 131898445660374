import { Box, useMediaQuery } from '@mui/material'
import FooterSection from 'Layout/Footer'
import Layout from 'Layout/Layout'
import { getAnswerByQuestionId } from 'api/answers'
import { getQuestion } from 'api/questions'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import LoadingBackDrop from 'components/Loading/LoadingBackDrop'
import AddAnswer from 'components/Question/AddAnswer'
import AnswerBox from 'components/Question/AnswerBox'
import { AnswerHeading, HireLawyerText, HireLawyerWrapper, NeedHelp } from 'components/Question/AnswerStyles'
import QuestionBox from 'components/Question/QuestionBox'
import { QuestionPageHeader, QuestionPageSubHeader } from 'components/Question/QuestionStyles'
import { useAuth } from 'context/auth'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

const Question = () => {
  const [questionData, setQuestionData] = useState({})
  const [answerData, setAnswerData] = useState([])
  const [addAnswer, setAddAnswer] = useState(false)
  const [loading, setLoading] = useState(false)
  const [firstFetch, setFirstFetch] = useState(true)
  const [error, setError] = useState(false)
  const {user} = useAuth()
  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width:768px)')
  let { id } = useParams()
  const [searchParams] = useSearchParams()
  const isTest = searchParams.get('isTest') === 'true'

  useEffect(() => {
    const fetchQuestion = async () => {
      setLoading(true)
      try {
        const data = await getQuestion(id)
        setQuestionData(data)
        const fetchAnswerData = await getAnswerByQuestionId(data.question_id, isTest)
        setAnswerData(fetchAnswerData?.answers)
        const userAnswer = fetchAnswerData?.answers?.find(answer => answer?.user_id === user?.id) ?? null
        setAddAnswer(userAnswer ? false : true)
        setLoading(false)
        setFirstFetch(false)
      } catch (err) {
        setError(err.message)
        setLoading(false)
      }
    }
    fetchQuestion()
  }, [firstFetch])

  return (
    <Layout isWhiteBackground>
        {error && <ErrorMessage message={error} />}
        <Box display='flex' alignItems='center' justifyContent={isMobile? 'space-between': 'right'} gap={isMobile? '0px':'13px'} mt={isMobile? '16px':'94px'} mr={isMobile? '20px':'60px'} mb={isMobile? '34px':'50px'}>
        <QuestionPageHeader>الاسئلة والأجوبة</QuestionPageHeader>
        <QuestionPageSubHeader onClick={() => navigate('/add-question')} ml={isMobile? '20px': '0px'}>+ اسأل محامي مجاناً</QuestionPageSubHeader>
        </Box>
        <QuestionBox question={questionData}/>
        <AnswerHeading mr={isMobile? '20px':'60px'} mt='38px' mb='30px'>الإجابة</AnswerHeading>
        {answerData?.map((answer, index) => (
          <AnswerBox key={index} answer={answer} setError={setError}/>
        ))}
        {addAnswer && user && user.status === 'active' && <AddAnswer setError={setError}/>}
        <Box display='flex' alignItems='center' mr={isMobile? '20px':'62px'} mt={isMobile?'54px':'77px'} mb={isMobile? '73px':'184px'}>
        <NeedHelp>تحتاج مساعدة؟</NeedHelp>
        <HireLawyerWrapper onClick={() => navigate('/lawyer-guide')}>
          <HireLawyerText>ابحث عن محامي!</HireLawyerText>
        </HireLawyerWrapper>
        </Box>
        <FooterSection/>
        <LoadingBackDrop loading={loading} />
    </Layout>
    
  )
}

export default Question