import { Outlet, Navigate } from 'react-router-dom'
import React from 'react'

const PrivateRoutes = () => {
    let auth = localStorage.getItem('authToken')
    return(
        auth ? <Outlet/> : <Navigate to="/"/>
    )
}

export default PrivateRoutes