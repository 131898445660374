import styled from "@emotion/styled"
import { Box, Button, TextField, Typography } from "@mui/material"

export const Heading = styled.p(`
color: var(--Main, #B4975A);
text-align: center;
font-family: Tajawal;
font-size: 50px;
padding-top: 143px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-bottom: 0px;
@media (max-width: 768px) {
    background: #ffff;
    font-size: 28px;
    padding-top: 30px;
  }
`)

export const Container = styled(Box)`
margin: auto;
margin-top: ${({ marginTop }) => marginTop ? marginTop : '86px'};
max-width: 765px;
max-height: ${({ customHeight }) => customHeight ? customHeight : '600px'};;
flex-shrink: 0;
border-radius: 30px;
background: #FFF;
box-shadow: 0px 4px 40px 0px rgba(130, 89, 37, 0.05);
@media (max-width: 768px) {
    max-width: 100%;
    height: 100vh;
    border-radius: 0; 
    margin-top: 0;
    box-shadow: none; 
  }
`

export const CustomTextField = styled(TextField)`
    height: 50px;
    border-radius: 4px;
    border: ${({ isFocused }) => isFocused ? "2px solid var(--Secondary,#825925);" : '2px solid var(--Secondary,#E3E1DC);'} 
    background: #FFF;
`

export const PhoneCode = styled.p`
color: #000;
text-align: right;
font-family: Tajawal;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
opacity: 0.3;
transform: translate(-17px, 6px);
`

export const Flag = styled.img`
transform: translate(-10px, -4px);
`

export const ClosedEye = styled.img`
transform: translate(-10px, -4px);
cursor: pointer;
`

export const OpenEye = styled.img`
transform: translate(-10px, -4px);
cursor: pointer;
`

export const ForgetPassword = styled.p`
color: var(--Main, #B4975A);
margin-top: 14px;
margin-bottom: 0px;
text-align: left;
width: 380px;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Tajawal;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
@media (max-width: 768px) {
  font-size: 16px;
  width: 320px;
  padding-left: 10px;
}
cursor: pointer;
`

export const CustomButton = styled(Button)`
display: flex;
width: ${({ CustomWidth }) => CustomWidth ? CustomWidth : '380px'};;
height: 48px;
padding: 12px 14px;
justify-content: center;
align-items: center;
gap: 20px;
flex-shrink: 0;
border-radius: 40px;
background: ${({ CustomColor }) => CustomColor ? CustomColor : 'rgba(180, 151, 90, 0.80)'};
&&{
  background: ${({ CustomColor }) => CustomColor ? CustomColor : 'rgba(180, 151, 90, 0.80)'};
}
@media (max-width: 768px){
    max-width: 80vw;
}
`

export const ButtonText = styled(Typography)`
color: #FFF;
text-align: center;
font-family: Tajawal;
font-size: ${({ customFontSize }) => customFontSize ? customFontSize : '20px'};;
font-style: normal;
font-weight: 400;
line-height: normal;
`

export const NewAccountText = styled(Typography)`
color: #3A3A3A;
text-align: right;
font-family: Tajawal;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
@media (max-width: 768px) {
  font-size: 15px;
}
`