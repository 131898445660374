import React from 'react'
import { Box, Grid, styled, useMediaQuery } from '@mui/material'
import { RatingValueChart, RatingCount } from '../LawyersProfile/LawyersProfileStyles'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'

const BorderLinearProgress = styled(LinearProgress)(({width}) => ({
    height: '5px',
    borderRadius: 5,
    width: width,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#E5E5E5',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#EEA100',
    },
}))
const RatingBar = ({ rating, value, count }) => {
    const isMobile = useMediaQuery('(max-width:408px)')
    const progressBarValue = value || 0
    const noReviews = count === 0
    return (
        <Box display="flex" alignItems="center" justifyContent='flex-end' width={isMobile ? '256px' : '306px'}>
        <Grid >
            <RatingCount>({count})</RatingCount>
        </Grid>
        <Grid justifyContent='center' mr='20px'  >
            <BorderLinearProgress  
            sx={{
                backgroundColor: noReviews ? '#E5E5E5' : '#EEA100',
            }}
            width= {isMobile ? '165px' : '190px'} 
            variant="determinate" 
            value={progressBarValue}  
            />
        </Grid>
        <Grid width={isMobile ? '45px' : '65px'}>
            <RatingValueChart >{rating}</RatingValueChart>
        </Grid>
    </Box>
    )
}

const RatingBarChart = ({ratings}) => (
    <Grid display='grid' gap='8px' mt='26px'>
            <RatingBar rating="5/5" value={(ratings?.ratingsCount?.star5/ratings.totalReviews)*100?? 0} count={ratings?.ratingsCount?.star5?? 0} />
            <RatingBar rating="4/5" value={(ratings?.ratingsCount?.star4/ratings.totalReviews)*100?? 0} count={ratings?.ratingsCount?.star4?? 0} />
            <RatingBar rating="3/5" value={(ratings?.ratingsCount?.star3/ratings.totalReviews)*100?? 0} count={ratings?.ratingsCount?.star3?? 0} />
            <RatingBar rating="2/5" value={(ratings?.ratingsCount?.star2/ratings.totalReviews)*100?? 0} count={ratings?.ratingsCount?.star2?? 0} />
            <RatingBar rating="1/5" value={(ratings?.ratingsCount?.star1/ratings.totalReviews)*100?? 0} count={ratings?.ratingsCount?.star1?? 0} />
    </Grid>
)

export default RatingBarChart
