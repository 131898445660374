import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CardMedia, Grid, Rating, useMediaQuery } from '@mui/material'
import { CityText, DescriptionText, LawyerInfoBox, NameText, Qualification, RatingBoxWrapper, StyledCard, StyledCardContent, StyledCardMedia, } from './LawyersListingStyle'
import { useNavigate } from 'react-router-dom'
import RatingBox from 'components/LawyerComponents/RatingBox'
import Stars from 'components/LawyerComponents/Stars'

export const LawyerCard = ({ lawyer }) => {
	const reviewScore = Math.round(lawyer.rating)
	const navigate = useNavigate()
	const isMobile = useMediaQuery('(max-width:768px)')
	const totalScore = 5
	return (
		<StyledCard>
			<StyledCardContent>
				<RatingBoxWrapper >
					<StyledCardMedia
						component="img"
						image={`https://storage.googleapis.com/qanooni-lawyer-image/${lawyer?.id}` || "top-lawyer-default.svg"}
						alt="lawyer"
						onClick={() => navigate(`/profile/${lawyer.id}`)}
						onError={(e) => {
							e.target.onerror = null
							e.target.src = "top-lawyer-default.svg"
						}}
					/>
					<LawyerInfoBox>
						<NameText onClick={() => {
							navigate(`/profile/${lawyer.id}`)
							window.scrollTo(0, 0)
						}}>{lawyer.full_name || 'صافي السلمي'}</NameText>
						{isMobile &&
							<Qualification color="#B4975A">محامي مرخص</Qualification>
						}
						<CityText onClick={() => {
							navigate(`/profile/${lawyer.id}`)
							window.scrollTo(0, 0)
						}} color="text.secondary">المدينة: {lawyer?.city}</CityText>
						{reviewScore > 0 &&
							<Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', cursor: 'pointer' }} onClick={() => {
								navigate(`/profile/${lawyer.id}`)
								window.scrollTo(0, 0)
							}}>
								<Stars rate={reviewScore} size={'15px'} />
								<Typography sx={{ mr: "6px", mt: "2px", fontWeight: '500', fontSize: '15px', lineHeight: 'normal' }}>
									{`${reviewScore}/${totalScore}`}
								</Typography>
							</Box>
						}
						<DescriptionText sx={{ display: { xs: 'none', md: 'block' } }}>
							{lawyer.description && lawyer.description.length > 220
								? lawyer.description.slice(0, 220) + '...'
								: (lawyer.description ?? `يقدم الدعم القانوني...`)
							}
						</DescriptionText>

					</LawyerInfoBox>
				</RatingBoxWrapper>
				<Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}>
					<DescriptionText>
						{lawyer.description && lawyer.description.length > 220
							? lawyer.description.slice(0, 220) + '...'
							: (lawyer.description ?? `يقدم الدعم القانوني...`)
						}
					</DescriptionText>
				</Grid>
			</StyledCardContent>
		</StyledCard>
	)
}
