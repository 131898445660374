import { Outlet, Navigate } from 'react-router-dom'
import React from 'react'

const PublicRoutes = () => {
  let auth = localStorage.getItem('authToken')
  return(
    !auth ? <Outlet/> : <Navigate to="/"/>
  )
}

export default PublicRoutes
