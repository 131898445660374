import { Box } from '@mui/material'
import React, { useState } from 'react'
import { AnswerEditTextField, AnswerTextField, WriteAnswer } from './AnswerStyles'
import RTLProvider from 'helpers/RTLProvider'
import { ButtonText, CustomButton } from 'components/Login/LoginStyles'

const EditAnswer = ({editField, setEditField, onSubmit}) => {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <Box mt='38px'>
    <WriteAnswer>أكتب اجابتك</WriteAnswer>
    <RTLProvider>
    <AnswerEditTextField 
    label='اكتب أجابتك هنا'
    multiline
    rows={12}
    value={editField}
    isFocused ={isFocused}
    onFocus={() => setIsFocused(true)}
    onBlur={() => setIsFocused(false)}
    onChange={(e) => setEditField(e.target.value)}
    />
    </RTLProvider>
    <Box mt='25px' pb='165px'>
        <CustomButton CustomWidth="190px" CustomColor="#B4975A" onClick={onSubmit}>
            <ButtonText>تعديل</ButtonText>
        </CustomButton>
    </Box>
</Box>
  )
}

export default EditAnswer