import BASE_URL from "./BaseURL"

export const getAnswerByQuestionId = async (answerId, isTest = false) => {
    try {
      const response = await fetch(`${BASE_URL}/getAnswersByQuestionId/${answerId}?isTest=${isTest}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }

  export const addAnswer = async answer => {
    try {
      const response = await fetch(`${BASE_URL}/addAnswer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(answer)
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }

  export const editAnswer = async (answer, answer_id) => {
    try {
      const token = localStorage.getItem('authToken')
      const response = await fetch(`${BASE_URL}/updateAnswer/${answer_id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'token': token
        },
        body: JSON.stringify(answer)
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }

  export const likeAnswer = async answer_id => {
    try {
      const response = await fetch(`${BASE_URL}/likeAnswer/${answer_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }

  export const removeLike = async answer_id => {
    try {
      const response = await fetch(`${BASE_URL}/removeLike/${answer_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }

  export const removeDislike = async answer_id => {
    try {
      const response = await fetch(`${BASE_URL}/removeDislike/${answer_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }

  export const dislikeAnswer = async answer_id => {
    try {
      const response = await fetch(`${BASE_URL}/dislikeAnswer/${answer_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }

  export const getAnswersByUserId = async answerData => {
    try {
      const response = await fetch(`${BASE_URL}/getAnswersByUserId/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(answerData)
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data.answers
    } catch (error) {
      throw Error(error.message) || error
    }
  }

  export const getAnswersCountByUserId = async answerData => {
    try {
      const response = await fetch(`${BASE_URL}/getAnswersCountByUserId/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(answerData)
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }