import BASE_URL from "./BaseURL"

export const getProvinces = async () => {
    try {
      const response = await fetch(`${BASE_URL}/get-provinces`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }

  export const getAllCities = async () => {
    try {
      const response = await fetch(`${BASE_URL}/get-cities`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }
  
  export const getCitiesPerProvince = async province => {
    try {
      const response = await fetch(`${BASE_URL}/get-province-cities/${province}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }
  