import { Box, useMediaQuery } from '@mui/material'
import FooterSection from 'Layout/Footer'
import Layout from 'Layout/Layout'
import React from 'react'
import { useAuth } from 'context/auth'
import { useLocation } from 'react-router-dom'
import AnsweredQuestionsSection from 'components/AnsweredQuestions/AnsweredQuestionsScreen'

const AnsweredQuestions = () => {
    const isMobile = useMediaQuery('(max-width:600px)')
    const { user } = useAuth()
    const isUserApproved = user?.status === 'active'
    
    return (
        <Layout isWhiteBackground={true}>
            <AnsweredQuestionsSection />
            <FooterSection />
        </Layout>
    )
}

export default AnsweredQuestions