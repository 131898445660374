/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react'
import { AddQuestionCounter, AddQuestionEmailTerms, AddQuestionEmailText, AddQuestionHeading, AddQuestionInput, AddQuestionLabel, AddQuestionOtherInput, AddQuestionRequired } from './AddQuestionStyles'
import { Box, FormControl, Grid, useMediaQuery } from '@mui/material'
import { CustomInputLabel, MenuItemCustom, SelectSpeciality, SpecialityItem } from 'components/EditAccount/EditAccountStyles'
import RTLProvider from 'helpers/RTLProvider'
import { ReviewModalRadioText, StyledRadio } from 'components/LawyerComponents/ReviewModalStyles'
import CustomPlainTextField from 'components/FormComponents/CustomPlainTextField'
import { ButtonText, CustomButton } from 'components/Login/LoginStyles'
import AddQuestionSuccess from './AddQuestionSuccess'
import { addQuestion } from 'api/questions'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import LoadingBackDrop from 'components/Loading/LoadingBackDrop'
import { categories } from 'constants/user'
import { rules, validateFields } from 'helpers/validation'
import { useSearchParams } from 'react-router-dom'

const AddQuestionComponent = () => {
    const [speciality, setSpeciality] = useState()
    const [question, setQuestion] = useState('')
    const [otherDetails, setOtherDetails] = useState('')
    const [questionSpecialities, setQuestionSpecialities] = useState([])
    const [visibleEmail, setVisibleEmail] = useState(false)
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const isMobile = useMediaQuery('(max-width:768px)')
    const [searchParams] = useSearchParams()
    const isTest = searchParams.get('isTest') === 'true'
    const [error, setError] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const fields = { question, otherDetails, questionSpecialities, email }
    
    const validationRules = {
        question: [
            rules.required('السؤال'),
            rules.noSqlInjection('السؤال', 'السؤال يحتوي على رموز غير صالحة'),
            rules.noXss('السؤال', 'السؤال يحتوي على نصوص غير صالحة')
        ],
        otherDetails: [
            rules.required('التفاصيل الاخري', 'التفاصيل الاخري مطلوبة'),
            rules.noSqlInjection('السؤال', 'تفاصيل اخري تحتوي على رموز غير صالحة'),
            rules.noXss('السؤال', 'تفاصيل اخري تحتوي على نصوص غير صالحة')
        ],
        questionSpecialities: [
            rules.custom('تخصص السؤال مطلوب', (val) => val.length > 0, 'تخصص السؤال مطلوب')
        ],
        email: [
            rules.custom('البريد الالكتروني مطلوب', (val) => !visibleEmail || (visibleEmail && val), 'البريد الالكتروني مطلوب'),
            rules.custom('صيغة البريد الالكتروني غير صحيحة', (val) => !visibleEmail || (visibleEmail && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val)), 'صيغة البريد الالكتروني غير صحيحة')
        ]
    }
    
    const addSpeciality = () => {
        if (speciality && !questionSpecialities.includes(speciality)) {
            setQuestionSpecialities(prevSpecialities => [...prevSpecialities, speciality])
            setSpeciality()
        }
    }

    const removeSpeciality = (specialityToRemove) => {
        setQuestionSpecialities(prevSpecialities => prevSpecialities.filter(spec => spec !== specialityToRemove))
    }

    useEffect(() => {
        addSpeciality()
    }, [speciality])

    const onAddQuestion = async () => {
        const errorMessage = validateFields(fields, validationRules)
        if (errorMessage) {
            setError(errorMessage)
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
            setLoading(false)
            return
        }
        try {
            setLoading(true)
            await addQuestion({ question, other_details: otherDetails, notified: visibleEmail, email, specializations: questionSpecialities, isTest })
            setLoading(false)
            setOpenModal(true)
        } catch (e) {
            setLoading(false)
            console.log(`Error submitting form: ${e}`)
        }
    }
    return (
        <Box>
            {error && <ErrorMessage message={error} />}
        <Box mt={isMobile? '40px':'98px'} width={isMobile? '100%': '50%'} mx={'auto'} display='flex' justifyContent='center' alignItems='center'>
        <Box width={isMobile?'83%': '100%'}>
            <AddQuestionHeading>أسئلة مجانية مع محامين مرخصين!</AddQuestionHeading>
            <AddQuestionRequired mt={isMobile? '40px':'46px'}>كافة الحقول مطلوبة</AddQuestionRequired>
            <Box display='flex' justifyContent='space-between' alignItems='center' mt={isMobile? '18px':'61px'} maxWidth={isMobile ? '402px' : '960px'}>
                <AddQuestionLabel>السؤال</AddQuestionLabel>
                <AddQuestionCounter>{120 - question.length} حرف</AddQuestionCounter>
            </Box>
            <AddQuestionInput multiline rows={isMobile? 8 : 4} placeholder='اكتب سؤالك باختصار' value={question} onChange={(e) => setQuestion(e.target.value)} inputProps={{ maxLength: 120 }} />
            <Box display='flex' justifyContent='space-between' alignItems='center' mt='50px' maxWidth={isMobile ? '402px' : '960px'}>
                <AddQuestionLabel>تفاصيل اخرى</AddQuestionLabel>
                <AddQuestionCounter>{1000 - otherDetails.length} حرف</AddQuestionCounter>
            </Box>
            <AddQuestionOtherInput inputProps={{ maxLength: 1000 }} placeholder='اكتب تفاصيل السؤال' multiline rows={isMobile? 14 : 6 } value={otherDetails} onChange={(e) => setOtherDetails(e.target.value)} />
            <Box display='flex' justifyContent='right' alignItems={isMobile? 'right':'center'} gap='20px' mt={isMobile? '33px':'61px'} flexDirection={isMobile? 'column': 'row'}>
                <AddQuestionLabel>اختر تخصص السؤال</AddQuestionLabel>
                <RTLProvider>
                <FormControl sx={{ m: 0, minWidth: 120 }}>
                <CustomInputLabel >اختر التخصص</CustomInputLabel>
                    <SelectSpeciality
                        label="اختر التخصص"
                        value={speciality}
                        onChange={(e) => { setSpeciality(e.target.value); addSpeciality() }}
                    >
                                    {categories.map((name) => (
                                        <MenuItemCustom key={name} value={name}>
                                            {name}
                                        </MenuItemCustom>
                                    ))}
                    </SelectSpeciality>
                </FormControl>
                </RTLProvider>
            </Box>
            <Box display='flex' justifyContent='right' alignItems='center' gap='20px' mt={isMobile? '35px':'50px'} flexWrap='wrap'>
                {Array.isArray(questionSpecialities) ? questionSpecialities?.map((specialityText, index) => (
                    <SpecialityItem key={index} width={isMobile? 'fit-content !important': '100%'}>
                        {specialityText}
                        <img width='10px' alt="cross" src='./cross-brown.svg' style={{ cursor: 'pointer' }} onClick={() => removeSpeciality(specialityText)} />
                    </SpecialityItem>
                )) : {}}
            </Box>
            <Grid item mt={isMobile? '52px':'76px'}>
                <AddQuestionLabel>أود أن يتم اشعاري عند الإجابة؟</AddQuestionLabel>
            </Grid>
            <Grid container item mt={isMobile ? '43px' : "25px"}>
                <Grid sx={{ display: "flex", alignItems: 'center', gap: '6px' }} xs={12} sm={3} md={3} item >
                    <StyledRadio value="no"
                        name='visibleEmail'
                        checked={visibleEmail === false}
                        onChange={() => setVisibleEmail(false)} />
                    <ReviewModalRadioText>لا</ReviewModalRadioText>
                </Grid>
                <Grid sx={{ display: "flex", alignItems: 'center', gap: '6px' }} xs={12} sm={3} md={9} item mt={isMobile && '30px'}>
                    <StyledRadio value="yes"
                        name='visibleEmail'
                        checked={visibleEmail === true}
                        onChange={() => setVisibleEmail(true)} />
                    <ReviewModalRadioText>نعم , أريد أن يتم إشعاري على بريدي الالكتروني</ReviewModalRadioText>
                </Grid>
            </Grid>
            {visibleEmail && <Grid item mt="30px">
                <CustomPlainTextField CustomWidth={isMobile && 'calc(100vw - 64px)'} label="البريد الالكتروني" value={email} setValue={(e) => setEmail(e.target.value)} />
                        <AddQuestionEmailText mt="10px">
                             بالضغط على إرسال فإنك توافق علي <AddQuestionEmailTerms href="/terms-conditions">سياسة الخصوصية و شروط الاستخدام</AddQuestionEmailTerms>.
                        </AddQuestionEmailText>
                </Grid>}
            <Box mt={isMobile? '62px':'70px'} mb={isMobile? '158px':'200px'}>
                <CustomButton sx={isMobile? {maxWidth: '83vw !important'}:{}} CustomColor='#B4975A' CustomWidth={isMobile? '110%':'383px'} onClick={onAddQuestion} >
                    <ButtonText>أرسل سؤالك الآن</ButtonText>
                </CustomButton>
            </Box>
            <AddQuestionSuccess openModal={openModal} setOpenModal={setOpenModal} specialities={questionSpecialities} />
        </Box>
        </Box>
        <LoadingBackDrop loading={loading} />
        </Box>
    )
}

export default AddQuestionComponent