import { Box, useMediaQuery } from '@mui/material'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import { Heading } from 'components/Login/LoginStyles'
import SuccessMessage from 'components/SuccessMessage/SuccessMessage'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from 'Layout/Layout'
import FooterSection from 'Layout/Footer'
import { SubHeading } from 'components/Signup/SignupStyles'
import ConfirmEmailBox from 'components/ConfirmPassword/ConfirmPasswordBox'

const ConfirmPassword = () => {
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:768px)')
    const [isContentLoaded, setIsContentLoaded] = useState(false)
	
	const onContentLoaded = () => {
		setIsContentLoaded(true)
	}

    return (
        <Layout navbarClass={'popup'} isWhiteBackground={isMobile} isUserButton={false}>
            <Box minHeight={isMobile ? 'calc(100vh - 456px)' : 'calc(100vh - 160px)'} >
                {error && <ErrorMessage message={error} />}
                {success && <SuccessMessage message={success} />}
                {isMobile && (<Box display='flex' flexDirection='column'>
                    <Box mt='40px' ml='22px' display='flex' justifyContent='flex-end' >
                        <img onClick={() => navigate('/account')} src="cross.svg" alt="cross" />
                    </Box>
                    <Box mx='auto' mt='50px'>
                        <img alt='logo' src='auth-logo.svg' />
                    </Box>
                </Box>)}
                <Heading style={{ marginTop: (isMobile ? '30px' : '') }}>تأكيد طلب تغيير كلمة المرور</Heading>
                <SubHeading>التحقق من رقم الجوال</SubHeading>
                <ConfirmEmailBox setError={setError} setSuccess={setSuccess} onLoaded={onContentLoaded}/>
            </Box>
            {(isContentLoaded || isMobile) && <FooterSection />} 
        </Layout>
    )
}

export default ConfirmPassword