import BASE_URL from "./BaseURL"

export const getLawyerList = async fetchData => {
    try {
      const response = await fetch(`${BASE_URL}/lawyers-list`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(fetchData)
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }
  
export const getTopLawyerList = async (isTest = false) => {
  try {
    const response = await fetch(`${BASE_URL}/getTopLawyers?isTest=${isTest}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    const data = await response.json()
    if (data.error) {
      throw Error(data.message)
    }
    return data
  } catch (error) {
    throw Error(error.message) || error
  }
}
  
  export const getLawyer = async userId => {
    try {
      const response = await fetch(`${BASE_URL}/lawyer-profile/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }
  
  export const getLawyerReviews= async (userId, page) => {
    try {
      const response = await fetch(`${BASE_URL}/reviews/${userId}?page=${page}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }
  