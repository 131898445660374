import React from 'react'
import { useMediaQuery, Box } from '@mui/material'
import { BioSubTitle, BioText, BioTextLight } from '../LawyersProfile/LawyersProfileStyles'

const Education = ({ title, subtitle, year }) => {

  const isMobile = useMediaQuery('(max-width:768px)')
  return (
    <Box mt='27px'>
      <BioSubTitle>{title}</BioSubTitle>
      <BioText mt='10px'>{subtitle}</BioText>
      <BioTextLight mt='8px'>{year}</BioTextLight>
    </Box>
  )
}

export default Education