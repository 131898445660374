import FooterSection from 'Layout/Footer'
import Layout from 'Layout/Layout'
import ContactUsScreen from 'components/ContactUs/ContactUsScreen'
import React from 'react'

const ContactUs = () => {
    return (
        <Layout isWhiteBackground={true}>
            <ContactUsScreen />
            <FooterSection />
        </Layout>
    )
}   

export default ContactUs