import React from 'react'
import { Grid, useMediaQuery } from '@mui/material'
import { NavbarItem } from '../LawyersProfile/LawyersProfileStyles'

const LawyerNavbar = ({ selectedItem, onSelectItem }) => {
    const isMobile = useMediaQuery('(max-width:768px)')

    return (
        <Grid display='flex' gap={isMobile ? '0px' : '24px'} mt={isMobile ? '30px' : '32px'} mr={isMobile ? '39px' : '6px'} flexWrap='nowrap' sx={isMobile ? { overflowX: 'scroll', scrollbarWidth: 'none', '-ms-overflow-style': 'none' } : {}}
            css={{
                '&::-webkit-scrollbar': {
                    display: 'none'
                }
            }}>
            <NavbarItem onClick={() => onSelectItem(1)} style={selectedItem === 1 ? { background: '#F5F4F0', color: '#825925', flexShrink: 0 } : { flexShrink: 0 }}>عن المحامي</NavbarItem>
            <NavbarItem onClick={() => onSelectItem(2)} style={selectedItem === 2 ? { background: '#F5F4F0', color: '#825925', flexShrink: 0 } : { flexShrink: 0 }}>التخصصات</NavbarItem>
            <NavbarItem onClick={() => onSelectItem(3)} style={selectedItem === 3 ? { background: '#F5F4F0', color: '#825925', flexShrink: 0 } : { flexShrink: 0 }}>السيرة الذاتية</NavbarItem>
            <NavbarItem onClick={() => onSelectItem(4)} style={selectedItem === 4 ? { background: '#F5F4F0', color: '#825925', flexShrink: 0 } : { flexShrink: 0 }}>التقييم</NavbarItem>
            <NavbarItem onClick={() => onSelectItem(5)} style={selectedItem === 5 ? { background: '#F5F4F0', color: '#825925', flexShrink: 0 } : { flexShrink: 0 }}>التواصل</NavbarItem>
        </Grid>
    )
}

export default LawyerNavbar