import { CustomTextField } from 'components/Login/LoginStyles'
import RTLProvider from 'helpers/RTLProvider'
import React, { useState } from 'react'

const CustomPlainTextField = ({ label, value, setValue, CustomWidth }) => {
  const [isFocused, setIsFocused] = useState(false)
  return (
    <RTLProvider>
      <CustomTextField label={label} sx={{
        width: CustomWidth ? CustomWidth : '380px',
        '& .MuiInputLabel-root': {
          color: value?  "#1F2732": 'rgba(31, 39, 50, 0.50)',
          transform: value ? 'translate(10px, 3px)' : 'translate(10px, 13px)',
          height: value ?"16px": "50px",
          fontSize: value ?"12px": "18px",
          fontFamily: "Tajawal",
          fontWeight: 400,
          lineHeight: "normal",
          '&.Mui-focused': {
            color: "#1F2732",
            textAlign: "right",
            fontFamily: "Tajawal",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            transform: 'translate(10px, 3px)',
            height: '16px' 
          }
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'transparent',
          },
          '&:hover fieldset': {
            borderColor: 'transparent',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'transparent',
          },
        },
        '& .MuiInputBase-root input':{
          padding: '16.5px 14px 0',
          boxShadow: '200px 200px 100px white inset' 
        }
      }}
        value={value}
        onChange={setValue}
        isFocused ={isFocused}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </RTLProvider>
  )
}

export default CustomPlainTextField