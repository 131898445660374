import { Box, useMediaQuery } from '@mui/material'
import React from 'react'
import { ExtraPagesDetails, ExtraPagesHeader, ExtraPagesHeading, ExtraPagesTitle } from './AboutusStyles'

const PrivacyPolicyComponent = () => {
    const isMobile = useMediaQuery('(max-width:768px)')

    return (
        <Box>
            <ExtraPagesHeading CustomColor="#825925">
                <ExtraPagesHeader>سياسة الخصوصية</ExtraPagesHeader>
            </ExtraPagesHeading>
            <Box mr={isMobile ? '30px' : '60px'} mt="62px">
                <Box ml={isMobile ? '30px' : '78px'}>
                    <ExtraPagesDetails>
                        حماية بياناتك الشخصية هي أولويتنا. تهدف سياسة الخصوصية (يشار إليها فيما بعد بـ &quot;السياسة&quot;) إلى مساعدتك على فهم المزيد حول البيانات الشخصية التي نجمعها، ومع من قد نشاركها، وكيف نحميها. باستخدامك لموقع قانوني (يشار إليه فيما بعد بـ &quot;الموقع&quot;)، فإنك توافق على بنود السياسية الموضحة أدناه وكذلك على شروط الاستخدام الخاصة بنا. بالإضافة إلى ذلك، سنقوم بتحديث هذه السياسة عند الحاجة لمواكبة أي تغييرات في قوانين ولوائح المملكة العربية السعودية المتعلقة بحماية البيانات الشخصية، بما في ذلك: أولًا، نظام حماية البيانات الشخصية الصادر بالمرسوم الملكي رقم (م/19) وتعديلاته؛ ثانيًا، اللائحة التنفيذية لنظام حماية البيانات الشخصية؛ ثالثاً، لائحة نقل البيانات الشخصية خارج المملكة (يشار إليهم مجتمعين فيما بعد بـ &quot;نظام ولوائح حماية البيانات الشخصية&quot;).
                    </ExtraPagesDetails>
                    <ExtraPagesDetails mt="26px"><strong>البند الأول: ما هي البيانات الشخصية التي يتم جمعها؟</strong></ExtraPagesDetails>
                    <ExtraPagesDetails>
                        يقوم الموقع بجمع البيانات الشخصية التالية:
                    </ExtraPagesDetails>
                    <ol style={{listStyle: "auto", paddingRight: "30px"}}>
                        <li>بيانات الحساب: تشمل بيانات الدخول مثل أسماء المستخدمين وكلمات المرور، بالإضافة إلى بيانات التواصل مثل أرقام الهواتف، والبريد الإلكتروني، والعنوان الشخصي، والاسم كاملًا، ومحل الإقامة أو العمل، ورقم ترخيص مزاولة مهنة المحاماة، والشهادات الأكاديمية.</li>
                        <li>البيانات التقنية: تتضمن عنوان بروتوكول الإنترنت (IP) الخاص بك، والذي يستخدم لربط جهازك بالإنترنت.</li>
                    </ol>
                    <ExtraPagesDetails>وفقًا لنظام ولوائح حماية البيانات الشخصية في المملكة العربية السعودية، توجد فئة من البيانات تُعرف بالبيانات الشخصية الحساسة، والتي تشمل معلومات تتعلق بأصل الفرد العرقي أو الإثني، معتقداته الدينية أو الفكرية أو السياسية، البيانات الأمنية أو الجنائية، بيانات السمات الحيوية التي تحدد الهوية، البيانات الوراثية، البيانات الصحية، أو البيانات التي تشير إلى أن الفرد مجهول الأبوين أو أحدهما. لا يقوم الموقع بجمع أو معالجة أي من هذه البيانات الحساسة.</ExtraPagesDetails>
                    <ExtraPagesDetails mt="26px">
                        <strong>البند الثاني: الغرض من جمع البيانات الشخصية</strong>
                    </ExtraPagesDetails>
                    <ExtraPagesDetails>
                        يقوم الموقع بجمع واستخدام البيانات الشخصية لتحقيق الأغراض التالية:
                    </ExtraPagesDetails>
                    <ol style={{listStyle: "auto", paddingRight: "30px"}}>
                        <li>تقديم الاستشارات القانونية: معالجة طلباتك لاستشارة قانونية مجانية من محامين مرخصين. </li>
                        <li>البحث عن محامين: تقديم قاعدة بيانات شاملة للمحامين المرخصين لتسهيل البحث عن محامين بناءً على مجالات تخصصهم والمناطق الجغرافية.</li>
                        <li>تحسين الخدمات: جمع ملاحظات العملاء، ومراقبة وتحسين أداء الموقع والخدمات.</li>
                        <li>التواصل والتسويق: إعلامك بمنتجات أو خدمات جديدة أو تحديثات بخصوص الموقع.</li>
                        <li>إحصائيات: جمع إحصائيات حول استخدام الموقع لتحسين أدائه.  </li>
                    </ol>
                    <ExtraPagesDetails mt="26px">
                        <strong>البند الثالث: الأساس النظامي لجمع ومعالجة البيانات الشخصية</strong>
                    </ExtraPagesDetails>
                    <ExtraPagesDetails>
                        يتم جمع ومعالجة البيانات الشخصية بناءً على موافقة صاحب البيانات الشخصية. يمكن لصاحب البيانات الرجوع عن موافقته على جمع ومعالجة بياناته الشخصية في أي وقت، ما لم يكن هناك أساس نظامي آخر يبرر استمرار المعالجة. للرجوع في الموافقة، يمكن التواصل معنا عبر البريد الإلكتروني التالي: contact@qanooni.co
                    </ExtraPagesDetails>
                    <ExtraPagesDetails mt="26px">
                        <strong>البند الرابع: كيف يتم جمع البيانات الشخصية:</strong>
                    </ExtraPagesDetails>
                    <ExtraPagesDetails>
                    يقوم الموقع بجمع البيانات الشخصية بطرق مختلفة، سواء بشكل مباشر أو غير مباشر، من خلال:
                    </ExtraPagesDetails>
                    <ol style={{listStyle: "auto", paddingRight: "30px"}}>
                        <li>
                        <ExtraPagesDetails>
                            الطرق المباشرة:
                        </ExtraPagesDetails>
                        <ExtraPagesDetails>
                        الخدمات: عند تسجيلك لمعلوماتك للاستفادة من خدمات الموقع. 
                        </ExtraPagesDetails>
                        <ExtraPagesDetails>
                        التواصل: عند تواصلك معنا عبر قنوات خدمة العملاء.
                        </ExtraPagesDetails>
                        </li>
                        <li>
                            <ExtraPagesDetails>الطرق غير المباشرة:</ExtraPagesDetails>
                            <ExtraPagesDetails>
                            ملفات تعريف الارتباط (Cookies): يتم جمع البيانات من خلال ملفات تعريف الارتباط التي تُستخدم لتحسين تجربة المستخدم وتحليل استخدام الموقع. تُستخدم هذه الطرق لجمع المعلومات التي تساعدنا في تقديم وتحسين خدماتنا بشكل مستمر.
                            </ExtraPagesDetails>
                        </li>
                    </ol>
                    <ExtraPagesDetails mt="26px">
                        <strong>البند الخامس: حماية البيانات الشخصية</strong>
                    </ExtraPagesDetails>
                    <ExtraPagesDetails>
                        نحن ملتزمون بحماية بياناتك الشخصية باتخاذ إجراءات أمنية تقنية وتنظيمية مناسبة للحفاظ على سلامتها من الوصول غير المصرح به أو الاستخدام غير المشروع أو الفقدان أو التلف. نحن نتعامل مع المعلومات الشخصية بسرية تامة ونلتزم بأعلى معايير الأمان. كما نقوم بحذف البيانات الشخصية عند انتهاء الغرض من جمعها؛ وفقاً لنظام ولوائح حماية البيانات الشخصية.
                    </ExtraPagesDetails>
                    <ExtraPagesDetails mt="26px">
                        <strong>البند السادس: حقوق مستخدمي الموقع / أصحاب البيانات الشخصية</strong>
                    </ExtraPagesDetails>
                    <ExtraPagesDetails>
                        يتمتع مستخدمي الموقع / أصحاب البيانات الشخصية بالحقوق الآتية:
                    </ExtraPagesDetails>
                    <ol style={{listStyle: "auto", paddingRight: "30px"}}>
                        <li>الحق في العلم: معرفة طرق جمع بياناتك، والغرض من جمعها، والأساس النظامي لذلك.  </li>
                        <li>الحق في الحصول علي البيانات الشخصية: يحق لمستخدمي الموقع / أصحاب البيانات الشخصية طلب نسخة من البيانات الشخصية الخاصة بهم. </li>
                        <li>الحق في التصحيح: يمكنك طلب تصحيح البيانات الشخصية إذا كانت غير دقيقة أو غير صحيحة أو غير مكتملة.</li>
                        <li>الحق في الحذف: يمكن طلب حذف أو محو البيانات الشخصية الخاصة. </li>
                        <li>الحق في الرجوع عن الموافقة: يحق لمستخدمي الموقع / أصحاب البيانات الشخصية الرجوع عن الموافقة على جمع ومعالجة بياناتهم الشخصية في أي وقت، ما لم تكن هناك ما لم يكن هناك أساس نظامي آخر يبرر استمرار الجمع و المعالجة. </li>
                    </ol>
                    <ExtraPagesDetails mt="26px">
                        <strong>البند السابع: التغييرات في سياسة الخصوصية</strong>
                    </ExtraPagesDetails>
                    <ExtraPagesDetails>
                    قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر لتعكس التغييرات في ممارساتنا أو لأسباب تشغيلية أو قانونية أو تنظيمية أخرى. سيتم نشر تعديلات السياسة على هذا الموقع، وينصح بمراجعتها بانتظام.
                    </ExtraPagesDetails>
                    <ExtraPagesDetails mt="26px">
                        <strong>البند الثامن: تواصل معنا</strong>
                    </ExtraPagesDetails>
                    <ExtraPagesDetails  mb={isMobile ? '50px' : '200px'}>
                        إذا كانت لديك أي أسئلة أو مخاوف بشأن سياسة الخصوصية هذه، أو حول كيفية تعاملنا مع بياناتك الشخصية، يرجى التواصل معنا عبر البريد الإلكتروني التالي: contact@qanooni.co
                    </ExtraPagesDetails>
                </Box>
            </Box>
        </Box>
    )
}

export default PrivacyPolicyComponent
