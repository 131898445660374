import React from 'react'
import { ErrorMessageText, ErrorWrapper } from './ErrorMessageStyles'

const ErrorMessage = ({ message }) => {
    return (
        <ErrorWrapper>
            <ErrorMessageText>
                {message}
            </ErrorMessageText>
        </ErrorWrapper>
    )
}

export default ErrorMessage