import { Box, Grid, useMediaQuery } from '@mui/material'
import { ButtonText, Container, CustomButton } from 'components/Login/LoginStyles'
import React, { useEffect, useState } from 'react'
import OTPField from 'components/OTP/OTPField'
import { OTPPhoneNumber, OTPResend, OTPText, TimerText } from 'components/OTP/OTPStyles'
import { formatTime } from 'helpers/formatTime'
import { resendOTP, verifyOTP } from 'api/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import LoadingBackDrop from 'components/Loading/LoadingBackDrop'
import { maskPhoneNumber } from 'helpers/maskPhone'
import { useAuth } from 'context/auth'
import { changePassword } from 'api/profile'

const ConfirmPasswordBox = ({ setError, route, onLoaded }) => {
  const { user } = useAuth()
  const [otp, setOtp] = useState('')
  const [timer, setTimer] = useState(300)
  const [loading, setLoading] = useState(false)
  const [mobileNumber, setMobileNumber] = useState('')
  const location = useLocation()
  const oldPassword = location.state?.oldPassword || null
  const newPassword = location.state?.newPassword || null
  const navigate = useNavigate()
  const gridStyles = { display: 'flex', justifyContent: 'center', alignItems: 'center' }
  const isMobile = useMediaQuery('(max-width:768px)')

  useEffect(() => {
    if (timer > 0) {
      const timeout = setTimeout(() => {
        setTimer(timer - 1)
      }, 1000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [timer])

  useEffect(() => {
    setMobileNumber(maskPhoneNumber("+966" + location.state.mobile_number))
  }, [])

  const validateFields = () => {
    if (!otp) {
      return 'رمز التحقق مطلوب'
    }
    return null
  }

  const onVerifyOTP = async () => {
    setLoading(true)
    const errorMessage = validateFields()
    if (errorMessage) {
      setError(errorMessage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      setLoading(false)
      return
    }
    try {
      await verifyOTP({ otp, mobile_number: location.state.mobile_number })
      // OTP verification successful, now try to change the password
      try {
        await changePassword({ oldPassword, newPassword })
        navigate('../account', { state: { successMessage: 'تم تغيير كلمة المرور' } })
      } catch (error) {
        // Handle errors related to changePassword
        navigate('../change-password', { state: { errorMessage: error.message } })
      }
    } catch (error) {
      // Handle errors related to verifyOTP
      setError(error.message)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    } finally {
      // This will execute after either set of try/catch blocks complete
      setLoading(false)
    }
  }

  const onResendOTP = async () => {
    setLoading(true)
    try {
      await resendOTP({ mobile_number: location.state.mobile_number })
      setTimer(300)
      setLoading(false)
    } catch (error) {
      setError(error.message)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      setLoading(false)
    }
  }
  return (
    <Box position='relative' maxWidth="790px" maxHeight="100" mx='auto' zIndex={10}>
      <Container sx={{ zIndex: '10 !important', position: 'relative' }} marginTop="40px" customHeight="600px">
        <Grid container justifyContent='center' alignItems='center' paddingX={isMobile ? '32px' : 0}>
          <Grid item xs={12} md={12} mt='80px' sx={gridStyles}>
            <OTPText>:لقد تم إرسال رمز التحقق رقم الجوال الذي أضفته</OTPText>
          </Grid>
          <Grid item xs={12} md={12} mt='10px' sx={gridStyles}>
            <OTPPhoneNumber>{mobileNumber}</OTPPhoneNumber>
          </Grid>
          <Grid item xs={12} md={12} mt='52px' sx={gridStyles}>
            <Box sx={{ direction: 'ltr' }}>
              <OTPField value={otp} onChange={setOtp} isMobile={isMobile} />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} mt='32px' sx={gridStyles} style={{ justifyContent: 'right' }} mr={!isMobile ? 25 : 0} gap={2} width='380px'>
            <OTPResend timeout={timer ? false : true} onClick={onResendOTP}>أرسل الرمز مرة أخرى</OTPResend>
            <TimerText >{formatTime(timer)}</TimerText>
          </Grid>
          <Grid item xs={12} md={12} mt='66px' mb='93px' sx={gridStyles}>
            <CustomButton onClick={onVerifyOTP} CustomColor='#B4975A' CustomWidth={isMobile ? '320px' : '380px'}>
              <ButtonText>تأكيد رمز التحقق</ButtonText>
            </CustomButton>
          </Grid>
        </Grid>
      </Container>
      {!useMediaQuery('(max-width:990px)') && <Box overflow={'overlay'} mt={-12} mr={-30}>
        <img src='login-background.svg' alt='emailOTP' onLoad={onLoaded} />
      </Box>}
      <LoadingBackDrop loading={loading} />
    </Box>
  )
}

export default ConfirmPasswordBox