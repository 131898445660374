import BASE_URL from "./BaseURL"

export const changeEmail = async emailData => {
    const token = localStorage.getItem('authToken')
    try {
        const response = await fetch(`${BASE_URL}/change-email/${emailData.id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            body: JSON.stringify({ email: emailData.email })
        })
        const data = await response.json()
        if (data.error) {
            throw Error(data.message)
        }
        return data
    } catch (error) {
        throw Error(error.message) || error
    }
}

export const updateUserInfo = async user => {
    const token = localStorage.getItem("authToken")
    try {
        const response = await fetch(`${BASE_URL}/users/${user.id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            body: JSON.stringify(user.data)
        })
        if (response.error) {
            throw Error(response.message)
        }
        if (response.status !== 200){
            throw Error("حدث خطأ أثناء تحديث معلومات المستخدم")
        }
        return response
    } catch (error) {
        throw Error(error.message) || error
    }
}
        
export const uploadImage = async imageData => {
  const token = localStorage.getItem('authToken')
    try {
        const response = await fetch(`${BASE_URL}/profile-image/${imageData.id}`, {
            method: 'POST',
            headers: {
              'token': token
            },
            body: imageData.formData
        })
        const data = await response.json()
        if (data.error) {
            throw Error(data.message)
        }
        if (response.status !== 200){
            throw Error("حدث خطأ أثناء تحميل الصورة")
        }
        return data
    } catch (error) {
        throw Error(error.message) || error
    }
}

export const getUserMobile = async (user) => {
    const token = localStorage.getItem('authToken')
    try {
        const response = await fetch(`${BASE_URL}/users/${user.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
        })
        const data = await response.json()
        const mobileNumber = data.mobile_number
        if (mobileNumber.error) {
            throw Error(mobileNumber.message)
        }
        return mobileNumber
    } catch (error) {
        throw Error(error.message) || error
    }
}

export const deleteAccount = async user => {
    const token = localStorage.getItem('authToken')
    try {
        const response = await fetch(`${BASE_URL}/users/${user.id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },

        })
        if (response.error) {
            throw Error(response.message)
        }
        return response
    } catch (error) {
        throw Error(error.message) || error
    }
}

export const changePassword = async passwordData => {
    const token = localStorage.getItem('authToken')
    try {
        const response = await fetch(`${BASE_URL}/change-password`, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            body: JSON.stringify(passwordData)
        })
        const data = await response.json()
        if (data.error) {
            throw Error(data.message)
        }
        return data
    } catch (error) {
        throw Error(error.message) || error
    }
}

export const getCurrentUserData = async user => {
    const token = localStorage.getItem("authToken")
    try {
        const response = await fetch(`${BASE_URL}/users/${user.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
        })
        if (response.error) {
            throw Error(response.message)
        }
        return response
    } catch (error) {
        throw Error(error.message) || error
    }
}
export const deleteImage = async user =>{
    const token = localStorage.getItem('authToken')
    try {
        const response = await fetch(`${BASE_URL}/profile-image/${user.id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'token': token
          },
        })
        const data = await response.json()
        if (data.error) {
          throw Error(data.message)
        }
        if (response.status !== 200){
            throw Error("حدث خطأ أثناء حذف الصورة")
        }
        return data
      } catch (error) {
        throw Error(error.message) || error
      }
}

