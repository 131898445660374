import React from 'react'
import { useMediaQuery } from '@mui/material'
import { SpecializationBox, SpecializationText } from '../LawyersProfile/LawyersProfileStyles'
import { useNavigate } from 'react-router-dom'

const SpecializationItem = ({ text, handleClick }) => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width:768px)')
  
  return (
    <SpecializationBox onClick={() => handleClick(text)}>
      <SpecializationText>{text}</SpecializationText>
    </SpecializationBox>
  )
}

export default SpecializationItem