import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'

const LoadingBackDrop = ({loading}) => {
  return (
    <Backdrop
    sx={{ color: '#B4975ACC', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={loading}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
  )
}

export default LoadingBackDrop