import { Box, useMediaQuery } from '@mui/material'
import FooterSection from 'Layout/Footer'
import Layout from 'Layout/Layout'
import EditAccountSection from 'components/EditAccount/EditAccountScreen'
import React from 'react'

const EditAccount = () => {
    const isMobile = useMediaQuery('(max-width:768px)')
    return (
        <Layout isWhiteBackground={true}>
            <EditAccountSection />
            <FooterSection />
        </Layout>
    )
}   

export default EditAccount