import React, { use, useEffect, useState } from 'react'
import {
  Box,
  Button,
  ThemeProvider,
  createTheme,
  useMediaQuery,
  MenuItem,
  FormControl,
  Grid
} from '@mui/material'
import FooterSection from 'Layout/Footer'
import Layout from 'Layout/Layout'
import QuestionsListSection from 'components/QuestionsList/QuestionsListScreen'

const QuestionsList = () => {
  const isMobile = useMediaQuery('(max-width:768px)')

  return (

    <Layout isWhiteBackground>
      <QuestionsListSection />
      <FooterSection />
    </Layout>

  )
}

export default QuestionsList