import styled from "@emotion/styled"
import { Typography } from "@mui/material"

export const SubHeading = styled(Typography)`
color: var(--Dark, #1F2732);
text-align: center;
font-family: Tajawal;
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: normal;
@media (max-width: 768px) {
    color: var(--Dark, #1F2732);
    text-align: center;
    font-family: Tajawal;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`

export const SignupHeader = styled(Typography)`
color: #3A3A3A;
text-align: right;
font-family: Tajawal;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
width: 380px;
`

export const SignupPhoneRule = styled.ul`
color: #3A3A3A;
width: 380px;
padding-bottom: 0px; 
margin-bottom: 0px; 
text-align: right;
font-family: Tajawal;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 160% */
@media (max-width: 768px) {
    font-size: 15px;
  }
`

export const TermsAndConditions = styled.p`
color: #3A3A3A;
display: inline;
text-align: center;
font-family: Tajawal;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
margin-bottom: 0px;
`

export const TermsAndConditionsLink = styled.p`
color:  rgb(130, 89, 37, 1);
font-family: Tajawal;
display: inline;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
cursor: pointer;
margin-bottom: 0px;
`