import { Box, useMediaQuery } from '@mui/material'
import FooterSection from 'Layout/Footer'
import Layout from 'Layout/Layout'
import AccountSection from 'components/Account/AccountScreen'
import React, { useState, useEffect } from 'react'
import SuccessMessage from 'components/SuccessMessage/SuccessMessage'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import { useLocation, useNavigate } from 'react-router-dom' 

const Account = () => {
    const isMobile = useMediaQuery('(max-width:768px)')
    const location = useLocation() 
    const navigate = useNavigate()
    const successMessage = location.state?.successMessage || null 
    const emailUpdated = location.state?.emailUpdated || null 
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    useEffect(() => {
        if (successMessage ) {
            // Redirect to the same page but without the state
            navigate(location.pathname, { state: { successMessage: null, emailUpdated } })
        }
    }, [successMessage])

    return (
        <Layout isWhiteBackground={true}>
            {error && <ErrorMessage message={error} />}
            {success && <SuccessMessage message={success} />}
            <AccountSection setError={setError} setSuccess={setSuccess} successMessage={successMessage} emailUpdated={emailUpdated} />
            <FooterSection />
        </Layout>
    )
}

export default Account