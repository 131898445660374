import { Box, Grid, useMediaQuery } from '@mui/material'
import CustomPlainTextField from 'components/FormComponents/CustomPlainTextField'
import PasswordTextField from 'components/FormComponents/PasswordTextField'
import { ButtonText, Container, CustomButton, CustomTextField } from 'components/Login/LoginStyles'
import React, { useState } from 'react'
import { BottomText } from './ChangeEmailStyles'
import LoadingBackDrop from 'components/Loading/LoadingBackDrop'
import { useLocation, useNavigate } from 'react-router-dom'
import { changeEmail } from 'api/profile'
import { useAuth } from 'context/auth'

const ChangeEmailScreen = ({ setError, setSuccess, onLoaded }) => {
    const { user } = useAuth()
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [newEmail, setNewEmail] = useState('')

    const navigate = useNavigate()
    const location = useLocation()
    const gridStyles = { display: 'flex', justifyContent: 'center', alignItems: 'center' }
    const isMobile = useMediaQuery('(max-width:768px)')

    const onEmailChange = async () => {
        if (newEmail === '') {
            setError('البريد الالكتروني مطلوب')
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
              })
            return false
        }
        else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(newEmail)) {
            setError('صيغة البريد الالكتروني غير صحيحة')
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
              })
            return false
        }
        if (password === '') {
            setError('كلمة المرور غير متطابقة')
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
              })
            return false
        }
        setLoading(true)
        try {
            await changeEmail({ id: user.id, email: newEmail })
            let userData = await JSON.parse(localStorage.getItem('userData'))
            userData.email = newEmail
            const updatedUserDataString = JSON.stringify(userData)
            localStorage.setItem('userData', updatedUserDataString)
            navigate('../account', { state: { successMessage: 'تم تعديل البريد الالكتروني', emailUpdated: newEmail } }) 
            setLoading(false)
        } catch (error) {
            setError(error.message)
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
              })
            setLoading(false)
        }
    }

    return (
        <Box maxWidth="765px" maxHeight="100" mx='auto' zIndex={10} mb={'100px'}>
            <Container sx={{ zIndex: '10 !important', height: 'fit-content!important', position: 'relative', marginBottom: '200px' }}>
                <Grid container justifyContent='center' alignItems='center' paddingX={isMobile ? '32px' : 0}>
                    <Grid item xs={12} md={12} justifyContent='center' marginTop='100px' sx={gridStyles}>
                        <CustomPlainTextField value={newEmail} setValue={(e) => setNewEmail(e.target.value)} label='البريد الالكتروني الجديد' CustomWidth={isMobile ? '300px' : '380px'} />
                    </Grid>
                    <Grid item xs={12} md={12} mt='40px' sx={gridStyles}>
                        <PasswordTextField label='تأكيد كلمة المرور' value={password} setValue={(e) => setPassword(e.target.value)} CustomWidth={isMobile ? '300px' : '380px'} />
                    </Grid>
                    <Grid item xs={12} md={12} mt='48px' mb={isMobile ? '77px' : '40px'} sx={gridStyles}>
                        <CustomButton onClick={onEmailChange} CustomColor='#825925' CustomWidth={isMobile ? '300px' : '380px'}>
                            <ButtonText>الاستمرار</ButtonText>
                            <img src='login-arrow.svg' alt="arrow" />
                        </CustomButton>
                    </Grid>
                    <Grid xs={12} md={12} mb={isMobile ? '31px' : '178px'} sx={gridStyles}>
                        <BottomText > يتطلب تغيير البريد الالكتروني إلى تأكيد كلمة مرور الحساب ومن ثم تأكيد البريد الالكتروني الجديد. </BottomText>
                    </Grid>
                </Grid>
            </Container>
            {!useMediaQuery('(max-width:990px)') && <Box overflow={'overlay'} mt={-12} mr={-30}>
                <img src='login-background.svg' alt='login' onLoad={onLoaded} />
            </Box>}
            <LoadingBackDrop loading={loading} />
        </Box>
    )
}

export default ChangeEmailScreen