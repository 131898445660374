/* eslint-disable no-undef */
import React, { useState } from 'react'
import { AnswerDate, AnswerEditButton, AnswerText, AnswerTextField, AnswerUseful, AnswerUsefulCount, AnswerWrapper, WriteAnswer } from './AnswerStyles'
import AnswerUser from './AnswerUser'
import { Box, useMediaQuery } from '@mui/material'
import { ButtonText, CustomButton } from 'components/Login/LoginStyles'
import AddAnswer from './AddAnswer'
import EditAnswer from './EditAnswer'
import { useAuth } from 'context/auth'
import { dislikeAnswer, editAnswer, likeAnswer, removeDislike, removeLike } from 'api/answers'
import LoadingBackDrop from 'components/Loading/LoadingBackDrop'
import { formatDateTime } from 'helpers/formatTime'
import { rules, validateFields } from 'helpers/validation'

const AnswerBox = ({ answer, answerAdd, setError }) => {
  const [enableEdit, setEnableEdit] = useState(false)
  const [editField, setEditField] = useState('')
  const [loading, setLoading] = useState(false)
  const isMobile = useMediaQuery('(max-width:768px)')
  const likedAnswers = JSON.parse(localStorage.getItem('likedAnswers')) || []
  const dislikedAnswers = JSON.parse(localStorage.getItem('dislikedAnswers')) || []
  const fields = { editField }
  const validationRules = {
    editField: [
        rules.required('الاجابة', 'الاجابة مطلوبة')
    ]
}
  const toggleEdit = () => {
    setEnableEdit(true)
    setEditField(answer?.answer)
  }

  const onEditAnswer = async () => {
    try {
      setLoading(true)
      const errorMessage = validateFields(fields, validationRules)
        if (errorMessage) {
            setError(errorMessage)
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
            setLoading(false)
            return
        }
        await editAnswer({answer: editField}, answer?.answer_id)
        answer.answer = editField
        setEnableEdit(false)
        setLoading(false)
    } catch (e) {
      setLoading(false)
        console.log(`Error submitting form: ${e}`)
    }
}

const onLikeAnswer = async () => {
  try {
    setLoading(true)
    if (!likedAnswers.includes(answer?.answer_id)) {
        await likeAnswer(answer?.answer_id)
        answer.likes = answer.likes + 1
        likedAnswers.push(answer?.answer_id)
        localStorage.setItem('likedAnswers', JSON.stringify(likedAnswers))
      } else {
        await removeLike(answer?.answer_id)
        answer.likes = answer.likes - 1
        likedAnswers.pop(answer?.answer_id)
        localStorage.setItem('likedAnswers', JSON.stringify(likedAnswers))
      }
      setLoading(false)
  } catch (e) {
    setLoading(false)
      console.log(`Error submitting form: ${e}`)
  }
}

const onDislikeAnswer = async () => {
  try {
      setLoading(true)
      if (!dislikedAnswers.includes(answer?.answer_id)) {
        await dislikeAnswer(answer?.answer_id)
        answer.dislikes = answer.dislikes + 1
        dislikedAnswers.push(answer?.answer_id)
        localStorage.setItem('dislikedAnswers', JSON.stringify(dislikedAnswers))
      } else {
        await removeDislike(answer?.answer_id)
        answer.dislikes = answer.dislikes - 1
        dislikedAnswers.pop(answer?.answer_id)
        localStorage.setItem('dislikedAnswers', JSON.stringify(dislikedAnswers))
      }
      setLoading(false)
  } catch (e) {
    setLoading(false)
      console.log(`Error submitting form: ${e}`)
  }
}
  const { user } = useAuth()
  return (
    <AnswerWrapper mr={isMobile? '0px':'60px'}>
      <Box pt='40px' pr={isMobile? '30px':'38px'} mb='20px'>
        <AnswerUser answer={answer} />
        {!enableEdit && !answerAdd && <><AnswerDate mt='47px'>نُشر في {formatDateTime(answer?.created_at)}</AnswerDate>
          <AnswerText ml='38px' mt='39px'>
            {answer.answer.split('\n').map((paragraph, index) => (
              <React.Fragment key={index}>
                {paragraph}
                <br />
              </React.Fragment>
            ))}</AnswerText>
        <Box display={isMobile? 'block':'flex'} justifyContent='space-between' width='100%' mt='140px' pb='61px'>
          <Box display='flex' gap={isMobile?'23px':'50px'} flexDirection={isMobile? 'column': 'row'} alignItems={isMobile? 'right':'center'}>
            <AnswerUseful mt='5px'>هل وجدت الإجابة مفيدة ؟</AnswerUseful>
            <Box display='flex' gap={isMobile? '66px':'63px'}>
            <Box display='flex' alignItems='center' gap='12px'>
              <img alt='like'  src={process.env.PUBLIC_URL + (likedAnswers.includes(answer?.answer_id) ? '/like-filled.svg' :'/like.svg')} width='21px' height='20px' style={{cursor: 'pointer'}} onClick={onLikeAnswer} />
              <AnswerUsefulCount mt='5px'>({answer?.likes?? 0})</AnswerUsefulCount>
            </Box>
            <Box display='flex' alignItems='center' mt='5px' gap='12px'>
              <img alt='like' src={process.env.PUBLIC_URL + (dislikedAnswers.includes(answer?.answer_id) ? '/dislike-filled.svg' :'/dislike.svg')} width='21px' height='20px' style={{cursor: 'pointer'}} onClick={onDislikeAnswer} />
              <AnswerUsefulCount>({answer?.dislikes?? 0})</AnswerUsefulCount>
            </Box>
            </Box>
          </Box>
          {answer?.user_id === user?.id && <Box display='flex' gap='20px' ml='42px' mt={isMobile?'50px': '0px'} sx={{ cursor: 'pointer' }} onClick={toggleEdit}>
            <img alt='edit answer' src={`${process.env.PUBLIC_URL}/edit-answer.svg`} width='18px' height='18px' />
            <AnswerEditButton>تعديل الاجابة</AnswerEditButton>
          </Box>}
        </Box></>}
        {answerAdd && <AddAnswer />}
        {enableEdit && <EditAnswer editField={editField} setEditField={setEditField} answer={answer} onSubmit={onEditAnswer} />}
      </Box>
      <LoadingBackDrop loading={loading}/>
    </AnswerWrapper>
  )
}

export default AnswerBox