import styled from "@emotion/styled"
import { MenuItem } from "@mui/material"

export const DropdownItem = styled(MenuItem)`
font-family: Tajawal;
font-size: 18px;
font-weight: 500;
line-height: 22px;
letter-spacing: 0em;
text-align: right;
padding-right: 60px;
height: 56px;
@media (max-width: 768px) {
   
  }
  &:hover{
    background-color: #F5F4F0;  
  }
`
