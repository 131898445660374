import React, { use, useEffect, useState } from 'react'
import { Box, ThemeProvider, createTheme, useMediaQuery, FormControl, Grid, Autocomplete, TextField } from '@mui/material'
import FooterSection from 'Layout/Footer'
import Layout from 'Layout/Layout'
import { LawyerCard } from 'components/LawyersListing/LawyerCard'
import {
  PaginationText,
  TitleText,
  SelectFilter,
  FilterPopup,
  FilterApplyButton,
  TitleGridPopup, 
  PopupTitle,
  AutocompleteFilter
} from 'components/LawyersListing/LawyersListingStyle'
import { DeletePopupWrapper } from '../components/Account/AccountStyles'
import { CustomInputLabel, MenuItemCustom} from '../components/EditAccount/EditAccountStyles'
import RTLProvider from 'helpers/RTLProvider'
import LawyerSearch from 'components/LawyersListing/LawyerSearch'
import { getLawyerList } from 'api/LawyerGuide'
import LoadingBackDrop from 'components/Loading/LoadingBackDrop'
import { categories } from 'constants/user'
import { FirstButton, NextButton, PreviousButton } from 'components/QuestionsList/QuestionsListStyles'
import { getAllCities, getCitiesPerProvince, getProvinces } from 'api/provinces'
import { useSearchParams } from 'react-router-dom'

const guideTheme = createTheme({
  typography: {
    fontFamily: 'Tajawal, Arial',
  },
})

export const LawyersGuide = () => {
  const [lawyersList, setLawyersList] = useState([])
  const [searchArea, setSearchArea] = useState(sessionStorage.getItem('searchArea')?? '')
  const [searchCity, setSearchCity] = useState(sessionStorage.getItem('searchCity')?? '')
  const [searchLanguage, setSearchLanguage] = useState(sessionStorage.getItem('searchLanguage')?? '')
  const [searchSpeciality, setSearchSpeciality] = useState(sessionStorage.getItem('searchSpeciality')?? '')
  const [filterChanged, setFilterChanged] = useState(false)
  const [pageLimit, setPageLimit] = useState(8)
  const [totalLawyers, setTotalLawyers] = useState(0)
  const [searchValue, setSearchValue] = useState(sessionStorage.getItem('searchValueLawyersGuide') || "")
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [noOfFilters, setnoOfFilters] = useState(0)
  const [anchor, setAnchor] = useState(null)
  const [cities, setCities] = useState([])
  const [provinces, setProvinces] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const currentPage = parseInt(searchParams.get('page')) || 1
  const isMobile = useMediaQuery('(max-width:900px)')
  const isTest = searchParams.get('isTest') === 'true'
  const open = Boolean(anchor)
  const id = open ? 'simple-popup' : undefined
  const deletePopupOpen = async (event) => {
    event.stopPropagation() 
    setAnchor(anchor ? null : event.currentTarget)
    document.body.style.setProperty('overflow', (anchor ? 'unset' : 'hidden'))
  }
  const handleClose = () => {
    setAnchor(null)
  }

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [open])
  
  useEffect(() => {
    const fetchLawyersList = async () => {
      try {
        setLoading(true)
        const params = {
          "pageSize": 9999,
          isTest
        }

        if (searchValue !== '') {
          params.lawyersName = searchValue
        }
        if (searchCity !== '') {
          params.city = searchCity
        }
        if (searchLanguage !== '') {
          params.language = searchLanguage
        }
        if (searchSpeciality !== '') {
          params.specialization = searchSpeciality
        }
        if (searchArea !== '') {
          params.area = searchArea
        }

        const list = await getLawyerList(params)

        setTotalLawyers(list.count)
        setLoading(false)
      } catch (err) {
        setError(err.message)
        setLoading(false)
      }
    }

    fetchLawyersList()

  }, [searchValue, filterChanged])

  const fetchProvinces = async () => {
    try {
      setLoading(true)
      const list = await getProvinces()
      setProvinces(list.result)
      setLoading(false)
    } catch (err) {
      setError(err.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchProvinces()
  }, [])

  const fetchCities = async () => {
    try {
      setLoading(true)
      const list = await getCitiesPerProvince(searchArea)
      setCities(list.result)
      setLoading(false)
    } catch (err) {
      setError(err.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if(searchArea){
      fetchCities()
    }
  }, [searchArea])

  useEffect(() => {
    const fetchLawyersList = async () => {
      try {
        setLoading(true)
        const params = {
          "pageIndex": currentPage,
          "pageSize": pageLimit,
          isTest
        }

        if (searchValue !== '') {
          params.lawyersName = searchValue
        }
        if (searchCity !== '') {
          params.city = searchCity
        }
        if (searchLanguage !== '') {
          params.language = searchLanguage
        }
        if (searchSpeciality !== '') {
          params.specialization = searchSpeciality
        }
        if (searchArea !== '') {
          params.area = searchArea
        }
        const numFiltersUsed = Object.values(params).filter(value => value !== undefined).length - 2
        setnoOfFilters(numFiltersUsed)

        const list = await getLawyerList(params)
        setLawyersList(list)
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        setLoading(false)

      } catch (err) {
        setError(err.message)
        setLoading(false)
      }
    }

    fetchLawyersList()
  }, [currentPage, pageLimit, searchValue, filterChanged])

  const applyFilters = async () => {
    setFilterChanged(prev => !prev)
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  const handleFirstPage = () => {
    setSearchParams({ page: 1 })
  }

  const handlePreviousPage = () => {
    const newPage = Math.max(1, currentPage - 1)
    setSearchParams({ page: newPage })
  }

  const handleNextPage = () => {
    const newPage = currentPage + 1
    setSearchParams({ page: newPage })
  }

  const handleSearch = async (value) => {
    setSearchValue(value)
    setSearchParams({ page: 1 })
  }

  const clearFilter = async () => {
    setSearchArea('')
    setSearchCity('')
    setSearchLanguage('')
    setSearchSpeciality('')
    setSearchValue('')
    sessionStorage.setItem('searchArea', '')
    sessionStorage.setItem('searchCity', '')
    sessionStorage.setItem('searchLanguage', '')
    sessionStorage.setItem('searchSpeciality', '')
    sessionStorage.setItem('searchValueLawyersGuide', '')
    setCities([])
    setnoOfFilters(0)
    applyFilters()
  }

  let startIndex = (currentPage - 1) * pageLimit + 1
  if (totalLawyers == 0){
    startIndex = 0
  }
  const endIndex = Math.min(startIndex + pageLimit - 1, totalLawyers)
  const isLastPage = currentPage * pageLimit >= totalLawyers

  const handleAreaChange = (event, newValue) => {
    if (newValue) {
      const selectedValue = newValue.province_id
      sessionStorage.setItem('searchArea', selectedValue)
      setSearchArea(selectedValue)
    } else {
      sessionStorage.removeItem('searchArea')
      setSearchArea('')
    }
  }

  return (
    <ThemeProvider theme={guideTheme}>
      <Box sx={{ bgcolor: 'white' }}>
        <Layout isWhiteBackground>
          <Box
            sx={{
              mx: { lg: '60px', xs: '20px' },
            }}
            minHeight={isMobile ? 'calc(100vh - 654px)' : 'calc(100vh - 258px)'}
          >
            <TitleText>دَليل المُحامين</TitleText>
            <LawyerSearch
              onSearch={handleSearch}
              onFilterClick={deletePopupOpen}
              onFilterClearClick={clearFilter}
              isFilterClearVisible={searchArea || searchSpeciality || searchLanguage || searchCity || searchValue}
              noOfFilters={noOfFilters}
            />
            <PaginationText>
              عرض {startIndex} - {endIndex} من {totalLawyers} محامي مرخص
            </PaginationText>
            {lawyersList?.result?.map((lawyer, index) => (
              <LawyerCard key={index} lawyer={lawyer} />
            ))}
            <Box
              sx={{
                marginTop: '50px',
                marginBottom: '222px',
              }}
            >
              {currentPage !== 1 && currentPage !== 2 && <FirstButton disabled={currentPage === 1} onClick={handleFirstPage}>الأولى</FirstButton>}
              {currentPage !== 1 && <PreviousButton disabled={currentPage === 1} onClick={handlePreviousPage}>السابق</PreviousButton>}
              <NextButton disabled={isLastPage} onClick={handleNextPage}>التالى</NextButton>
            </Box>
          </Box>
          <FooterSection />
        </Layout>
      </Box>
      <DeletePopupWrapper id={id} open={open} anchor={anchor} onClick={handleClose}>
        <FilterPopup onClick={(e) => e.stopPropagation()}>
          <TitleGridPopup>
            <PopupTitle>تصفية</PopupTitle>
            <img onClick={deletePopupOpen} src="cross.svg" alt="cross" width={18} style={isMobile ? { order: '-1' } : { cursor: 'pointer' }} />
          </TitleGridPopup>
          <RTLProvider>
            <Grid display='grid' width='100%' justifyContent='center' mt={isMobile ? '36px' : '110px'}>
              <FormControl sx={{ m: 0, minWidth: 120 }}>
                <AutocompleteFilter
                  value={provinces.find(p => p.province_id === searchArea) || null}
                  onChange={handleAreaChange}
                  options={provinces}
                  getOptionLabel={(option) => option.province_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="المنطقة الادارية"
                      InputLabelProps={{
                        sx: {
                          color: '#a0a0a0', // Gray color for the label
                          transform: searchArea ? 'translate(10px, 0px)' : 'translate(10px, 13px)',
                          fontSize: searchArea ? "12px" : "18px",
                          '&.Mui-focused': {
                            color: '#a0a0a0', // Keep the label gray when focused
                            transform: 'translate(10px, 0px)',
                            fontSize: "12px"
                          }
                        }
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <MenuItemCustom value={option.province_id} key={option.province_id}>
                        {option.province_name}
                      </MenuItemCustom>
                    </li>
                  )}
                />
              </FormControl>
              <FormControl sx={{ m: 0, minWidth: 120 }}>
                <AutocompleteFilter
                  value={cities.find(c => c.city === searchCity) || null}
                  onChange={(event, newValue) => {
                    const cityValue = newValue ? newValue.city : ""
                    sessionStorage.setItem('searchCity', cityValue)
                    setSearchCity(cityValue)
                  }}
                  options={cities}
                  getOptionLabel={(option) => option.city}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="المدينة"
                      InputLabelProps={{
                        sx: {
                          color: '#a0a0a0',
                          transform: searchCity ? 'translate(10px, 0px)' : 'translate(10px, 13px)',
                          fontSize: searchCity ? "12px" : "18px",
                          '&.Mui-focused': {
                            color: '#a0a0a0',
                            transform: 'translate(10px, 0px)',
                            fontSize: "12px"
                          }
                        }
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <MenuItemCustom value={option.city} key={option.city_id}>
                        {option.city}
                      </MenuItemCustom>
                    </li>
                  )}
                />
              </FormControl>
              <FormControl sx={{ m: 0, minWidth: 120 }}>
                <CustomInputLabel >اللغات</CustomInputLabel>
                <SelectFilter
                  label="اللغات"
                  value={searchLanguage}
                  onChange={(e) => {
                    sessionStorage.setItem('searchLanguage', e.target.value)
                    setSearchLanguage(e.target.value)
                  }}
                >
                  <MenuItemCustom value="العربية">العربية</MenuItemCustom>
                </SelectFilter>
              </FormControl>
              <FormControl sx={{ m: 0, minWidth: 120 }}>
                <CustomInputLabel >التخصصات</CustomInputLabel>
                <SelectFilter
                  label="التخصصات"
                  value={searchSpeciality}
                  onChange={(e) => {
                    sessionStorage.setItem('searchSpeciality', e.target.value)
                    setSearchSpeciality(e.target.value)
                  }}
                >
                  {categories.map((speciality) => (
                  <MenuItemCustom value={speciality} key={speciality}>{speciality}</MenuItemCustom>
                  ))}
                </SelectFilter>
              </FormControl>
              <FilterApplyButton onClick={(e)=>{e.stopPropagation(), applyFilters(), deletePopupOpen(e)}}>عرض النتائج</FilterApplyButton>
            </Grid>
          </RTLProvider>
        </FilterPopup>
      </DeletePopupWrapper>
      <LoadingBackDrop loading={loading}/>
    </ThemeProvider>
  )
}
