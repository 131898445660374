import React from 'react'
import { useMediaQuery, Box } from '@mui/material'
import { BioText } from '../LawyersProfile/LawyersProfileStyles'

const ContactItem = ({ img, text, highlighted, onClick}) => {

  const isMobile = useMediaQuery('(max-width:768px)')
  return (
    <Box display='flex' gap='20px' onClick={onClick} style={{ cursor: 'pointer' }}>
      <img src={img} alt='icon'></img>
      <BioText color='#000000!important' style={highlighted ? {textDecoration: 'underline'} : {}}>{text}</BioText>
    </Box>
  )
}

export default ContactItem