import { Box, Grid, useMediaQuery } from '@mui/material'
import CustomPlainTextField from 'components/FormComponents/CustomPlainTextField'
import PasswordTextField from 'components/FormComponents/PasswordTextField'
import { ButtonText, Container, CustomButton } from 'components/Login/LoginStyles'
import React, { useState, useEffect } from 'react'
import { TopText } from './ChangePasswordStyles'
import LoadingBackDrop from 'components/Loading/LoadingBackDrop'
import { useLocation, useNavigate } from 'react-router-dom'
import { resendOTP } from 'api/auth'
import { getUserMobile } from 'api/profile'
import { useAuth } from 'context/auth'

const ChangePasswordScreen = ({ setError, setSuccess, onLoaded, errorMessage }) => {
    const { user } = useAuth()
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const navigate = useNavigate()
    const location = useLocation()
    const gridStyles = { display: 'flex', justifyContent: 'center', alignItems: 'center' }
    const isMobile = useMediaQuery('(max-width:768px)')

    useEffect(() => {
        if (errorMessage) {

            setError(errorMessage)
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
            const timer = setTimeout(() => {
                setError('')
                
            }, 5000)
            return () => clearTimeout(timer)
        }

    }, [errorMessage]) // Dependencies

    const onChangePassword = async () => {
        if (password === '' || confirmPassword === '' || newPassword === '') {
            setError('كلمة المرور غير متطابقة')
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
            return false
        }
        if (newPassword.length < 8) {
            setError('كلمة المرور يجب الا تقل عن 8 احرف')
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
            return false
        }
        if (confirmPassword !== newPassword) {
            setError('يجب أن تكون كلمات المرور هي نفسها')
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
            return false
        }
        setLoading(true)
        try {
            const mobile_number = await getUserMobile({ id: user?.id })
            await resendOTP({ mobile_number, oldPassword: password })
            navigate('../otp-password', { state: { mobile_number: mobile_number, oldPassword: password, newPassword: newPassword } })
            setLoading(false)
        } catch (error) {
            setError(error.message)
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
            setLoading(false)
        }
    }

    return (
        <Box maxWidth="765px" maxHeight="100" mx='auto' zIndex={10} mb={'100px'}>
            <Container sx={{ zIndex: '10 !important', height: 'fit-content!important', position: 'relative', marginBottom: '200px' }}>
                <Grid container justifyContent='center' alignItems='center' paddingX={isMobile ? '32px' : 0}>
                    <Grid xs={12} md={12} mb={isMobile ? '91px' : '60px'} mt={isMobile ? '23px' : '80px'} sx={gridStyles}>
                        <TopText >استخدم النموذج أدناه لتغيير كلمة المرور لحساب قانوني الخاص بك</TopText>
                    </Grid>
                    <Grid item xs={12} md={12} sx={gridStyles}>
                        <PasswordTextField label='كلمة المرور الحالية' value={password} setValue={(e) => setPassword(e.target.value)} CustomWidth={isMobile ? '300px' : '380px'} />
                    </Grid>
                    <Grid item xs={12} md={12} mt='40px' sx={gridStyles}>
                        <PasswordTextField label=' كلمة المرور الجديدة' value={confirmPassword} setValue={(e) => setConfirmPassword(e.target.value)} CustomWidth={isMobile ? '300px' : '380px'} />
                    </Grid>
                    <Grid item xs={12} md={12} mt='40px' sx={gridStyles}>
                        <PasswordTextField label='تأكيد  كلمة المرور الجديدة' value={newPassword} setValue={(e) => setNewPassword(e.target.value)} CustomWidth={isMobile ? '300px' : '380px'} />
                    </Grid>
                    <Grid item xs={12} md={12} mt='48px' mb={isMobile ? '40px' : '77px'} sx={gridStyles}>
                        <CustomButton onClick={onChangePassword} CustomColor='#825925' CustomWidth={isMobile ? '300px' : '380px'} style={{ gap: '40px' }}>
                            <ButtonText>تغيير</ButtonText>
                            <img src='login-arrow.svg' alt="arrow" />
                        </CustomButton>
                    </Grid>

                </Grid>
            </Container>
            {!useMediaQuery('(max-width:990px)') && <Box overflow={'overlay'} mt={-12} mr={-30}>
                <img src='login-background.svg' alt='login' onLoad={onLoaded} />
            </Box>}
            <LoadingBackDrop loading={loading} />
        </Box>
    )
}

export default ChangePasswordScreen