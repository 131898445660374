import { CustomTextField, LawyerBoxWrapper } from 'components/Lawyer/LawyerStyles'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography, useMediaQuery } from '@mui/material'
import { is } from '@babel/types'

const LawyerBoxItem = ({ icon, text, mobileIcon, desktopIcon, disabled, navigateTo }) => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:508px)')

    return (
        <LawyerBoxWrapper disabled={disabled} onClick={() => navigate(navigateTo)} >
            <img width={isMobile ? mobileIcon : desktopIcon } alt="box-icon" src={icon} />
            <CustomTextField>
                {text}
            </CustomTextField>
        </LawyerBoxWrapper>
    )
}

export default LawyerBoxItem