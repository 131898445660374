import styled from "@emotion/styled"
import { CardMedia, Typography, Box, CardContent, Grid, Button, Select, InputLabel, InputBase, ListItem } from "@mui/material"

export const TitleText = styled(Typography)`
	color: #1f2732;
	font-size: 30px;
	font-weight: 700;
  @media (max-width: 768px) {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;    
  }
`

export const TitleSubText = styled(Typography)`
font-family: Tajawal;
font-size: 16px;
font-weight: 500;
padding-top: 6px;
line-height: 19px;
letter-spacing: 0em;
text-align: right;
color: #B4975A;
cursor: pointer;
@media (max-width: 768px) {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
`

export const NameText = styled(Typography)`
font-family: Tajawal;
font-size: 18px;
font-weight: 500;
line-height: 21px;
letter-spacing: 0em;
text-align: right;
	cursor: pointer;
  color: #34312A;
  max-width: 200px;
  @media (max-width: 990px) {
    max-width: 170px;
  }
  @media (max-width: 768px) {
    max-width: 200px;
  }
`

export const TitleTopLawyers = styled(Typography)`
font-family: Tajawal;
font-size: 25px;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: center;
  color: #34312A;
`

export const DescriptionText = styled(Typography)`
font-family: Tajawal;
font-size: 15px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0em;
text-align: right;
color: #34312AB2;

	}
`

export const CityText = styled(Typography)`
	font-size: 15px;
	font-weight: 500;
	line-height: 18px;

`
export const StyledCardMedia = styled(CardMedia)`
  width: fit-content;
  object-fit: cover;
  max-height: 120px;
  min-height: 120px;
  margin-bottom: 2px;
  margin-left: 10px;
  max-width: 100px;
  min-width: 100px;
  border-radius: 4px;

`
export const RatingBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 55px;
  @media (max-width: 1200px) {
    margin-right: 45px;
  }
  @media (max-width: 990px) {
    margin-right: 0;
  } 
`
export const StyledCardContent = styled(CardContent)`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }

`

export const TopLawyersGrid = styled(Grid)`
  display: grid;
  align-items: center;
  align-content: center;
  gap: 50px;
`
export const TopLawyersQuestion = styled(Grid)`
  display: flex;
  align-items: center;
  height: fit-content;
  margin: 51px 0;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 768px) {
    margin: 25px 0;
  }
`

export const PaginationText = styled(Typography)`
	color: rgba(0, 0, 0, 0.4);
	font-size: 17px;
	font-weight: 500;
	padding-bottom: 47px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 18px;
  }
`

export const TopLawyersWrapper = styled(Grid)`
  background-color: #FCFAF2;
  height: fit-content;
  padding-bottom: 77px;
  width: 430px;
  @media (max-width: 1200px) {
    width: 350px;  
  }
  @media (max-width: 990px) {
    width: 300px;  
  }
  @media (max-width: 768px) {
    width: calc(100% + 40px);
    margin-right: -20px;
    margin-bottom: 30px;
  }
`

export const HelpText = styled(Typography)`
font-family: Tajawal;
font-size: 30px;
font-weight: 700;
line-height: 36px;
letter-spacing: 0em;
text-align: right;
color: #1F2732;
@media (max-width: 1200px) {
  font-size: 26px;
}
@media (max-width: 990px) {
  font-size: 20px;
  width: 180px;
} 
`

export const HelpTextLight = styled(Typography)`
font-family: Tajawal;
font-size: 22px;
font-weight: 500;
line-height: 26px;
letter-spacing: 0em;
text-align: right;
text-align: right;
color: #1F2732;
@media (max-width: 1200px){
  font-size: 24px;
  text-align: center;
}
@media (max-width: 768px){
  font-size: 22px;
}
`

export const HelpButton = styled(Button)`
font-family: Tajawal;
font-size: 19px;
font-weight: 400;
line-height: 23px;
letter-spacing: 0em;
text-align: center;
color: #FFF;
background: #1F2732;
padding: 12px 24px;
border-radius: 40px;
:hover{
  color: #FFF;
  background: #1F2732;
  opacity: 95%;
}
@media (max-width: 1200px){
  padding: 12px 20px ; 
  font-size: 17px;
}
@media (max-width: 990px){
  font-size: 15px;
}
`

export const HelpButtonLong = styled(HelpButton)`
padding: 12px 100px;
@media (max-width: 1200px){
  padding: 12px 60px; 
}
@media (max-width: 768px){
  padding: 12px 14px;
  width: 120px;
  margin: 0 auto;
}
`

const StyledButton = styled(Button)`
  background-color: #B4975A;
  color: white;
  border-radius: 40px;
  padding: 12px 14px;
  width: 151px;
  &:hover {
    background-color: #B4975A;
  }
  &:disabled{
    background-color: #B4975A80;
    color: #fff;
  }
  @media (max-width: 900px){
    width: 120px;
  }
  @media (max-width: 768px){
    width: 100px;
  }
  @media (max-width: 360px){
    width: 80px;
  }
`

export const FirstButton = styled(StyledButton)`
  background-color: #B4975A;
  margin-left: 10px; 
  margin-bottom: 0px;
  @media (max-width: 768px){
    margin-left: 8px; 
  }
`

export const PreviousButton = styled(StyledButton)`
`

export const NextButton = styled(StyledButton)`
  margin-right: 10px;
  @media (max-width: 768px){
    margin-right: 8px;
  }
`
export const BottomSection = styled(Grid)`
  display: flex;
  align-items: center;
  max-width: 861px !important;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 30px;
  padding: 32px 60px;
  background: #E3DCCF47;
  @media (max-width: 768px){
    display: grid;
    width: calc(100% + 40px);
    margin-right: -20px;
    justify-content: center;
    justify-items: center;
    padding: 32px 50px;
  }
  @media (max-width: 350px){
    padding: 32px 30px;
  }
`

export const SelectFilter = styled(Select)`
background: #FFF;
border-radius: 4px;
font-family: Tajawal;
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0px;
text-align: right;
color: #1F273280;
width: calc(100vw - 60px);
max-width: 380px;
height: 50px;
margin-bottom: 40px;
& fieldset{
  border: 2px solid #E3E1DC;
}
& svg {
  color: #DADADA;
}
& .MuiSelect-select{
  display: grid;
  align-items: center;
  width: 80%;
  padding: 10px 14px 0!important;
}
&.MuiInputLabel-shrink {
  transform: translate(10px, 3px);
  font-size: 12px;
}
@media (max-width: 768px) {
  max-width: 402px;
  margin-bottom: 20px;
}
`

export const InputLabelCustom = styled(InputLabel)`
font-family: Tajawal;
&.MuiInputLabel-shrink {
  transform: translate(10px, 3px);
  font-size: 12px;
}
`
export const ListItemCustom = styled(ListItem)`
padding: 0;
padding-left: 10px;
& .MuiTypography-root{
  font-family: "Tajawal";
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: #000;
  opacity: 1;
}
& span {
  color: #CCCCCC;
}
& .MuiCheckbox-colorPrimary.Mui-checked {
  color: #B4975A;
}
`

export const FilterPopup = styled.div(`
width: 765px;
height: 420px;
max-height: 100vh;
background-color: #FFF;
box-shadow: 0px 4px 40px rgba(130, 89, 37, 0.05);
border-radius: 30px;
margin: 20vh auto;
padding: 40px;
overflow-y: auto; /* Allow vertical scrolling */
/* Hide the scrollbar */
scrollbar-width: thin;
scrollbar-color: transparent transparent;

/* Webkit browsers (e.g., Chrome) */
&::-webkit-scrollbar {
  width: 6px;
}

&::-webkit-scrollbar-thumb {
  background-color: transparent;
}
@media (max-width: 768px) {
	width: 100%;
    border-radius: 0;
    margin: 0 auto!important;
    padding: 20px;
    position: fixed;
    bottom: 0;
    border-radius: 20px 20px 0 0;
}

@media (min-width: 1400px) {
    margin: 28vh auto !important;
  }

@media (max-height: 729px and min-width: 729px ) {
	height: 90vh;
	margin: 2vh auto!important;
  }
`)

export const FilterButton = styled(Button)`
color: white;
background-color: #B4975A;
padding: 12px 35px;
border-radius: 40px;
min-width: 207px;
height: 48px;
margin-right: 39px;
display:flex;
align-items: center;
text-wrap: nowrap;

@media (max-width: 1440px){
	margin-right: 0;
	margin-top: 10px;
}
@media (max-width: 1050px){
	padding:0 10px;
}
@media (max-width: 768px) {
	background-color: white;
	color: #B4975A;
	font-size: 16px;
	margin-right: 0px;
	padding:0;
	width: auto;
	min-width: unset;
	height: 0;
	margin-top: 30px;
	&:active {
		background-color: white; 
	}
	&:hover{
		background-color: white!important; 
	}
}
&:hover {
	background-color: #825925; 
}

`

export const SearchContainer = styled('div')`
	display: flex;
	align-items: center;
	border-radius: 26px;
	border: 1px solid #d7d7d7;
	background-color: #fff;
	padding: 0px; // Adjust as needed
	width: 50%;
	@media (max-width: 1440px){
		width: 100%;	
	}

	height: 46px;
	justify-content: space-between;
	
`

export const StyledInputBase = styled(InputBase)`
	color: inherit;
	flex: 1;
	padding-block: 7px;
	margin-right: 21.17px;
  width: 100%;
  input::placeholder {
    color: #000000;
    opacity: 50%;
    font-family: Tajawal, sans-serif; 
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: right; 
  }
	@media (max-width: 768px) {
		font-size: 15px;
	}
`

export const SearchButton = styled(Button)`
	background-color: #825925;
	color: white;
	padding: 18px 36.29px 14px 36.29px;
	border-radius: 40px 0px 0px 40px;
	height: inherit;
	margin-right: auto;
	@media (max-width: 768px) {
		width: 71.78px;
		font-size: 15px;
	}

	&:hover {
		background-color: #825925; // Darker shade for hover state
	}
`

export const ClearButton = styled(Button)`
color: #B4975A;
padding: 0;
margin-right: 21px;
font-size: 16px;
font-weight: 500;
line-height: 19px;
	&:hover {
		background-color: white!important; 
	}
@media (max-width: 768px) {
	height: 25px;
	margin-top: 19px;
}
`