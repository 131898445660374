/* eslint-disable no-useless-escape */
import { Box, Checkbox, Dialog, DialogContent, DialogContentText, Grid, Radio, Rating, useMediaQuery } from '@mui/material'
import React, { useRef, useState } from 'react'
import { CommentCount, ReviewCommentInput, ReviewModalCity, ReviewModalHired, ReviewModalName, ReviewModalNoName, ReviewModalProfileCard, ReviewModalRadioText, ReviewModalRequired, ReviewModalSuccess, ReviewModalTerms, ReviewModalTermsLink, ReviewModalTitle, ReviewModalVerifiedLawyer, StyledCheckbox, StyledRadio } from './ReviewModalStyles'
import StarRating from './AddRating'
import styled from '@emotion/styled'
import RTLProvider from 'helpers/RTLProvider'
import { InputLarge } from 'components/EditAccount/EditAccountStyles'
import { TitleItemLight } from 'components/Account/AccountStyles'
import CustomPlainTextField from 'components/FormComponents/CustomPlainTextField'
import { ButtonText, CustomButton } from 'components/Login/LoginStyles'
import LoadingBackDrop from 'components/Loading/LoadingBackDrop'
import { addReview } from 'api/reviews'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import { useParams } from 'react-router-dom'
import { AddQuestionEmailTerms } from 'components/AddQuestion/AddQuestionStyles'

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
})

const ReviewModal = ({ openModal, setOpenModal, setSelectedValue, selectedValue, lawyer }) => {
  const [hover, setHover] = useState(-1)
  const [comment, setComment] = useState('')
  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [visibleName, setVisibleName] = useState(true)
  const [isHired, setIsHired] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const isMobile = useMediaQuery('(max-width:768px)')
  const {id} = useParams()
  const dialogRef = useRef(null)
  const [imgSrc, setImgSrc] = useState(
    `https://storage.googleapis.com/qanooni-lawyer-image/${id}`
  )

  const handleImageError = () => {
    setImgSrc(`${process.env.PUBLIC_URL}/top-lawyer-default.svg`)
  }

  const scrollToTop = () => {
    if (dialogRef.current) {
      dialogRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }

  const emailValidation = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+\s*$/
  const validateFields = () => {
    if (!selectedValue) {
      return 'يجب اختيار التقييم اولا'
    }
    if (!isHired) {
      return 'يجب تعيين المحامي اولا حتي يتم تقييمه'
    }
    if (!comment || !comment.trim()) {
      return ' التعليق مطلوب'
    }
    if (!firstName && visibleName) {
      return 'الاسم الأول مطلوب'
    }
    if (visibleName &&  !/^(?!.*(<.*?>|javascript:|(\b(on[a-zA-Z]*=))|(\b(SELECT|INSERT|UPDATE|DELETE|DROP|CREATE|ALTER|EXEC|UNION|TRUNCATE|REPLACE)\b|['";]|--|\/\*|\*\/)))([\u0600-\u06FFa-zA-Z\s?]+|[\u0600-\u06FFa-zA-Z\s?]+(?: [\u0600-\u06FFa-zA-Z\s?]+)*)$/.test(firstName)) {
      return 'الاسم الاول يحتوي علي رموز ممنوعة'
    }
    if (!email) {
      return 'البريد الالكتروني مطلوب'
    } else if (!emailValidation.test(email)) {
      return 'صيغة البريد الالكتروني غير صحيحة'
    }
    return null
  }

  const handleRating = (event, newValue) => {
    setSelectedValue(newValue)
    console.log(`Rated with value: ${newValue}`)
  }
  const handleClose = () => {
    setOpenModal(false)
    setSuccess(false)
    setError(false)
    setComment('')
    setEmail('')
    setFirstName('')
    setVisibleName(true)
    setIsHired(false)
  }
  const maxRating = 5
  const handleHover = (event, newHover) => {
    const correctedHover = 5-1
    newHover=4
    setHover(correctedHover)
  }

  const onAddReview = async () => {
    setLoading(true)
    const errorMessage = validateFields()
    if (errorMessage) {
      setError(errorMessage)
      scrollToTop()
      setLoading(false)
      return
    }
    try {
      await addReview({
        user_id: id,
        rating: selectedValue,
        comment,
        name_visible: visibleName,
        is_hired: isHired,
        review_name: firstName,
        review_email: email
      })
      setSuccess(true)
      setLoading(false)
      setError(false)
    } catch (error) {
      setError(error.message)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      setLoading(false)
    }
  }

  const handleCommentChange = (event) => {
    setComment(event.target.value)
  }

  return (
    <div>
      <Dialog fullScreen={isMobile} open={openModal} onClose={handleClose} maxWidth='lg' sx={{
        ".MuiDialog-paperWidthLg": {
          width: "760px !important",
          borderRadius: isMobile ? '0' : '30px'
        },
        '& .MuiDialogContent-root': {
          overflowY: 'auto',
          scrollbarWidth: 'none', 
          msOverflowStyle: 'none', 
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }
      }} >
        <DialogContent ref={dialogRef}>
          <DialogContentText>
            <Box display='flex' justifyContent={success? 'left':'space-between'} gap={isMobile ? '130px' : "300px"} mt='20px' pr='5px' width={'100%'} >
              
                {!success && <ReviewModalTitle>قيّم محاميك !</ReviewModalTitle>}
    
              <Box display='flex' justifyContent='left' height='18px' width='50px' ml="20px">
                <img style={{width: '50px !important', height: '18px !important'}} onClick={handleClose} src={`${process.env.PUBLIC_URL}/cross.svg`} alt="cross" />
              </Box>
            </Box>
            {error && <ErrorMessage message={error} />}
            {!success &&
              <><Box display='flex' justifyContent='center'>

                <ReviewModalProfileCard>
                  <Box display='flex' alignItems='center' justifyContent='flex-start' gap='20px' mt='21px'  mr='36px'>
                    <img src={imgSrc} onError={handleImageError} alt='User Profile' width='75px' height='90px' style={{ 
                        height: '90px', 
                        width:  '75px', 
                        objectFit: 'cover',
                        borderRadius: '4px' }}  />
                    <Box>
                      <ReviewModalName>{lawyer.full_name}</ReviewModalName>
                      <ReviewModalVerifiedLawyer>محامي مرخص</ReviewModalVerifiedLawyer>
                      <ReviewModalCity>المدينة: {lawyer?.city}</ReviewModalCity>
                    </Box>
                  </Box>
                </ReviewModalProfileCard>
              </Box>
              <Box display='flex' justifyContent='center'>
                <Grid container width='100%'>
                  <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }} mt={isMobile ? "27px" : "35px"}>
                    <StyledRating
                      style={{ marginTop: '6px', direction: 'ltr' }} // Ensure direction is set to 'rtl'
                      name="customized-icons"
                      defaultValue={selectedValue}
                      precision={1}
                      icon={<img src={`${process.env.PUBLIC_URL}/star_active.svg`} alt="active star" width={isMobile ? '40' : '60px'} />}
                      emptyIcon={<img src={`${process.env.PUBLIC_URL}/star_inactive.svg`} alt="inactive star" width={isMobile ? '40' : '60px'} />}
                      onChange={handleRating}
                      onChangeActive={handleHover}
                      sx={{'.MuiRating-iconHover': {
                        color: 'red'
                      }}} />
                  </Grid>
                  <Grid item sx={{ display: "flex", justifyContent: "right", alignItems: "center", width: "100%" }} mr={isMobile ? "5px" : "27px"} mt="51px">
                    <ReviewModalRequired>كافة الحقول مطلوبة</ReviewModalRequired>
                  </Grid>
                  <Grid item sx={{ display: "flex", justifyContent: "right", alignItems: "center", width: "100%" }} mr={isMobile ? "-5px" : "18px"} mt={isMobile ? "25px" : "35px"}>
                    <StyledCheckbox checked={isHired} onChange={() => setIsHired(!isHired)} />
                    <ReviewModalHired>هل قمت بتعيين هذا المحامي؟</ReviewModalHired>
                  </Grid>

                  <Grid item mr={isMobile ? "5px" : "30px"} mt={isMobile ? "30px" : "0px"} ml={isMobile ? "0px" : '51px'}>
                    <Grid display='flex' justifyContent='left' alignItems='center' width={isMobile? '100%': '660px'}>
                      <CommentCount>{200 - comment.length} حرف.</CommentCount>
                    </Grid>
                    <RTLProvider>
                      <ReviewCommentInput
                        label="اكتب التعليق"
                        multiline
                        sx={isMobile? {width: '100%'}: {width: '660px'}}
                        rows={isMobile? 4:6}
                        value={comment}
                        onChange={handleCommentChange}
                        isFocused ={isFocused}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        inputProps={{ maxLength: 200 }} />
                    </RTLProvider>
                  </Grid>
                  <Grid item mr={isMobile ? "5px" : "29px"} mt="30px">
                    <ReviewModalNoName>عرض التقييم بدون اسم؟</ReviewModalNoName>
                  </Grid>
                  <Grid container item mr={isMobile ? "-5px" : "24px"} mt={isMobile ? '37px' : "30px"}>
                    <Grid sx={{ display: "flex", alignItems: 'center' }} xs={12} sm={2} md={2.5} item >
                      <StyledRadio value="yes"
                        name='visibleName'
                        checked={visibleName === false}
                        onChange={() => setVisibleName(false)} />
                      <ReviewModalRadioText>نعم</ReviewModalRadioText>
                    </Grid>
                    <Grid sx={{ display: "flex", alignItems: 'center' }} xs={12} sm={3} md={5} item mt={isMobile && '30px'}>
                      <StyledRadio value="no"
                        name='visibleName'
                        checked={visibleName === true}
                        onChange={() => setVisibleName(true)} />
                      <ReviewModalRadioText>لا , اعرض اسمي الأول مع التقييم</ReviewModalRadioText>
                    </Grid>
                  </Grid>
                  {visibleName && <Grid item mr={isMobile? "0px":"28px"} mt="30px">
                    <CustomPlainTextField CustomWidth={isMobile && '320px'} label="الاسم الأول" value={firstName} setValue={(e) => setFirstName(e.target.value)} />
                  </Grid>}
                  <Grid item mr={isMobile? "0px":"28px"} mt="30px">
                    <CustomPlainTextField CustomWidth={isMobile && '320px'} label="البريد الالكتروني" value={email} setValue={(e) => setEmail(e.target.value)} />
                  </Grid>
                  <Grid item mr={isMobile? "0px":"28px"} mt={isMobile? "51px" : "22px"}>
                  <ReviewModalTerms>
                             بالضغط على إرسال فإنك توافق علي <ReviewModalTermsLink href="/terms-conditions">سياسة الخصوصية و شروط الاستخدام</ReviewModalTermsLink>.
                  </ReviewModalTerms>
                  </Grid>
                  <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }} mr={isMobile? "0px": ''} mt="64px" mb='20px'>
                    <CustomButton onClick={onAddReview} CustomWidth={isMobile && '320px'} CustomColor='#B4975A'>
                      <ButtonText>إرسال التقييم</ButtonText>
                    </CustomButton>
                  </Grid>
                </Grid></Box></>
            }
            {
              success && <Box>
                <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: "center", width: "100%" }} mt={isMobile ? '129px' : '50px'}>
                  <img src={`${process.env.PUBLIC_URL}/reviewSuccess.svg`} alt='success' width='159px' height='159px' />
                  <ReviewModalSuccess mt='55px'>تم إرسال التقييم !</ReviewModalSuccess>
                  <Box mt={isMobile ? '226px' : '69px'} mb='88px'>
                    <CustomButton onClick={handleClose} CustomColor='#B4975A' CustomWidth={isMobile ? '320px' : '380px'}>
                      <ButtonText>إغلاق النافذة</ButtonText>
                    </CustomButton>
                  </Box>
                </Box>
              </Box>
            }
          </DialogContentText>
        </DialogContent>
        <LoadingBackDrop loading={loading} />
      </Dialog>
    </div>
  )
}

export default ReviewModal