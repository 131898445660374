import React, { useState } from 'react'
import { Rating, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { RatingTitle, RatingBoxWrapper, RatingValueText } from '../LawyersProfile/LawyersProfileStyles'
import ReviewModal from './ReviewModal'

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
  '& .MuiRating-label': {
    display: 'none',
  },
  justifyContent: 'flex-end',
  width: 'fit-content',
  marginLeft: '25px'
})
const StarRating = ({lawyer}) => {
  const [hover, setHover] = useState(-1)
  const [selectedValue, setSelectedValue] = useState(2)
  const [openModal, setOpenModal] = useState(false)
  const isMobile = useMediaQuery('(max-width:768px)')
  const handleClickOpen = () => {
      setOpenModal(true)
  }
  const handleRating = (event, newValue) => {
    setSelectedValue(newValue)
    console.log(`Rated with value: ${newValue}`)
    handleClickOpen()
  }

  return (
    <RatingBoxWrapper dir='ltr' width={isMobile ? '80%!important' : '70%!important'} padding='16px 13px 18px 0!important' mt={isMobile ? '10px!important' :'62px!important'} display='grid' justifyContent='end' >
      <RatingTitle >قُم بتقييم المحامي الآن</RatingTitle>
      <StyledRating
        style={{ marginTop: '6px', gap: (isMobile ? '6.4px' : '7.3px') }}
        name="customized-icons"
        defaultValue={0}
        precision={1}
        icon={<img src={`${process.env.PUBLIC_URL}/star_active.svg`} alt="active star" width='32px' />} // Active star icon
        emptyIcon={<img src={`${process.env.PUBLIC_URL}/star_inactive.svg`} alt="inactive star" width='32px' />} // Inactive star icon
        onChange={handleRating}
        onChangeActive={(event, newHover) => {
          setHover(newHover)
        }}
      />
      <ReviewModal lawyer={lawyer} openModal={openModal} setOpenModal={setOpenModal} selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
    </RatingBoxWrapper>
  )
}

export default StarRating

