// utils/validation.js
const sqlInjectionPattern = /(\b(SELECT|INSERT|UPDATE|DELETE|DROP|CREATE|ALTER|EXEC|UNION|TRUNCATE|REPLACE)\b|['";]|--|\/\*|\*\/)/i
const xssPattern = /<.*?>|javascript:|(\b(on[a-zA-Z]*=))/i
export const validateFields = (fields, rules) => {
    for (const field in rules) {
        const value = fields[field]
        const validations = rules[field]

        for (const rule of validations) {
            const error = rule(value, fields)
            if (error) {
                return error
            }
        }
    }
    return null
}

// Validation rules
export const rules = {
    required: (fieldName, errorMessage) => (value) => {
        if (!value || !value.trim()) {
            return errorMessage || `${fieldName} مطلوب`
        }
        return null
    },
    length: (fieldName, min, max) => (value) => {
        if (value.length < min) {
            return `${fieldName} يجب ألا يقل عن ${min} أحرف`
        }
        if (max && value.length > max) {
            return `${fieldName} يجب ألا يزيد عن ${max} أحرف`
        }
        return null
    },
    regex: (fieldName, regex, errorMessage) => (value) => {
        if (!regex.test(value)) {
            return errorMessage || `${fieldName} غير صحيح`
        }
        return null
    },
    custom: (fieldName, validateFn, errorMessage) => (value) => {
        if (!validateFn(value)) {
            return errorMessage || `${fieldName} غير صحيح`
        }
        return null
    },
    noSqlInjection: (field, message) => (value) => sqlInjectionPattern.test(value) ? message : null,
    noXss: (field, message) => (value) => xssPattern.test(value) ? message : null
}
