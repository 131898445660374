import React, { useState } from 'react'
import { AnswerUserAnswers, AnswerUserBox, AnswerUserLocation, AnswerUserVerified, AnswerUsername, UserPhotoContainer } from './AnswerStyles'
import { Box, Rating, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const AnswerUser = ({answer}) => {
  const navigate = useNavigate()
  const [imgSrc, setImgSrc] = useState(`https://storage.googleapis.com/qanooni-lawyer-image/${answer?.user_id}`)

  const handleError = () => {
    setImgSrc(`${process.env.PUBLIC_URL}/top-lawyer-default.svg`)
  }
  return (
    <Box display='flex' gap='10px' onClick={() => navigate(`/profile/${answer?.user_id}`)} style={{ cursor: 'pointer' }}>
        <UserPhotoContainer>
            <img alt='user-photo' style={{borderRadius: '4px', width: '100px', height: '120px', objectFit: 'cover'}} src={imgSrc} onError={handleError} />
        </UserPhotoContainer>
        <AnswerUserBox>
            <AnswerUsername>{answer?.full_name}</AnswerUsername>
            <AnswerUserVerified>محامي مرخص</AnswerUserVerified>
            <AnswerUserLocation>المدينة: {answer?.city}</AnswerUserLocation>
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
							<Rating name="read-only" size="small" value={answer?.rating} readOnly />
							<Typography sx={{ mr: "6px", mt: "2px", fontWeight: '500', fontSize: '15px', lineHeight: 'normal' }}>
              {Math.round(answer?.rating?? 0)}/5
							</Typography>
			</Box>
            <AnswerUserAnswers>عدد الإجابات: ({answer?.total_answers_by_user?? 0}) إجابة</AnswerUserAnswers>
        </AnswerUserBox>

    </Box>
  )
}

export default AnswerUser