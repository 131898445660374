import React, { useEffect, useState } from 'react'
import { LawyerInfoBox, LawyerTitle, LawyerBasicInfo, LawyerName, LawyerSpec, LawyerCity, AboutLawyerText, BioTitle, BioSubTitle, VerticalLine, ContactInfoBox } from './LawyersProfileStyles'
import { Grid, useMediaQuery, Typography, Box, Button, autocompleteClasses } from '@mui/material'
import { Container } from 'react-bootstrap'
import Stars from '../LawyerComponents/Stars'
import LicenseBox from 'components/LawyerComponents/LicenseBox'
import { useAuth } from 'context/auth'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import LawyerNavbar from 'components/LawyerComponents/LawyerNavbar'
import LawyerTitleItem from 'components/LawyerComponents/LawyerTitleItem'
import SpecializationItem from 'components/LawyerComponents/SpecializationItem'
import Experience from 'components/LawyerComponents/Experience'
import Education from 'components/LawyerComponents/Education'
import RatingBox from 'components/LawyerComponents/RatingBox'
import RatingBarChart from 'components/LawyerComponents/RatingBarChart'
import StarRating from 'components/LawyerComponents/AddRating'
import ReviewSection from 'components/LawyerComponents/ReviewSection'
import { abs } from 'stylis'
import SocialSection from 'components/LawyerComponents/SocialSection'
import ContactItem from 'components/LawyerComponents/ContactItem'
import { getLawyer, getLawyerReviews } from 'api/LawyerGuide'
import LoadingBackDrop from 'components/Loading/LoadingBackDrop'
import { ratingRound } from 'helpers/formatRating'

const LawyersProfileScreen = ({ onLoaded }) => {
  const [lawyer, setLawyer] = useState('')
  const [reviews, setReviews] = useState([])
  const [currentPage, setCurrentPage] = useState(2)
  const [hasMoreReviews, setHasMoreReviews] = useState(true)
  const [comments, setComments] = useState([])
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const isMobile = useMediaQuery('(max-width:768px)')
  const { user } = useAuth()
  const navigate = useNavigate()
  let { id } = useParams()
  const isUserApproved = user?.status
  const [selectedItem, setSelectedItem] = useState(1)
  const [error, setError] = useState(false)
  const ratings = [
    { rating: '5/5', value: (11 / 20) * 100, count: 11 },
    { rating: '4/5', value: (3 / 20) * 100, count: 3 },
    { rating: '3/4', value: (3 / 20) * 100, count: 3 },
    { rating: '2/5', value: (2 / 20) * 100, count: 2 },
    { rating: '1/5', value: (1 / 20) * 100, count: 1 },
  ]
  const [imgSrc, setImgSrc] = useState(
    `https://storage.googleapis.com/qanooni-lawyer-image/${id}`
  )

  const handleImageError = () => {
    setImgSrc(`${process.env.PUBLIC_URL}/top-lawyer-default.svg`)
  }
  const handleSelectItem = (item) => {
    setSelectedItem(item)
    setTimeout(() => {
        const element = document.getElementById(`scroll_${item}`)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
        }
    }, 0) // or a small delay like 10ms
}
useEffect(() => {
  const fetchLawyer = async () => {
    setLoading(true)
    try {
      const data = await getLawyer(id)
      setLawyer(data)
      const reviewsData = await getLawyerReviews(id, 1)
      setReviews(reviewsData.result)
      if(reviewsData.result.totalReviews === 0){
        setHasMoreReviews(false)
      }
      setComments(reviewsData.result.reviews)
      setLoading(false)
    } catch (err) {
      setError(err.message)
      setLoading(false)
    }
  }
  fetchLawyer()
}, [])

const handleEmail = (email) => {
  window.location.href = `mailto:${email}`
}

const fetchMoreReviews = async () => {
  try {
    setLoading(true)
    if (hasMoreReviews) {
      const reviewsData = await getLawyerReviews(id, currentPage)
      setComments(prevReviews => [...prevReviews, ...reviewsData.result.reviews])
      setLoading(false)
      if (reviewsData.result.reviews.length < 6) {
        setHasMoreReviews(false)
      } else {
        setCurrentPage(currentPage + 1)
      }
    }
  } catch (err) {
    setError(err.message)
  }
}

const handleSpecializationClick = (text) => {
  sessionStorage.setItem('searchSpeciality', text)
  navigate('/lawyer-guide')
}

const handleCallClick = () => {
  window.location.href = `tel: +966 ${lawyer?.social_links[0]?.mobile_number}`
}
  return (
    <Box style={isMobile ? { padding: '0' } : {margin: '0 60px'}}  >
      <Grid display='grid' container minHeight={isMobile ? 'calc(100vh - 599px)' : 'calc(100vh - 266px)'} alignContent={'flex-start'} mb={isMobile ? '82px' : '148px'} paddingTop={isMobile ? '16px' : '100px'}>
        <LawyerTitle mb="40px"  >دَليل المُحامين</LawyerTitle>
        <LawyerInfoBox width='100%' display={isMobile ? 'grid' : 'flex'} padding='60px' overflow='hidden' >
          <Grid display={isMobile ? 'grid' : 'flex'} alignItems='flex-start'>
          <Box 
      sx={{
        height: isMobile ? '216px' : '300px',
        width: isMobile ? '180px' : '250px',
        minWidth: (isMobile? '180px' : '250px'),
        maxWidth: (isMobile? '180px' : '250px'),
        margin: '0 auto',
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden', 
        borderRadius: '10px',
      }}
    >
      <img 
        src={imgSrc}
        alt='profile picture' 
        onError={handleImageError}
        style={{ 
          height: '100%', 
          width: '100%',
          objectFit: 'cover', // or 'contain' depending on your requirement
          borderRadius: '10px',
        }} 
      />
    </Box>
            <LawyerBasicInfo>
              <LawyerName>{lawyer.full_name}</LawyerName>
              <LawyerSpec>محامي مرخص</LawyerSpec>
              <LawyerCity>المدينة: {lawyer?.city}</LawyerCity>
              <Grid display='flex' justifyContent={isMobile ? 'center' : ''} gap='7px'>
                <Stars rate={Math.round(reviews?.averageRating)?? 0} size={isMobile ? '14px' : '19px'} />
                <LawyerCity style={{ opacity: '1' }}>{reviews.averageRating ? ratingRound(reviews.averageRating) : 0}/5</LawyerCity>
              </Grid>
            </LawyerBasicInfo>
          </Grid>
          <LicenseBox lawyer={lawyer} />
        </LawyerInfoBox>
        <LawyerNavbar selectedItem={selectedItem} onSelectItem={handleSelectItem} />
        <LawyerTitleItem id='scroll_1' text='عن المحامي' marginTop={isMobile ? '81px' : '93px'} />
        <AboutLawyerText mr={isMobile ? '39px' : '26px'} mt={isMobile ? '19px' : '24px'} ml={isMobile? '21px': '60px'}>{lawyer.description}</AboutLawyerText>
        <LawyerTitleItem id='scroll_2' text='التخصصات' marginTop={isMobile ? '50px' : '76px'} />
        <Grid display='flex' mt='30px' gap={'10px'} mr={isMobile ? '39px' : '26px'} flexWrap={'wrap'} maxWidth={isMobile ? '290px' : '750px'} ml={isMobile? '21px': '60px'}>
          {lawyer.specializations?.map((speciality, index) => (
          <SpecializationItem text={speciality} key={index} handleClick={handleSpecializationClick} />
          ))}
        </Grid>
        <LawyerTitleItem id='scroll_3' text='السيرة الذاتية' marginTop={isMobile ? (lawyer?.description && lawyer?.description.length > 0 ? '50px' : '50px') : (lawyer?.description && lawyer?.description.length > 0 ? '146px' : '76px')} />
        <Grid mr={isMobile ? '39px' : '31x'} mt='48px' display={isMobile ? 'grid' : 'flex'} justifyContent='start' gap={isMobile ? '60px' : '300px'}  >
          <Grid>
            <BioTitle mr={isMobile? '0px':'28px'}>الخبرة</BioTitle>
            {lawyer?.experience?.map((object, index) => (
            <Experience key={index} title={object.position} company={object.company} year={`${object.start_date} - ${object.end_date}`} />
            ))}
          </Grid>
          <Grid>
            <BioTitle>التعليم</BioTitle>
            {lawyer.certificates?.map((certificate, index) => (
            <Education key={index} title={certificate.university} subtitle={certificate.degree} year={`السنة: ${certificate.year}`} />
          ))}
          </Grid>
          <Grid>
            <BioTitle>اللّغات</BioTitle>
            <Box mt='27px'>
            {lawyer?.languages?.map((language, index) => (
            <BioSubTitle mb='12px' key={index}>{language}</BioSubTitle>
            ))}
            </Box>
          </Grid>
        </Grid>
        <LawyerTitleItem id='scroll_4' text={`التقييم (${reviews.totalReviews})`} marginTop={isMobile ? (lawyer?.experience && lawyer?.experience.length > 0 ? '79px' : '50px') : (lawyer?.experience && lawyer?.experience.length > 0 ? '105px' : '76px')} />
        <Grid display={isMobile ? 'grid' : 'flex'} >
          <Box width={isMobile ? '95%' : '40%'} >
            <Grid display='grid' mr={isMobile ? '39px' : '2px'} mt={isMobile? '44px':'106px'} >
              <BioTitle>تقييم العملاء</BioTitle>
              <RatingBox ratingValue={reviews} />
              <RatingBarChart ratings={reviews} />
              {isMobile && (
                <AboutLawyerText mt={isMobile ? '75px' : '24px'}>{Math.floor((reviews.averageRating/5)*100)}% من عملاء المحامي يوصون بهذا المحامي لصديق.</AboutLawyerText>
              )}
              <StarRating lawyer={lawyer}/>
            </Grid>
          </Box>
          <VerticalLine display={isMobile ? 'none' : 'block'} />
          <Grid display='grid' mr={isMobile ? '15px' : '2px'} mt={isMobile? '40px':'106px'} width={isMobile ? '95%' : '60%'}>
            <Box>
              <BioTitle mr='24px'>مراجعات العملاء</BioTitle>
              <ReviewSection lawyer={lawyer} reviews={comments} fetchMoreReviews={fetchMoreReviews} hasMoreReviews={hasMoreReviews} totalReviews={reviews?.totalReviews} />
            </Box>
          </Grid>
        </Grid>
        <LawyerTitleItem  id='scroll_5' text='التواصل' marginTop={isMobile? '48px':'121px'} />
        <ContactInfoBox width='100%' display='grid' padding='26px' mt='20px'>
          <BioSubTitle>يمكنك متابعة المحامي من خلال معلومات التواصل التالية:</BioSubTitle>
          <Grid display={isMobile ? 'grid' : 'flex'} alignItems='flex-start' mt='28px' gap={isMobile ? '30px' : '180px'}>
            <SocialSection data={lawyer?.social_links && lawyer.social_links.length > 0 ? lawyer?.social_links[0]: {}}></SocialSection>
            <Box display='grid' gap='16px'>
              <ContactItem img={`${process.env.PUBLIC_URL}/phone.svg`} text={lawyer?.social_links && lawyer.social_links.length > 0 && lawyer.social_links[0].mobile_number ? `${lawyer.social_links[0].mobile_number} 966+` : 'لا يوجد'} onClick={handleCallClick}/>
              <ContactItem img={`${process.env.PUBLIC_URL}/mail.svg`} text={lawyer?.social_links && lawyer.social_links.length > 0 &&  lawyer?.social_links[0].email ? lawyer?.social_links[0].email : ''} highlighted={true} 
              onClick={() => handleEmail(lawyer?.email)}/>
              <ContactItem img={`${process.env.PUBLIC_URL}/location.svg`} text={lawyer?.city} />
            </Box>
          </Grid>
        </ContactInfoBox>
      </Grid>
      <LoadingBackDrop loading={loading}/>
    </Box>
  )
}
export default LawyersProfileScreen