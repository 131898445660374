import React from 'react'
import { useMediaQuery, Grid } from '@mui/material'
import { BioText, RatingBoxWrapper, RatingValueText } from '../LawyersProfile/LawyersProfileStyles'
import Stars from './Stars'
import { ratingRound } from 'helpers/formatRating'

const RatingBox = ({ ratingValue, size = '24px' }) => {

  const isMobile = useMediaQuery('(max-width:768px)')
  const ratingValueRounded = ratingRound(parseFloat(ratingValue?.averageRating))
  return (
    <RatingBoxWrapper >
      <Grid display='flex' justifyContent='flex-start' alignItems='center' gap='15px'>
        <RatingValueText>{ratingValue?.averageRating?? 0}</RatingValueText>
        <Stars size={size} rate={ratingValueRounded}></Stars>
      </Grid>
      <BioText color='#000000!important' fontSize={isMobile ? '16px!important' : ''} mt='5px'>متوسط التقييم بناءً على {ratingValue?.totalReviews?? 0} تقييمًا</BioText>
    </RatingBoxWrapper>
  )
}

export default RatingBox