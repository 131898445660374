import { InputAdornment } from '@mui/material'
import { ClosedEye, CustomTextField, OpenEye } from 'components/Login/LoginStyles'
import RTLProvider from 'helpers/RTLProvider'
import React, { useState } from 'react'

const PasswordTextField = ({ value, setValue, label, CustomWidth }) => {
    const [show, setShow] = useState(false)
    const [isFocused, setIsFocused] = useState(false)

  return (
    <RTLProvider>
      <CustomTextField label={label}
      type={show? 'text': 'password'}
      sx={{
          width: CustomWidth ? CustomWidth : '380px',
          '& .MuiInputLabel-root': {
            color: value?  "#1F2732": 'rgba(31, 39, 50, 0.50)',
            transform: value ? 'translate(10px, 3px)' : 'translate(10px, 13px)',
            height: value ?"16px": "50px",
            fontFamily: 'Tajawal',
            fontSize: value ?"12px": "18px",
            fontWeight: 400,
            lineHeight: "normal",
            '&.Mui-focused': {
              color: "#1F2732",
              textAlign: "right",
              fontFamily: "Tajawal",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              transform: 'translate(10px, 3px)',
              height: '16px'
            }
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent', 
            },
            '&:hover fieldset': {
              borderColor: 'transparent', 
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent', 
            },
          },
          '& .MuiInputBase-root input':{
            padding: '16.5px 14px 0',
            boxShadow: '200px 200px 100px white inset' 
          },
          '& .MuiInputBase-root .MuiInputAdornment-root':{
            paddingTop: '16.5px',
          }
        }} 
        InputProps={{
          endAdornment: <InputAdornment position="end">{show? <OpenEye alt='show password' width="19" height="15" src='open-eye.svg' onClick={() => setShow(!show)} />: <ClosedEye onClick={() => setShow(!show)} alt='closed eye' src='closed-eye.svg'/>}</InputAdornment>,
        }}
        value={value}
        onChange={setValue}
        isFocused ={isFocused}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        />
    </RTLProvider>
  )
}

export default PasswordTextField