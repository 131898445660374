import styled from "@emotion/styled"
import { Button, TextField, Typography, Grid, Link, Select, InputLabel } from "@mui/material"
import { keyframes } from '@emotion/react'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const SpinningImage = styled.img`
  margin: ${({ isMobile }) => (isMobile ? '11px auto 40px' : '20px auto 103px')};
  animation: ${spin} 1s linear infinite;
`

export const TitleItem = styled(Typography)`
font-family: Tajawal;
font-size: 20px;
font-weight: 700;
line-height: 24px;  
letter-spacing: 0em;
text-align: right;
color: #000000;
text-wrap: wrap;
cursor: pointer;
`

export const GridContainer = styled(Grid)`
display: grid;
align-items: center;
justify-content: start;
align-content: center;

`

export const ContentItem = styled(TitleItem)`
font-family: Tajawal;
font-size: 18px;
font-weight: 500;
line-height: 22px;
letter-spacing: 0em;
text-align: right;
color: #000;
`

export const QuestionLink = styled(Link)`
font-family: Tajawal;
font-size: 18px;
font-weight: 500;
line-height: 22px;
letter-spacing: 0em;
text-align: right;
color: #825925;
text-decoration: none;
cursor: pointer;

`

export const GridItem = styled(Grid)`
display: grid;
justify-content: flex-start;
padding: 30px;
margin-bottom: 40px;
width: 100%;
min-width: 1570px;
background-color: #FBFAF8;
@media (max-width: 768px) {
  margin-bottom: 30px;
  min-width: unset;
}
@media (max-width: 1600px) {
  min-width: 98vw;
}
@media (max-width: 1200px) {
  min-width: 97vw;
}
`

export const PageTitle = styled(Typography)`
color: #1F2732;
font-family: Tajawal;
font-size: 30px;
font-weight: 700;
line-height: 36px;
letter-spacing: 0em;
margin-top: 29px;
@media (max-width: 768px) {
    margin-top: 33px;
    font-size: 20px;
    font-weight: 500;
}
`

export const DateItem = styled(Typography)`
font-family: Tajawal;
font-size: 18px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
text-align: right;
color: #000;
opacity: 40%;
`

export const FilterButton = styled(Button)`
width: 286px;
height: 48px;
padding: 12px, 14px, 12px, 14px;
border-radius: 40px;
gap: 10px;
background: #B4975A;
color: #FFF;
font-family: Tajawal;
font-size: 19px;
font-weight: 400;
line-height: 23px;
letter-spacing: 0em;
text-align: center;
position: absolute;
left: 0;
top: 91px;
@media (max-width: 768px) {
    top: 0;
    position: relative;
    color: #B4975A!important;
    background: #FFF!important;
    justify-content: flex-start;
    height: unset;
    width: fit-content
  }
&:hover{
    background: #B4975A;
    color: #FFF;
  }
`
export const ClearButton = styled(Button)`
font-family: 'Tajawal';
color: #B4975A;
padding: 0;
margin: 10px 21px 0 0;
height: 0;
position: absolute;
left: 340px;
top: 95px;
font-size: 16px;
font-weight: 500;
line-height: 19px;
@media (max-width: 768px) {
  top: 0;
  position: relative;
  height: inherit;
  display: block;
  width: 100%;
  left: 0;
  text-align: right;
  margin: 0 21px 20px 0;
}
`