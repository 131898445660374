import React from 'react'
import { SuccessMessageText, SuccessWrapper } from './SuccessMessageStyles'

const SuccessMessage = ({ message }) => {
    return (
        <SuccessWrapper>
            <SuccessMessageText>
                {message}
            </SuccessMessageText>
        </SuccessWrapper>
    )
}

export default SuccessMessage