import FooterSection from 'Layout/Footer'
import Layout from 'Layout/Layout'
import AddQuestionComponent from 'components/AddQuestion/AddQuestionComponent'
import React from 'react'

const AddQuestion = () => {
  return (
    <Layout isWhiteBackground>
        <AddQuestionComponent/>
        <FooterSection/>
    </Layout>
  )
}

export default AddQuestion