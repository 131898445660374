import styled from "@emotion/styled"
import { Typography } from "@mui/material"

export const TopText = styled(Typography)`
color: #3A3A3A;
text-align: right;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Tajawal;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
width: 380px;
@media (max-width: 768px) {
    width: 300px;
  }
`