import BASE_URL from "./BaseURL"

export const getQuestions = async questionData => {
    try {
      const response = await fetch(`${BASE_URL}/questions-offset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(questionData)
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }

  export const getQuestionList = async questionData => {
    try {
      const response = await fetch(`${BASE_URL}/questions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(questionData)
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }
  
  export const getTotalCountFilter = async questionData => {
    try {
      const response = await fetch(`${BASE_URL}/questions/count-filter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(questionData)
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }

  export const getAnsweredQuestionsCountFilter = async questionData => {
    try {
      const response = await fetch(`${BASE_URL}/questions/answered-count-filter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(questionData)
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }

  export const getTotalCount = async questionData => {
    try {
      const response = await fetch(`${BASE_URL}/questions/count`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }
  
  export const addQuestion = async (question) => {
    try {
      const response = await fetch(`${BASE_URL}/add-question`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(question)
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }
  
  export const getQuestion = async questionId => {
    try {
      const response = await fetch(`${BASE_URL}/questions/${questionId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }
  