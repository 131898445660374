import styled from "@emotion/styled"
import { Box, Typography } from "@mui/material"

export const ExtraPagesHeading = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: right;
  background: url('aboutus.png') center/cover no-repeat;
  background-color: ${({ CustomColor }) => CustomColor ? CustomColor : '#B4975A'};
  width: 100%;
  height: 199px; // Adjust height as needed to fit your design
  @media (max-width: 768px) {
    background: url('aboutus-mobile.png') center/cover no-repeat;
    background-color:${({ CustomColor }) => CustomColor ? CustomColor : '#B4975A'};

  }
`

export const ExtraPagesHeader = styled(Typography)`
  color: #FFF;
  text-align: right; // Adjusted to center, change as needed
  font-family: Tajawal;
  font-size: 70px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 60px;
  z-index: 1;
  @media (max-width: 768px) {
    margin-right: 20px;
    font-size: 40px;

  }
`

export const ExtraPagesSubHeader = styled(Typography)`
  color: #FFF;
  text-align: right; // Adjusted to center, change as needed
  font-family: Tajawal;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 126.316% */
  margin-right: 60px;
  z-index: 1;
  @media (max-width: 768px) {
    margin-right: 20px;
    margin-left: 38px;
    margin-top: 14px;
  }
`

export const ExtraPagesTitle = styled(Typography)`
color: #1F2732;
text-align: right;
font-family: Tajawal;
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: normal;
@media (max-width: 768px) {
    font-size: 24px;
  }
`

export const ExtraPagesDetails = styled(Typography)`
color: #1F2732;
text-align: justify;
font-family: Tajawal;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 162.5% */
`
