import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, useMediaQuery } from '@mui/material'
import { is } from '@babel/types'

const Stars = ({ rate, size, gap }) => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:508px)')

    return (
        <Grid display='flex' gap={gap ?? ''}>
            <img src={(rate > 4) ? `${process.env.PUBLIC_URL}/star_active.svg` : `${process.env.PUBLIC_URL}/star_inactive.svg`} width={size} style={{height: (size)}}/>
            <img src={(rate > 3) ? `${process.env.PUBLIC_URL}/star_active.svg` : `${process.env.PUBLIC_URL}/star_inactive.svg`} width={size} style={{height: (size)}}/>
            <img src={(rate > 2) ? `${process.env.PUBLIC_URL}/star_active.svg` : `${process.env.PUBLIC_URL}/star_inactive.svg`} width={size} style={{height: (size)}}/>
            <img src={(rate > 1) ? `${process.env.PUBLIC_URL}/star_active.svg` : `${process.env.PUBLIC_URL}/star_inactive.svg`} width={size} style={{height: (size)}}/>
            <img src={(rate > 0) ? `${process.env.PUBLIC_URL}/star_active.svg` : `${process.env.PUBLIC_URL}/star_inactive.svg`} width={size} style={{height: (size)}}/>
        </Grid>
    )
}

export default Stars