import React from 'react'
import { useMediaQuery, Box, Button, Grid } from '@mui/material'
import { Twitter, LinkedIn } from '../LawyersProfile/LawyersProfileStyles'

const SocialSection = ({data}) => {
    const isMobile = useMediaQuery('(max-width:768px)')
    const navigate = (url) => {
        return window.location.href = url
    }
    return (
        <Grid display={isMobile ? 'flex' : 'grid'} gap='15px'>
            <Box>
                {!isMobile && (
                    <Button sx={{ border: '1px solid #0000001A', borderRadius: '40px', width: '175px', height: '45px', position: 'relative' }} onClick={() => {navigate(data.twitter)}}>
                        <Twitter>X</Twitter>
                        <img src={`${process.env.PUBLIC_URL}/twitter-logo.svg`} style={{ position: 'absolute', right: '8px' }}></img>
                    </Button>
                )}
                {isMobile && (
                    <Button style={{ padding: '0', borderRadius: '100%', minWidth: '40px' }} onClick={() => {navigate(data.twitter)}}>
                        <img src={`${process.env.PUBLIC_URL}/twitter-logo-mobile.svg`}  ></img>
                    </Button>
                )}
            </Box>
            <Box>
                {!isMobile && (
                    <Button sx={{ border: '1px solid #0000001A', borderRadius: '40px', width: '175px', height: '45px', position: 'relative' }} onClick={() => navigate(data.linkedin)}>
                        <LinkedIn>LinkedIn</LinkedIn>
                        <img src={`${process.env.PUBLIC_URL}/linkedin-logo.svg`} style={{ position: 'absolute', right: '16px' }}></img>
                    </Button>
                )}
                {isMobile && (
                    <Button style={{ padding: '0', borderRadius: '100%', minWidth: '40px' }} onClick={() => {navigate(data.linkedin)}} >
                        <img src={`${process.env.PUBLIC_URL}/linkedin-logo-mobile.svg`}  ></img>
                    </Button>
                )}
            </Box>
        </Grid>

    )
}

export default SocialSection