// will add layout components later

import React from 'react'
import NavbarSection from './Navbar'

const Layout = ({children, navbarClass, isWhiteBackground, isUserButton}) => {
  return (
    <div style={isWhiteBackground?{ background: '#FFF'} : {background: '#F1F1F1'}}>
      <NavbarSection ContainerClass={navbarClass} hasBorder={isWhiteBackground} isUserButton={isUserButton}/>
        {children}
    </div>  
  )
}

export default Layout