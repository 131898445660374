import styled from "@emotion/styled"
import { Typography } from "@mui/material"

export const OTPInput = styled.input`
color: #000;
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: flex;
width: 60px;
height: 60px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border: 2px solid #E3E1DC;
border-radius: 5px;
text-align: center;
&:focus {
     outline: none; 
     border-color: #825925;

  }

`

export const OTPText = styled(Typography)`
color: var(--Dark, #1F2732);
text-align: right;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Tajawal;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 125% */
width: 380px;
@media (max-width: 768px) {
    font-size: 14px;
  }
`

export const OTPPhoneNumber = styled(Typography)`
color: var(--Dark, #1F2732);
text-align: right;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Tajawal;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 125% */
width: 380px;
direction: ltr;

`
export const OTPResend = styled(Typography)`
color: ${({ timeout }) => timeout ? '#825925' : '#999'};
cursor: ${({ timeout }) => timeout ? 'pointer' : 'auto'};
text-decoration: ${({ timeout }) => timeout ? 'underline' : 'none'};
text-align: right;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Tajawal;
font-size: 16px;
font-style: normal;
font-weight: 500;
display: inline;
line-height: 20px; /* 125% */
`

export const TimerText = styled(Typography)`
color: var(--Main, #B4975A);
text-align: right;
display: inline;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Tajawal;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 125% */
`