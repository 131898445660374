import styled from "@emotion/styled"
import { TextField, Typography } from "@mui/material"

export const AddQuestionHeading = styled(Typography)`
text-align: center;
font-family: Tajawal;
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: 36px; /* 155.556% */
@media (max-width: 768px) {
    font-size: 20px;
    text-align: right;
}
`

export const AddQuestionRequired = styled(Typography)`
text-align: right;
font-family: Tajawal;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 19.2px; /* 155.556% */
@media (max-width: 768px) {
    font-size: 14px;
    text-align: right;
}
`

export const AddQuestionLabel = styled(Typography)`
text-align: right;
font-family: Tajawal;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 155.556% */
@media (max-width: 768px) {
    font-size: 18px;
    text-align: right;
}
`

export const AddQuestionCounter = styled(Typography)`
color: #000;
text-align: left;
font-family: Tajawal;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
`

export const AddQuestionInput = styled(TextField)`
width: 100%;
max-width: 960px;
height: 120px;
margin-top: 12px;
background-color: #FFF;
& fieldset{
  border: 2px solid #E3E1DC;
  height: 120px;
}
& .MuiFormLabel-root{
    color: rgba(31, 39, 50, 0.50);
    text-align: right;
    font-family: Tajawal;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
& .MuiInputBase-input{
  font-family: Tajawal;
}
@media (max-width: 768px) {
  height: 240px;
  margin-top: 5px;
  max-width: 402px;
  & fieldset{
    height: 240px;
  }
}
@media (max-width: 360px) {
  width: calc(100vw - 60px);
}
`

export const AddQuestionOtherInput = styled(TextField)`
width: 100%;
max-width: 960px;
height: 180px;
margin-top: 12px;
background-color: #FFF;
& fieldset{
  border: 2px solid #E3E1DC;
  height: 180px;
}
& .MuiFormLabel-root{
    color: rgba(31, 39, 50, 0.50);
    text-align: right;
    font-family: Tajawal;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
& .MuiInputBase-input{
  font-family: Tajawal;
}
@media (max-width: 768px) {
  margin-top: 5px;
  height: 390px;
  max-width: 402px;
  & fieldset{
    height: 390px;
  }
}
@media (max-width: 360px) {
  width: calc(100vw - 60px);
}
`

export const AddQuestionEmailText= styled(Typography)`
color: #3A3A3A;
text-align: right;
font-family: Tajawal;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
`
export const AddQuestionEmailTerms = styled.a`
color: var(--Secondary, #825925);
font-family: Tajawal;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
text-decoration: none;
`