import { Box, useMediaQuery } from '@mui/material'
import FooterSection from 'Layout/Footer'
import Layout from 'Layout/Layout'
import LawyerScreen from 'components/Lawyer/LawyerScreen'
import { Heading } from 'components/Lawyer/LawyerStyles'
import React from 'react'

const Lawyer = () => {
    const isMobile = useMediaQuery('(max-width:600px)')
    return (
        <Box bgcolor='#F1F1F1'>
        <Layout>
            <LawyerScreen />
            <FooterSection />
        </Layout>
        </Box>
    )
}   

export default Lawyer