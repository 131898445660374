import { Box, Grid, useMediaQuery } from '@mui/material'
import { ButtonText, Container, CustomButton } from 'components/Login/LoginStyles'
import React, { useEffect, useState } from 'react'
import OTPField from './OTPField'
import { OTPPhoneNumber, OTPResend, OTPText, TimerText } from './OTPStyles'
import { formatTime } from 'helpers/formatTime'
import { changeEmail, resendOTP, verifyOTP } from 'api/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import LoadingBackDrop from 'components/Loading/LoadingBackDrop'
import { maskPhoneNumber } from 'helpers/maskPhone'
import { useAuth } from 'context/auth'

const OTPBox = ({ setError, route, newAccount = false, onLoaded }) => {
  const [otp, setOtp] = useState('')
  const [timer, setTimer] = useState(60)
  const {authLogin} = useAuth()
  const [loading, setLoading] = useState(false)
  const [mobileNumber, setMobileNumber] = useState('')

  const location = useLocation()
  const navigate = useNavigate()
  const gridStyles = { display: 'flex', justifyContent: 'center', alignItems: 'center' }
  const isMobile = useMediaQuery('(max-width:768px)')
  
  useEffect(() => {
    if (timer > 0) {
      const timeout = setTimeout(() => {
        setTimer(timer - 1)
      }, 1000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [timer])

  useEffect(() =>{
    setMobileNumber(maskPhoneNumber("+966" + location.state.mobile_number))
  }, [])

  const validateFields = () => {
    if (!otp) {
      return 'رمز التحقق مطلوب'
    }
    return null
  }

  const onVerifyOTP = async () => {
    setLoading(true)
    const errorMessage = validateFields()
    if (errorMessage) {
      setError(errorMessage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      setLoading(false)
      return
    }
    try {
      if(newAccount){
        const {mobile_number, email, password, full_name, isTest} = location.state
        const res = await verifyOTP({ otp, mobile_number, email, password, full_name, mode: 'signup', isTest })
        await authLogin({ mobile_number, password })
      } else{
        await verifyOTP({ otp, mobile_number: location.state.mobile_number })
      }
      navigate(route, { state: { mobile_number: location.state.mobile_number, otp, newAccount } })
      setLoading(false)
    } catch (error) {
      setError(error.message)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      setLoading(false)
    }
  }

  const onResendOTP = async () => {
    setLoading(true)
    try {
      await resendOTP({ mobile_number: location.state.mobile_number })
      setTimer(60)
      setLoading(false)
    } catch (error) {
      setError(error.message)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      setLoading(false)
    }
  }

  const requestOtp = async () => {
    try {
      const otpCredential = await navigator.credentials.get({
        otp: { transport: ['sms'] },
      })

      if (otpCredential) {
        setOtp(otpCredential.code)
        onVerifyOTP()
      }
    } catch (error) {
      console.error('Error requesting OTP:', error)
    }
  }

  useEffect(() => {
    requestOtp()
  }, [])
  
  return (
    <Box position='relative' maxWidth="790px" maxHeight="100" mx='auto' zIndex={10}>
      <Container width={isMobile?'380px': '100%'} sx={{ zIndex: '10 !important', position: 'relative' }} marginTop="40px" customHeight="600px">
        <Grid container justifyContent='center' alignItems='center' paddingX={isMobile ? '32px' : 0}>
          <Grid item xs={12} md={12} mt={isMobile? '60px': '122px'} sx={gridStyles}>
            <OTPText>لقد تم إرسال رمز التحقق رقم الجوال الذي أضفته :</OTPText>
          </Grid>
          <Grid item xs={12} md={12} mt='10px' sx={gridStyles}>
            <OTPPhoneNumber>{mobileNumber}</OTPPhoneNumber>
          </Grid>
          <Grid item xs={12} md={12} mt={isMobile? '40px': '35px'} sx={gridStyles}>
            <Box sx={{direction: 'ltr'}}>
            <OTPField value={otp} onChange={setOtp} isMobile={isMobile} />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} mt={isMobile? '67px':'32px'} sx={gridStyles} style={{ justifyContent: 'right' }} mr={!isMobile ? 25 : 0} gap={2} width='380px'>
            <TimerText >{formatTime(timer)}</TimerText>
            <OTPResend timeout={timer ? false : true} onClick={onResendOTP}>أرسل الرمز مرة أخرى</OTPResend>
          </Grid>
          <Grid item xs={12} md={12} mt={isMobile? '71px':'75px'} mb={isMobile? '74px':'93px'} sx={gridStyles}>
            <CustomButton onClick={onVerifyOTP} CustomColor='#B4975A' CustomWidth={isMobile ? '300px' : '380px'}>
              <ButtonText>تأكيد رمز التحقق</ButtonText>
            </CustomButton>
          </Grid>
        </Grid>
      </Container>
      {!useMediaQuery('(max-width:990px)') && <Box overflow={'overlay'} mt={-12} mr={-30}>
        <img src='login-background.svg' alt='forgetPassword' onLoad={onLoaded} />
      </Box>}
      <LoadingBackDrop loading={loading} />
    </Box>
  )
}

export default OTPBox