import './styles/globals.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ForgetPassword from 'screens/ForgetPassword'
import ForgetPasswordOTP from 'screens/ForgetPasswordOTP'
import Home from 'screens/Home'
import Login from 'screens/Login'
import ResetPassword from 'screens/ResetPassword'
import Signup from 'screens/Signup'
import SignupOtp from 'screens/SignupOtp'
import Lawyer from 'screens/Lawyer'
import Account from 'screens/Account'
import { AuthProvider } from 'context/auth'
import ChangeEmail from 'screens/ChangeEmail'
import ChangePassword from 'screens/ChangePassword'
import ConfirmPassword from 'screens/ConfirmPassword'
import EditAccount from 'screens/EditAccount'
import AnsweredQuestions from 'screens/AnsweredQuestions'
import NewQuestions from 'screens/NewQuestions'
import ContactUs from 'screens/ContactUs'
import LawyersProfile from 'screens/LawyersProfile'
import { LawyersGuide } from 'screens/LawyersGuide'
import QuestionsList from 'screens/QuestionsList'
import AddQuestion from 'screens/AddQuestion'
import Question from 'screens/Question'
import Aboutus from 'screens/Aboutus'
import PrivacyPolicy from 'screens/PrivacyPolicy'
import TermsConditions from 'screens/Terms&Conditions'
import PrivateRoutes from 'PrivateRoute'
import PublicRoutes from 'PublicRoutes'
import { getUserStatus } from 'api/auth'

const App = () => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const fetchUserStatus = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('userData'))
        if(user.id){
          setLoading(true)
          const status = await getUserStatus(user.id)
          if(status.result === 'deleted' || status.result === 'suspended'){
            localStorage.removeItem('authToken')
            localStorage.removeItem('userData')
            window.location.href = '/'
            return true
          }
          user.status = status.result
          localStorage.setItem('userData', JSON.stringify(user))
          setLoading(false)
        } 
        return true
      } catch (err) {
        console.log(err)
      }
    }
    fetchUserStatus()
  }, [])

  return (
    <AuthProvider>
    <Router>
     {!loading && <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route element={<PublicRoutes />}>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/forget-password" element={<ForgetPassword />} />
              <Route path="/confirm-account" element={<SignupOtp />} />
              <Route path="/forget-password-otp" element={<ForgetPasswordOTP />} />
              <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
          <Route path="/lawyer" element={<Lawyer />} />
          <Route path="/otp-password" element={<ConfirmPassword />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/profile/:id" element={<LawyersProfile />} />
          <Route path="/lawyer-guide" element={<LawyersGuide/>} />
          <Route path="/questions-list" element={<QuestionsList/>} />
          <Route path="/add-question" element={<AddQuestion/>} />
          <Route path="/question/:id" element={<Question/>} />
          <Route path="/about-us" element={<Aboutus/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/terms-conditions" element={<TermsConditions/>} />
          <Route element={<PrivateRoutes />}>
              <Route path="/edit-account" element={<EditAccount />} />
              <Route path="/answered-questions" element={<AnsweredQuestions />} />
              <Route path="/new-questions" element={<NewQuestions />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/change-email" element={<ChangeEmail />} />
              <Route path="/account" element={<Account />} />
          </Route>
        </Routes>
      </div>}
    </Router>
    </AuthProvider>
  )
}

export default App
