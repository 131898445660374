import '../styles/navbar.css'
import React, { useEffect, useState } from 'react'
import { Navbar, Nav, FormControl, Form, Container } from 'react-bootstrap'
import { MenuItem, MenuList, useMediaQuery, Popper, Grow, Paper, ClickAwayListener, Button, Typography, Icon, Box } from '@mui/material'
import { MenuItemLawyer, MenuItemLawyerMobile } from '../components/NavbarComponents/MenuItems'
import { useAuth } from 'context/auth'
import { useNavigate } from 'react-router-dom'

const NavbarSection = ({ ContainerClass, hasBorder, isUserButton = true }) => {

  const { user, setUser } = useAuth()
  const [expanded, setExpanded] = useState(false)
  const isMobile = useMediaQuery('(max-width:990px)')
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const isUserApproved = user?.status === 'active'
  const anchorRef = React.useRef(null)
  const [imgSrc, setImgSrc] = useState(`${process.env.PUBLIC_URL}/user-profile.svg`)
  const location = window.location.pathname
  useEffect(()=>{
    setImgSrc(`https://storage.googleapis.com/qanooni-lawyer-image/${user?.id}`)
  }, [user?.id])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleNavigate = (route) => {
    if(!isUserApproved){
      navigate('/lawyer')
    } else {
      navigate(route)
    }
    setExpanded(false)
  }

  const handleLogout = () => {
    localStorage.removeItem("authToken")
    localStorage.removeItem("userData")
    navigate("/login")
    setUser(null)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const handleImageError = () => {
    setImgSrc(`${process.env.PUBLIC_URL}/user-profile.svg`)
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  return (
    <Navbar expanded={expanded} expand="lg" className={expanded ? `navbar-section expanded ${ContainerClass}` : `navbar-section ${ContainerClass}`} >
      <Container className={`navbar-container`}>
        <div className='navbar-container-inner' style={hasBorder ? ( isMobile ? {} : {borderBottom: '#E3E1DC 1px solid'} ) : {boxShadow: '0px 4px 10px 0px #00000008'}}>
          <div className='navbar-logo-wrapper'>
            <Navbar.Brand href="#home" className="navbar-logo" onClick={() => navigate('/')}>
              <img src='../NavbarLogo.svg' width={isMobile ? 90 : 140} alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={() => setExpanded(expanded ? false : "expanded")}
            />
          </div>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <Nav.Link href="/lawyer-guide" className='navbar-button guide' onClick={() => setExpanded(false)}>دليل المحامين<img src='../arrow-mobile.svg' hidden={isMobile ? false : true} /></Nav.Link>
              <Nav.Link href="/questions-list" className='navbar-button questions' onClick={() => setExpanded(false)}>الاسئلة والاجوبة<img src='../arrow-mobile.svg' hidden={isMobile ? false : true} /></Nav.Link>
            </Nav>
            {(user && !isMobile && isUserButton) && (
              <>
                <div className='navbar-login-btn-wrapper'>
                  <Button
                    variant="outline-primary"
                    className='navbar-login-btn no-border'
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? 'composition-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                  >
                    <div
                      style={{
                        height: '35px',
                        width: '35px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'hidden',
                        borderRadius: '50%',
                      }}
                    >
                      <img
                        src={imgSrc}
                        alt='profile picture'
                        onError={handleImageError}
                        style={{
                          height: '100%',
                          width: '100%',
                          objectFit: 'cover',
                          borderRadius: '10px',
                        }}
                      />
                    </div>
                    <div style={{ color: '#825925', marginTop: '4px' }}> مرحبا، </div>
                    <div style={{marginTop: '4px'}}>
                    {user.full_name.split(" ")[0]}
                    </div>
                    <img src='../arrow-down.svg' alt="Arrow down" />
                  </Button>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                    sx={{

                      borderRadius: '0',
                      width: '263px',
                      border: 'none',
                      zIndex: '100'
                    }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom-start' ? 'center' : 'center',
                        }}
                      >
                        <Paper sx={{ borderRadius: '0', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 10px 0px', }}>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                              sx={{ alignContent: 'center', }}
                            >
                              <MenuItemLawyer onClick={() => navigate('/lawyer')} text={'حسابي'} />
                              <MenuItemLawyer onClick={() => handleNavigate('/account')} text={'إعدادات الحساب'} />
                              <MenuItemLawyer onClick={() => handleNavigate('/edit-account')} text={'ملفي التعريفي'} />
                              <MenuItemLawyer onClick={() => handleNavigate('/answered-questions')} text={'مشاركاتي'} />
                              <MenuItemLawyer onClick={() => handleNavigate('/new-questions')} text={'أسئلة لم يتم الاجابة عليها'} />
                              <MenuItemLawyer onClick={handleLogout} text={'تسجيل الخروج'} />
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </>
            )}
            {(user && isMobile) && (
              <>
                <div className='navbar-login-btn-wrapper'>
                  <Button variant="outline-primary" className='navbar-login-btn' onClick={() => navigate('/lawyer')}>
                    <img src={imgSrc} onError={handleImageError} alt="User Icon" style={{ objectFit: 'cover', borderRadius: '50%', height: '35px', width: '35px'}} />
                    <div style={{ color: '#825925' }}> مرحبا، </div>
                    {user.full_name.split(" ")[0]}
                  </Button>
                </div>
                <Nav>
                  <MenuItemLawyerMobile onClick={() => navigate('/lawyer')} text={'حسابي'} />
                  <MenuItemLawyerMobile onClick={() => handleNavigate('/account')} text={'إعدادات الحساب'} />
                  <MenuItemLawyerMobile onClick={() => handleNavigate('/edit-account')} text={'ملفي التعريفي'} />
                  <MenuItemLawyerMobile onClick={() => handleNavigate('/answered-questions')} text={'مشاركاتي'} />
                  <MenuItemLawyerMobile onClick={() => handleNavigate('/new-questions')} text={'أسئلة لم يتم الاجابة عليها'} />
                  <MenuItemLawyerMobile onClick={handleLogout} text={'تسجيل الخروج'} />
                </Nav>
              </>
            )}
            {!user && (
              <div className='navbar-login-btn-wrapper'>
               {location !== '/login' && location !== '/signup' && location !== '/forget-password' && <Button onClick={() => navigate('/login')} variant="outline-primary" className='navbar-login-btn'>
                  <img src='../user-icon.svg' width={16} height={16} alt="User Icon" />
                  تسجيل الدخول للمحامين
                </Button>}
              </div>
            )}
          </Navbar.Collapse>
        </div>
      </Container>
    </Navbar>
  )
}

export default NavbarSection
