import React from "react"
import { Typography, Grid, useMediaQuery, Box } from "@mui/material"
import { Container } from 'react-bootstrap'
import { ExtraPagesDetails, ExtraPagesHeader, ExtraPagesHeading } from "components/Aboutus/AboutusStyles"

const ContactUsScreen = () => {
    const isMobile = useMediaQuery('(max-width:768px)')

    return (
        <Box minHeight={isMobile ? 'calc(100vh - 456px)' : 'calc(100vh - 160px)'}>
            <ExtraPagesHeading>
                <ExtraPagesHeader>تواصل معنا</ExtraPagesHeader>
            </ExtraPagesHeading>
            <Box mr={isMobile ? '30px' : '61px'} mt='134px'>
                <Box ml={isMobile ? '30px' : '78px'}>
                    <ExtraPagesDetails sx={{fontWeight: '500 !important'}}>إذا كان لديك أي أسئلة أو استفسارات، فلا تتردد في التواصل معنا. يمكنك التواصل من خلال البريد الإلكتروني التالي:</ExtraPagesDetails>
                    <Box display='flex' alignItems='center' gap="11.8px" mt={isMobile ? '64px' : '53px'} flexDirection={isMobile? 'column': 'row'}>
                        <Box>
                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
                                <circle cx="22.5" cy="22.5" r="22.5" fill="#B4975A" />
                                <path d="M28 18L27 18.6667L26.4376 19.0416C24.8338 20.1108 24.0319 20.6454 23.1652 20.8531C22.3992 21.0366 21.6008 21.0366 20.8348 20.8531C19.9681 20.6454 19.1662 20.1108 17.5624 19.0416L17 18.6667L16 18M20 31H24C26.8003 31 28.2004 31 29.27 30.455C30.2108 29.9757 30.9757 29.2108 31.455 28.27C32 27.2004 32 25.8003 32 23V21C32 18.1997 32 16.7996 31.455 15.73C30.9757 14.7892 30.2108 14.0243 29.27 13.545C28.2004 13 26.8003 13 24 13H20C17.1997 13 15.7996 13 14.73 13.545C13.7892 14.0243 13.0243 14.7892 12.545 15.73C12 16.7996 12 18.1997 12 21V23C12 25.8003 12 27.2004 12.545 28.27C13.0243 29.2108 13.7892 29.9757 14.73 30.455C15.7996 31 17.1997 31 20 31Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                            </svg>
                        </Box>
                        <ExtraPagesDetails>contact@qanoni.co</ExtraPagesDetails>
                    </Box>
                    <Box display='flex' alignItems='center' gap="11.8px" mt={isMobile ? '67px' : '35px'} mb={isMobile? '20px': '100px'} flexDirection={isMobile? 'column': 'row'}>
                        <Box>
                            <svg xmlns="http://www.w3.org/2000/svg" width="47" height="45" viewBox="0 0 47 45" fill="none">
                                <ellipse cx="23.5651" cy="22.5" rx="22.755" ry="22.5" fill="#B4975A" />
                                <path d="M13.9575 21.5C13.9575 16.5 18.0029 13 23.0595 13C28.1162 13 32.1615 16.5 32.1615 21.5C32.1615 29.3518 25.0822 32.9998 23.0595 32.9998C21.0369 32.9998 13.9575 29.3518 13.9575 21.5Z" stroke="white" strokeWidth="1.5" />
                                <path d="M26.0935 22C26.0935 23.6569 24.7352 25 23.0595 25C21.3839 25 20.0255 23.6569 20.0255 22C20.0255 20.3431 21.3839 19 23.0595 19C24.7352 19 26.0935 20.3431 26.0935 22Z" stroke="white" strokeWidth="1.5" />
                            </svg>
                        </Box>
                        <ExtraPagesDetails>المملكة العربية السعودية </ExtraPagesDetails>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ContactUsScreen