import { Box, useMediaQuery } from '@mui/material'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import { Heading } from 'components/Login/LoginStyles'
import ChangePasswordScreen from 'components/ChangePassword/ChangePasswordScreen'
import SuccessMessage from 'components/SuccessMessage/SuccessMessage'
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom' 
import Layout from 'Layout/Layout'
import FooterSection from 'Layout/Footer'

const ChangePassword = () => {
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const navigate = useNavigate()
    const location = useLocation() 
    const errorMessage = location.state?.errorMessage || null 
    const isMobile = useMediaQuery('(max-width:768px)')
	const [isContentLoaded, setIsContentLoaded] = useState(false)
	
	const onContentLoaded = () => {
		setIsContentLoaded(true)
	}
    useEffect(() => {
        if (errorMessage ) {
            navigate(location.pathname, { state: { replace: true } })
        }
    }, [errorMessage])

    return (
        <Layout navbarClass={'popup'} isWhiteBackground={isMobile} isUserButton={false}>
            <Box minHeight={isMobile ? 'calc(100vh - 456px)' : 'calc(100vh - 160px)'}>
                {error && <ErrorMessage message={error} />}
                {success && <SuccessMessage message={success} />}
                {isMobile && (<Box display='flex' flexDirection='column'>
                    <Box mt='40px' ml='22px' display='flex' justifyContent='flex-end' >
                        <img onClick={() => navigate('/account')} src="cross.svg" alt="cross" />
                    </Box>
                    <Box mx='auto' mt='50px'>
                        <img alt='logo' src='auth-logo.svg' />
                    </Box>
                </Box>)}
                <Heading style={{ marginTop: (isMobile ? '30px' : '') }}>تغيير كلمة المرور</Heading>
                <ChangePasswordScreen setError={setError} setSuccess={setSuccess} onLoaded={onContentLoaded} errorMessage={errorMessage}/>
            </Box>
            {(isContentLoaded || isMobile) && <FooterSection />} 
        </Layout>
    )
}

export default ChangePassword