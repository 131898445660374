import FooterSection from 'Layout/Footer'
import Layout from 'Layout/Layout'
import TermsAndConditions from 'components/Aboutus/TermsAndConditions'
import React from 'react'

const TermsConditions = () => {
    return (
        <Layout>
            <TermsAndConditions />
            <FooterSection />
        </Layout>
    )
}

export default TermsConditions