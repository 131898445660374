import { Box, useMediaQuery } from '@mui/material'
import React from 'react'
import { ExtraPagesDetails, ExtraPagesHeader, ExtraPagesHeading, ExtraPagesTitle } from './AboutusStyles'

const TermsAndConditions = () => {
    const isMobile = useMediaQuery('(max-width:768px)')

    return (
        <Box>
            <ExtraPagesHeading CustomColor="#825925">
                <ExtraPagesHeader>شروط الاستخدام</ExtraPagesHeader>
            </ExtraPagesHeading>
            <Box mr={isMobile?'30px':'60px'} mt='62px'>
                <Box ml={isMobile?'30px' :'78px'}>
                <ExtraPagesDetails>يُرجى قراءة شروط الاستخدام هذه بعناية قبل استخدام موقع قانوني.</ExtraPagesDetails>
                <ExtraPagesDetails>
                    <strong>1.	مقدمة عن موقع قانوني</strong>
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                يهدف موقع قانوني (ويُشار إليه فيما بعد بـ &quot;الموقع&quot;) إلى تيسير الوصول إلى العدالة ودعم الأفراد والشركات في حل مشكلاتهم القانونية من خلال محامين مرخصين. يتم تشغيل الموقع من قبل شركة الأذرع المتحدة للخدمات التسويقية (&quot;الشركة&quot;)، وهي شركة ذات مسؤولية محدودة، ومقيدة بالسجل التجاري تحت رقم 2052104792. يوفر الموقع حلًا متكاملًا لتلقي الأسئلة القانونية والحصول على إجابات من محامين مرخصين. يتيح الموقع للمستخدمين طرح أسئلتهم القانونية، حيث يتم توجيهها إلى محامين ذوي خبرة. كما يقدم قاعدة بيانات شاملة للمحامين في مختلف التخصصات والمناطق الجغرافية داخل المملكة العربية السعودية، ويتيح للمستخدمين البحث عن محامٍ مناسب استنادًا إلى تقييمات وآراء العملاء السابقين. بالإضافة إلى ذلك، يوفر الموقع موارد قانونية متنوعة (يشار إلي جميع الخدمات السابق ذكرها بـ &quot;الخدمات&quot;). يلتزم الموقع التزامًا تامًا بحماية خصوصية وأمان المستخدمين وفقًا لسياسة الخصوصية الخاصة بالموقع.
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                    <strong>2.	الموافقة على شروط الاستخدام</strong>
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                تمثل المقدمة عاليه وكافة البنود والأحكام الواردة هنا وكذلك الواردة بسياسة الخصوصية ( يشار إليهم مجتمعين ب &quot;شروط الاستخدام&quot;) جزءًا لا يتجزأ من شروط الاستخدام وتمثل القواعد المنظمة للعلاقة الناشئة بين الموقع والمستفيدين. 
استخدامك للموقع يعد إقرارًا منك بالموافقة على شروط الاستخدام وبأنك تقبلها وتوافق على الامتثال لها والالتزام بما ورد فيها. 

                </ExtraPagesDetails>

                <ExtraPagesDetails>
                    <strong>3.	طبيعة العلاقة بين الأطراف</strong>
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                تشمل الخدمات وسائل متعددة للتواصل مع المحامين والحصول على مشورة قانونية. إن دور الشركة هنا هو وسيط فقط، ولا تتحمل أي مسؤولية قانونية تتعلق بجودة أو دقة المعلومات أو الخدمات القانونية التي يقدمها المحامون عبر الموقع.
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                    <strong>4.	موقع قانوني ليس مكتب محاماة ولا يقدم خدمات قانونية</strong>
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                الشركة / الموقع ليست مكتب محاماة، ولا يقدم أي من موظفي الشركة نصائح قانونية أو خدمات صياغة للمستندات القانونية. لا يمكن اعتبار أي محتوى أو خدمات مقدمة من خلال الموقع كبديل عن الاستشارة القانونية من محامٍ مرخص. إذا كنت بحاجة إلى استشارة قانونية تتعلق بموقفك الخاص، ننصحك بالتواصل مع محامٍ مرخص.
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                يُقدِّم الموقع منصة للمعلومات القانونية والمساعدة الذاتية. تُعدّ المعلومات المتوفرة من خلال الموقع، بالإضافة إلى المحتوى المتعلق بالمسائل القانونية، موجهة لتعزيز المعرفة الشخصية ولا تُعتبر نصيحة قانونية.
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                لا يقدم الموقع أي ضمانات فيما يتعلق بمؤهلات أو كفاءة أي محامٍ مُدرج لدي الموقع أو بمدى دقة أو كمال العمل الذي يقوم به. ولن يتحمل الموقع أي مسؤولية أو التزام من أي نوع عن أي خدمات مهنية يتم تقديمها من قبل أي محامٍ تتعامل معه عبر الموقع، وأي استخدام أو اعتماد على هذه الخدمات المهنية يكون على مسؤوليتك الشخصية.
                </ExtraPagesDetails>

                <ExtraPagesDetails>
                    <strong>5.	ملفات تعريف المحامين وتصنيفهم</strong>
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                بينما يسعى الموقع للتأكد من تسجيل المحامين المرخصين فقط ، إلا أن الموقع لا يقوم بالتحقيق أو التحقق أو تقديم ضمانات حول دقة المعلومات الموجودة في أي ملف تعريف محامٍ على الموقع. المحامون المدرجون يتحملون وحدهم مسؤولية النصائح التي يقدمونها والخدمات التي يوفرونها والتعريف بأنفسهم.
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                تقييم المحامين ليس تأييدًا لأي محامٍ معين ولا يشكل ضمانًا لجودة أو كفاءة أو شخصية المحامي. كما أن تصنيف المحامين لا يتنبأ بنتيجة أي مسألة يشارك فيها ذلك المحامي. بل تهدف تقييمات المحامين إلى أن تكون نقطة انطلاق لجمع المعلومات حول المحامين الذين قد يكونون مناسبين لاحتياجاتك القانونية. 
                </ExtraPagesDetails>

                <ExtraPagesDetails>
                    <strong>6.	الشروط والأحكام</strong>
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                تشكل شروط الاستخدام هذه اتفاقية ملزمة قانونيًا بينك وبين الشركة بخصوص استخدامك للمعلومات والخدمات المتاحة من خلال الموقع. يعتبر استخدامك للموقع قبولًا وموافقة على جميع شروط الاستخدام.
                </ExtraPagesDetails>

                <ExtraPagesDetails>
                    <strong>
                    7.	التزاماتك عند التسجيل
                    </strong>
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                في إطار استخدامك للخدمة، تقر بأنك لديك الأهلية المعتبرة نظاميًا وشرعًا لإبرام عقد ملزم بموجب أنظمة المملكة العربية السعودية. كما توافق على تقديم معلومات صحيحة ودقيقة وحديثة وكاملة عن نفسك.
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                    <strong>8.	سلوك المستخدم</strong>
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                يتعهد المستخدم بعدم استخدام الموقع في أي من الآتي:
                </ExtraPagesDetails>
                <ul style={{listStyle: "circle", paddingRight: "30px"}}>
                    <li>	نشر أي محتوى غير قانوني، أو ينتهك حقوق الآخرين، أو يحتوي على فيروسات أو برامج ضارة.</li>
                    <li>التعدي على حقوق الملكية الفكرية.</li>
                    <li>إرسال رسائل غير مرغوب فيها أو الترويج لأنشطة غير قانونية أو خادعة.</li>
                    <li>القيام بأي أنشطة من شأنها تعطيل أو إعاقة أو إضرار تشغيل الموقع أو التمتع بالخدمات من قبل المستخدمين الآخرين. </li>
                </ul>
                <ExtraPagesDetails>يحتفظ الموقع بالحق في مراقبة أو إزالة أي محتوى ينتهك شروط الاستخدام هذه.</ExtraPagesDetails>

                <ExtraPagesDetails>
                    <strong>9.	إنهاء الخدمة</strong>
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                يحتفظ الموقع بالحق في إنهاء أو تعليق وصولك إلى الموقع في أي وقت ودون إشعار مسبق إذا خالفت شروط الاستخدام.
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                    <strong>10.	الروابط الخارجية</strong>
                </ExtraPagesDetails>
                <ExtraPagesDetails>قد يحتوي الموقع على روابط لمواقع خارجية. لا نتحمل أي مسؤولية عن محتوى أو ممارسات الخصوصية لهذه المواقع الخارجية.</ExtraPagesDetails>
                <ExtraPagesDetails>
                    <strong>11.	الاستخدام غير القانوني أو المحظور</strong>
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                كشرط لاستخدامك للموقع، تتعهد بعدم استخدام الموقع لأي غرض غير قانوني أو محظور بموجب شروط الاستخدام.
                </ExtraPagesDetails>

                <ExtraPagesDetails>
                    <strong>12.	تعديلات على شروط الاستخدام</strong>
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                يحتفظ الموقع بالحق في تعديل هذه الشروط في أي وقت. سيتم نشر أي تعديلات على هذه الصفحة، ويعتبر استمرارك في استخدام الموقع بعد نشر التعديلات قبولًا للشروط المُعَدلة.
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                    <strong>13.	حقوق الملكية الفكرية</strong>
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                جميع محتويات الموقع محمية بموجب حقوق الطبع والنشر وجميع أنظمة ولوائح الملكية الفكرية بالمملكة العربية السعودية.
                </ExtraPagesDetails>
                <ExtraPagesDetails>
                    <strong>14.	القانون الواجب التطبيق والاختصاص القضائي</strong>
                </ExtraPagesDetails>
                <ExtraPagesDetails mb={isMobile ? '50px' : '200px'}>
                تخضع هذه الشروط وتُفسر وفقًا للأنظمة المعمول بها بالمملكة العربية السعودية. أي نزاع ينشأ عن أو يتعلق بهذه الشروط سيتم إحالته إلى المحاكم المختصة في المملكة العربية السعودية.
                </ExtraPagesDetails>
                </Box>
            </Box>
        </Box>
  )
}

export default TermsAndConditions