import React, { useState, useRef, useEffect } from 'react'
import { useAuth } from 'context/auth'
import { GridContainer, PageTitle, GridItem, TitleItemLight, TitleItem, LinkNav, LinkNavDarker, SelectSpeciality, CustomInputLabel, AddButton, InputLarge, InputSmall, AddCertTextLight, ButtonSubmit, SpecialityItem, MenuItemCustom } from './EditAccountStyles'
import { useMediaQuery, Typography, Grid, Select, Button, FormControl, InputLabel, MenuItem, Box, TextField } from '@mui/material'
import { Container } from 'react-bootstrap'
import { Navigate, useNavigate } from 'react-router-dom'
import PageNav from '../AccountComponents/PageNav'
import RTLProvider from 'helpers/RTLProvider'
import Certificate from 'components/EditAccountComponents/Certificate'
import Language from 'components/EditAccountComponents/Language'
import Experience from 'components/EditAccountComponents/Experience'
import { updateUserInfo, getCurrentUserData, uploadImage, deleteImage } from 'api/profile'
import LoadingBackDrop from 'components/Loading/LoadingBackDrop'
import { categories } from 'constants/user'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import SuccessMessage from 'components/SuccessMessage/SuccessMessage'
import { getAllCities } from 'api/provinces'
import { AutocompleteFilter } from 'components/LawyersListing/LawyersListingStyle'

const EditAccountSection = () => {
    const [user, setUser] = useState(null)
    const isMobile = useMediaQuery('(max-width:768px)')
    const navigate = useNavigate()
    const [description, setDescription] = useState('')
    const [userSpecialities, setUserSpecialities] = useState([])
    const [speciality, setSpeciality] = useState()
    const [userCertificates, setUserCertificates] = useState([])
    const [certificate, setCertificate] = useState({ university: '', degree: '', year: '' })
    const [userLanguages, setUserLanguages] = useState([])
    const [language, setLanguage] = useState()
    const [userExperiences, setUserExperiences] = useState([])
    const [experience, setExperience] = useState({ company: '', position: '', startDate: '', endDate: '' })
    const [social, setSocial] = useState({ twitter: '', mobile_number: '', linkedin: '', city: '', email: '' })
    const maxChars = 1000
    const [selectedFile, setSelectedFile] = useState()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [cities, setCities] = useState([])
    const characterRegex = /^(?!.*(<.*?>|javascript:|(\b(on[a-zA-Z]*=))|(\b(SELECT|INSERT|UPDATE|DELETE|DROP|CREATE|ALTER|EXEC|UNION|TRUNCATE|REPLACE)\b|['";]|--|\/\*|\*\/)))([\u0600-\u06FFa-zA-Z\s?]+|[\u0600-\u06FFa-zA-Z\s?]+(?: [\u0600-\u06FFa-zA-Z\s?]+)*)$/

    const fetchCities = async () => {
        try {
          setLoading(true)
          const list = await getAllCities()
          setCities(list.result)
          setLoading(false)
        } catch (err) {
          setError(err.message)
          setLoading(false)
        }
      }
    
      useEffect(() => {
          fetchCities()
      }, [])

    const setErrorMessage = (msg) => {
        setError(msg)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        setTimeout(()=>setError(false), 5000)
    }

    const setSuccessMessage = (msg) => {
        setSuccess(msg)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        setTimeout(()=>setSuccess(false), 5000)

    }

    useEffect(() => {
        if (user) {
            setSelectedFile(`https://storage.googleapis.com/qanooni-lawyer-image/${user?.id}`)
            setUserSpecialities(user?.specializations ? user.specializations : [])
            setUserCertificates(user?.certificates?.filter(cert => cert !== null) || [])
            setUserLanguages(user?.languages ? user.languages : [])
            setUserExperiences(user?.experience?.filter(experience => experience !== null).map(experience => ({
                ...experience,
                startDate: experience.startDate?? experience.start_date,
                endDate: experience.endDate?? experience.end_date
            })) || [])
            setSocial(user?.social_links[0] ? {...user.social_links[0], city: user.city} : [])
            setDescription(user?.description ? user.description : '')
            console.log(user?.experience)
        }
        return
    }, [user])

    const inputFileRef = useRef(null)

    const handleLinkNavClick = () => {
        inputFileRef.current.click() // Open file dialog
    }

    const handleFileChange = async (event) => {
        setLoading(true)
        const file = event.target.files[0] 
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/png']
            if (!allowedTypes.includes(file.type)) {
                setErrorMessage('فقط ملفات .jpg و .png مسموح بها')
                setLoading(false)
                return
            }
    
            const formData = new FormData()
            formData.append('file', file)
            try {
                const data = await uploadImage({ formData, id: user.id })
                await updateProfileData(data.url, false)
                setSuccessMessage(data.message)
                setLoading(false)
                return data.url
            } catch (error) {
                setErrorMessage(error.message)
                setLoading(false)
            }
        } else {
            setLoading(false)
            setErrorMessage('لم يتم اختيار أي ملف')
        }
    }

    const updateProfileData = async (newUrl, setToNull) => {
        const newFileSrc = `https://storage.googleapis.com/qanooni-lawyer-image/${user?.id}`
        setSelectedFile(setToNull ? './top-lawyer-default.svg' : newFileSrc)

    }

    const deleteProfilePicture = async () => {
        if (selectedFile == './top-lawyer-default.svg'){
            setErrorMessage("لم يتم اختيار صورة")
            return
        }
        setLoading(true)
        try {
            const data = await deleteImage({ id: user.id })
            await updateProfileData('', true)
            setSuccessMessage(data.message)
            setLoading(false)
            return data
        } catch (error) {
            setErrorMessage(error.message)
            setLoading(false)
        }
    }

    // This useEffect is only for setting the user from local storage
    useEffect(() => {
        const token = localStorage.getItem('authToken')
        const userData = JSON.parse(localStorage.getItem('userData'))
        if (token && userData) {
            setUser(userData)
        }
    }, []) // Empty dependency array means this runs once on mount

    const addSpeciality = () => {
        if (!speciality){
            setErrorMessage("اختر التخصص أولاً")
            return
        }
        if (userSpecialities.includes(speciality)){
            setErrorMessage("التخصص موجود بالفعل في القائمة")
            return
        }
        if (speciality && !userSpecialities.includes(speciality)) {
            setUserSpecialities(prevSpecialities => [...prevSpecialities, speciality])
        }
    }
    const validateFields = () => {
        const twitterRegex = /^https?:\/\/(www\.)?x\.com(\/.*)?$/
        const linkedinRegex = /^https?:\/\/(www\.)?linkedin\.com(\/.*)?$/
        if (social.mobile_number !== '' && social.mobile_number !== null && social.mobile_number !== undefined && !/^\d{9}$/.test(social.mobile_number)) {
            return 'رقم الجوال يجب أن يكون 9 أرقام ويحتوي على أرقام فقط'
        }
        if (social.email !== '' && social.email !== null && social.email !== undefined && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(social.email)) {
            return 'صيغة البريد الالكتروني غير صحيحة'
        }
        if (social.twitter !== '' && social.twitter !== null && social.twitter !== undefined && !twitterRegex.test(social.twitter)) {
            return 'صيغة رابط Twitter(X) غير صحيحة'
        }
        if (social.linkedin !== '' && social.linkedin !== null && social.linkedin !== undefined && !linkedinRegex.test(social.linkedin)) {
            return 'صيغة رابط Linkedin غير صحيحة'
        }
        if (!description.trim() && description !== undefined && description !== null && description !== ''){
            return 'صيغة الوصف غير صحيحة'
        }
    }
    const onSubmit = async () => {
        setLoading(true)
        try {
            const errorMessage = validateFields()
            if (errorMessage) {
                setError(errorMessage)
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                })
                setTimeout(() => {
                    setError(false)
                }, 10000)
                setLoading(false)
                return
            }
            await updateUserInfo({ id: user?.id, data: { certificates: userCertificates, languages: userLanguages, specializations: userSpecialities, description: description, experience: userExperiences, social } })
            let userData = JSON.parse(localStorage.getItem('userData'))
            userData.certificates = userCertificates
            userData.languages = userLanguages
            userData.specializations = userSpecialities
            userData.description = description
            userData.experience = userExperiences
            userData.social_links = [social]
            userData.city = social?.city
            const updatedUserDataString = JSON.stringify(userData)
            localStorage.setItem('userData', updatedUserDataString)
            setSuccessMessage("تم التحديث بنجاح")
            setLoading(false)
        } catch (e) {
            setErrorMessage(e.message)
            setLoading(false)
        }
    }

    const removeSpeciality = (specialityToRemove) => {
        setUserSpecialities(prevSpecialities => prevSpecialities.filter(spec => spec !== specialityToRemove))
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value)
    }

    const addCertificate = () => {
        if (certificate.university == '' || certificate.degree == '' || certificate.year == '' || !certificate.university.trim() || !certificate.degree.trim()|| !certificate.year.trim()) {
            setErrorMessage("الرجاء ملء جميع الحقول")
            return
        }
        if (!characterRegex.test(certificate.degree) || !characterRegex.test(certificate.university)){
            setErrorMessage('الحقل يجب ألا يحتوي على رموز خاصة أو نصوص غير مسموحة ويجب أن يحتوي فقط على أحرف عربية أو إنجليزية')
            return
        }
        if (certificate && !userCertificates.some(cert => JSON.stringify(cert) === JSON.stringify(certificate))) {
            setUserCertificates(prevCer => {
                const updatedCertificates = [...prevCer, certificate]
                updatedCertificates.sort((b, a) => new Date(a.year) - new Date(b.year))
                return updatedCertificates
            })
            setCertificate({ university: '', degree: '', year: '' }) // Clear the fields after adding
        }
    }

    const removeCertificate = (cetrificateToRemove) => {
        setUserCertificates(prevCertificates => prevCertificates.filter(cert => cert !== cetrificateToRemove))
    }

    const handleCertificateChange = (field, value) => {
        setCertificate(prevCertificate => ({ ...prevCertificate, [field]: value }))
    }

    const addExperience = () => {
        if (experience.company == '' || experience.position == '' || experience.startDate == '' || !experience.company.trim() || !experience.position.trim()) {
            setErrorMessage("الرجاء ملء جميع الحقول")
            return
        }
        if (experience.startDate >  experience.endDate &&  (experience.startDate != '' && experience.endDate != '')) {
            setErrorMessage("تاريخ بداية العمل لا يمكن أن يكون قبل تاريخ نهاية العمل")
            return
        }
        if (!characterRegex.test(experience.company) || !characterRegex.test(experience.position)){
            setErrorMessage('الحقل يجب ألا يحتوي على رموز خاصة أو نصوص غير مسموحة ويجب أن يحتوي فقط على أحرف عربية أو إنجليزية')
            return
        }
        if (experience && !userExperiences.some(exp => JSON.stringify(exp) === JSON.stringify(experience))) {
            if (experience.endDate == ''){
                experience.endDate = "الحالي"
            }
            setUserExperiences(prevEx => {
                const updatedExperiences = [...prevEx, experience]
                updatedExperiences.sort((b, a) => new Date(a.startDate) - new Date(b.startDate))
                return updatedExperiences
            })
            setExperience({ company: '', position: '', startDate: '', endDate: '' }) 
        }
    }

    const removeExperience = (experienceToRemove) => {
        setUserExperiences(prevEx => prevEx.filter(exp => exp !== experienceToRemove))
    }

    const handleExperienceChange = (field, value) => {
        setExperience(prevEx => ({ ...prevEx, [field]: value }))
    }

    const addLanguage = () => {
        if (!language){
            setErrorMessage("اختر اللغة أولاً")
            return
        }
        if (userLanguages.includes(language)){
            setErrorMessage("اللغة موجودة بالفعل في القائمة")
            return
        }
        if (language && !userLanguages.includes(language)) {
            setUserLanguages(prevLanguages => [...prevLanguages, language])
        }
    }

    const removeLanguage = (languageToRemove) => {
        setUserLanguages(prevLanguages => prevLanguages.filter(lang => lang !== languageToRemove))
    }

    const handleSocialChange = (field) => (event) => {
        setSocial({ ...social, [field]: event.target.value })
    }

    const handleImageError = () => {
        setSelectedFile(`${process.env.PUBLIC_URL}/top-lawyer-default.svg`)
    }

    return (
        <Box style={{ padding: (isMobile ? '0' : ''),  margin: (isMobile ? "" : '0 60px') }}>
            {error && <ErrorMessage message={error} />}
            {success && <SuccessMessage message={success} />}
            <GridContainer minHeight={isMobile ? 'calc(100vh - 296px)' : 'calc(100vh - 197px)'} paddingTop={isMobile ? '24px' : '50px'}>
                <div style={{ padding: (isMobile ? '0 12px' : '') }} >
                    <PageNav parentText={'حسابي'} childText={'ملفي التعريفي'} navigateTo='../lawyer'></PageNav>
                </div>
                <PageTitle style={{ padding: (isMobile ? '0 20px' : '') }}>ملفي التعريفي</PageTitle>
                <Grid mt={isMobile ? '14px' : '41px'}>

                    <GridItem style={{ justifyContent: (isMobile ? 'center' : '') }} >
                        <Grid display={isMobile ? 'grid' : 'flex'} gap={isMobile ? '24px' : '40px'} justifyContent='flex-start' justifyItems={isMobile ? 'center' : ''}>
                            <img src={selectedFile} onError={handleImageError} alt='User Profile'style={{borderRadius: '4px', width: '100px', height: '120px', objectFit: 'cover'}} />
                            <Grid display='grid' gap='11px' justifyContent='center' justifyItems={isMobile ? 'center' : ''} >
                                <TitleItem>الصورة الشخصية</TitleItem>
                                <TitleItemLight>يجب أن تكون الصورة بحجم 150 × 200 بكسل على الأقل وبتنسيق JPG أو PNG.</TitleItemLight>
                                <Grid display='flex' gap='42px' justifyContent='flex-start' mt='11px'>
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        ref={inputFileRef}
                                        onChange={handleFileChange}
                                    />
                                    <LinkNav onClick={handleLinkNavClick}>تغيير الصورة</LinkNav>
                                    <LinkNav onClick={deleteProfilePicture}>حذف الصورة</LinkNav>
                                </Grid>
                            </Grid>
                        </Grid>
                    </GridItem>

                    <GridItem >
                        <Grid display='grid' gap='19px' justifyContent='center' >
                            <TitleItem>الاسم</TitleItem>
                            <TitleItemLight>{user?.full_name}</TitleItemLight>
                        </Grid>
                    </GridItem>

                    <GridItem>
                        <Grid display='grid' justifyContent='flex-start'>
                            <TitleItem>التخصصات</TitleItem>
                            <Grid display='flex' gap='20px' mt='30px' flexWrap='wrap'>
                                {Array.isArray(userSpecialities) ? userSpecialities?.map((specialityText, index) => (
                                    <SpecialityItem key={index} >
                                        {specialityText}
                                        <img width='10px' alt="cross" src='./cross-brown.svg' style={{cursor: 'pointer'}} onClick={() => removeSpeciality(specialityText)} />
                                    </SpecialityItem>
                                )) : {}}
                            </Grid>
                            <LinkNavDarker mt={isMobile ? '52px' : '74px'}>اضف تخصص جديد</LinkNavDarker>
                            <Grid display={isMobile ? 'grid' : 'flex'} gap='20px' justifyContent='flex-start' mt='8px' alignItems='center'>
                                <RTLProvider>
                                    <FormControl sx={{ m: 0, minWidth: 120 }}>
                                        <CustomInputLabel >اختر التخصص</CustomInputLabel>
                                        <SelectSpeciality
                                            label="اختر التخصص"
                                            value={speciality}
                                            onChange={(e) => setSpeciality(e.target.value)}
                                        >
                                            {categories.map((name) => (
                                               <MenuItemCustom key={name} value={name}>{name}</MenuItemCustom>
                                            ))}
                                        </SelectSpeciality>
                                    </FormControl>
                                </RTLProvider>
                                <AddButton onClick={addSpeciality}>اضافة</AddButton>
                            </Grid>
                        </Grid>
                    </GridItem>

                    <GridItem>
                        <Grid display='grid' justifyContent='flex-start'>
                            <Grid display='flex' justifyContent='space-between' alignItems='center'>
                                <TitleItem>نبذة</TitleItem>
                                <TitleItemLight>{description.length}/{maxChars} حرف.</TitleItemLight>
                            </Grid>
                            <RTLProvider>
                                <InputLarge
                                    label='أضف نبذة مختصرة عنك ...'
                                    multiline
                                    rows={6}
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    inputProps={{ maxLength: maxChars }} />
                            </RTLProvider>
                        </Grid>
                    </GridItem>

                    <GridItem display='flex!important'>
                        <Grid justifyContent='flex-start' width='100%'>
                            <Grid display='flex' justifyContent='space-between' alignItems='flex-start' mb={'50px'} >
                                <TitleItem>الشهادات</TitleItem>
                                <img src='./arrow_down.svg' alt='arrow' />
                            </Grid>
                            {Array.isArray(userCertificates) ? userCertificates?.map((cert, index) => (
                                <Certificate key={index} university={cert.university} degree={cert.degree} year={cert.year} onDelete={() => { removeCertificate(cert) }} />
                            )) : {}}
                            <div style={{ marginTop: isMobile ? '40px' : '88px' }}>
                                <LinkNavDarker>إضافة خبرة جديدة</LinkNavDarker>
                            </div>

                            <RTLProvider>
                                <Grid display={isMobile ? 'grid' : 'flex'} gap='20px' justifyContent='flex-start' mt='8px' alignItems='center'>
                                    <InputSmall
                                        label='اسم الجامعة'
                                        value={certificate.university}
                                        onChange={(e) => handleCertificateChange('university', e.target.value)}
                                        style={{ width: (isMobile ? '' : '20vw') }} />
                                    <InputSmall
                                        label='الدرجة والتخصص'
                                        value={certificate.degree}
                                        onChange={(e) => handleCertificateChange('degree', e.target.value)}
                                        style={{ width: (isMobile ? '' : '20vw') }} />
                                    <FormControl sx={{ m: 0, minWidth: 120 }}>
                                        <CustomInputLabel >سنة التخرج</CustomInputLabel>
                                        <SelectSpeciality
                                            label="سنة التخرج"
                                            value={certificate.year}
                                            onChange={(e) => handleCertificateChange('year', e.target.value)}
                                            style={{ width: (isMobile ? '' : '20vw') }}>
                                            {
                                                Array.from({ length: 2024 - 1970 + 1 }, (_, i) => 1970 + i).map((year, index) => (
                                                    <MenuItem key={index} value={year.toString()}>{year}</MenuItem>
                                                ))
                                            }
                                        </SelectSpeciality>
                                    </FormControl>
                                    <AddButton onClick={addCertificate}>اضافة</AddButton>
                                </Grid>
                            </RTLProvider>
                        </Grid>
                    </GridItem>

                    <GridItem display='flex!important'>
                        <Grid justifyContent='flex-start' width='100%'>
                            <Grid display='flex' justifyContent='space-between' alignItems='flex-start' mb={'50px'} >
                                <TitleItem>اللغات</TitleItem>
                                <img src='./arrow_down.svg' alt='arrow' />
                            </Grid>
                            {Array.isArray(userLanguages) ? userLanguages?.map((language, index) => (
                                <Language key={index} language={language} onDelete={() => { removeLanguage(language) }} />
                            )) : {}}
                            <div style={{ marginTop: isMobile ? '63px' : '54px' }}>
                                <LinkNavDarker>إضافة خبرة جديدة</LinkNavDarker>
                            </div>
                            <Grid display={isMobile ? 'grid' : 'flex'} gap='20px' justifyContent='flex-start' mt='8px' alignItems='center'>
                                <RTLProvider>
                                    <FormControl sx={{ m: 0, minWidth: 120 }}>
                                        <CustomInputLabel >اختر اللغة</CustomInputLabel>
                                        <SelectSpeciality
                                            label="اختر اللغة"
                                            value={language}
                                            onChange={(e) => setLanguage(e.target.value)}
                                        >
                                            <MenuItemCustom value='العربية'>العربية</MenuItemCustom>
                                            <MenuItemCustom value='الانجليزية'>الانجليزية</MenuItemCustom>
                                        </SelectSpeciality>
                                    </FormControl>
                                </RTLProvider>
                                <AddButton onClick={addLanguage}>اضافة</AddButton>
                            </Grid>

                        </Grid>
                    </GridItem>

                    <GridItem display='flex!important'>
                        <Grid justifyContent='flex-start' width='100%'>
                            <Grid display='flex' justifyContent='space-between' alignItems='flex-start' mb={'50px'} >
                                <TitleItem>الخبرات</TitleItem>
                                <img src='./arrow_down.svg' alt='arrow' />
                            </Grid>
                            {Array.isArray(userExperiences) ? userExperiences?.map((exp, index) => (
                                <Experience key={index} company={exp.company} position={exp.position} startDate={exp.startDate} endDate={exp.endDate} onDelete={() => { removeExperience(exp) }} />
                            )) : {}}
                            <div style={{ marginTop: isMobile ? '40px' : '88px' }}>
                                <LinkNavDarker>إضافة خبرة جديدة</LinkNavDarker>
                            </div>
                            <AddCertTextLight>جميع الحقول مطلوبة</AddCertTextLight>
                            <RTLProvider>
                                <Grid display='grid' gap='30px' justifyContent='flex-start' mt='8px' alignItems='center'>
                                    <InputSmall
                                        value={experience.company}
                                        onChange={(e) => handleExperienceChange('company', e.target.value)}
                                        style={{ maxWidth: (isMobile ? '402px' : '440px') }}
                                        label='اسم الشركة' />
                                    <InputSmall
                                        value={experience.position}
                                        onChange={(e) => handleExperienceChange('position', e.target.value)}
                                        style={{ maxWidth: (isMobile ? '402px' : '440px') }}
                                        label='الوظيفة' />
                                    <Grid display={isMobile ? 'grid' : 'flex'} width={isMobile ? '' : '440px'} gap='20px' >
                                        {/* <InputSmall
                                            value={experience.startDate}
                                            onChange={(e) => handleExperienceChange('startDate', e.target.value)}
                                            label='تاريخ بداية العمل' /> */}
                                        <FormControl sx={{ m: 0 }}>
                                            <CustomInputLabel >تاريخ بداية العمل</CustomInputLabel>
                                            <SelectSpeciality
                                                label='تاريخ بداية العمل'
                                                value={experience.startDate}
                                                onChange={(e) => handleExperienceChange('startDate', e.target.value)}
                                                style={{ width: (isMobile ? '' : '210px') }}>
                                                {
                                                    Array.from({ length: 2024 - 1970 + 1 }, (_, i) => 1970 + i).map((year, index) => (
                                                        <MenuItem key={index} value={year.toString()}>{year}</MenuItem>
                                                    ))
                                                }
                                            </SelectSpeciality>
                                        </FormControl>
                                        {/* <InputSmall
                                            value={experience.endDate}
                                            onChange={(e) => handleExperienceChange('endDate', e.target.value)}
                                            label='تاريخ نهاية العمل' /> */}
                                        <FormControl sx={{ m: 0 }}>
                                            <CustomInputLabel >تاريخ نهاية العمل</CustomInputLabel>
                                            <SelectSpeciality
                                                label='تاريخ نهاية العمل'
                                                value={experience.endDate}
                                                onChange={(e) => handleExperienceChange('endDate', e.target.value)}
                                                style={{ width: (isMobile ? '' : '210px') }}>
                                                {
                                                    Array.from({ length: 2024 - 1970 + 1 }, (_, i) => 1970 + i).map((year, index) => (
                                                        <MenuItem key={index} value={year.toString()}>{year}</MenuItem>
                                                    ))
                                                }
                                                <MenuItem value={'الحالي'}>الحالي</MenuItem>
                                            </SelectSpeciality>
                                        </FormControl>
                                    </Grid>
                                    <AddButton onClick={addExperience} style={{ maxWidth: (isMobile ? '402px' : '440px') }} >اضافة</AddButton>
                                </Grid>
                            </RTLProvider>
                        </Grid>
                    </GridItem>

                    <GridItem display='flex!important'>
                        <Grid justifyContent='flex-start' width='100%'>
                            <Grid display='flex' justifyContent='space-between' alignItems='flex-start' mb={'50px'} >
                                <TitleItem>التواصل</TitleItem>
                                <img src='./arrow_down.svg' alt='arrow' />
                            </Grid>
                            <TitleItemLight>أدخل الرابط كاملاً لملفك الشخصي على مواقع الشبكات الاجتماعية </TitleItemLight>
                            <RTLProvider>
                                <Grid display={isMobile ? 'grid' : 'flex'} flexWrap='wrap' gap='30px' justifyContent='flex-start' mt='45px' alignItems='center' maxWidth='1000px'>
                                    <InputSmall
                                        style={(isMobile ? { maxWidth: '402px', order: 0 } : { maxWidth: '440px' })}
                                        label='Twitter (X)'
                                        value={social.twitter}
                                        onChange={handleSocialChange('twitter')} />
                                    <InputSmall
                                        style={(isMobile ? { maxWidth: '402px', order: 1 } : { maxWidth: '440px' })}
                                        label='Linkedin'
                                        value={social.linkedin}
                                        onChange={handleSocialChange('linkedin')} />
                                    <InputSmall
                                        style={(isMobile ? { maxWidth: '402px', order: 3 } : { maxWidth: '440px' })}
                                        label='رقم الجوال'
                                        value={social.mobile_number}
                                        onChange={handleSocialChange('mobile_number')} />
                                    <InputSmall
                                        style={(isMobile ? { maxWidth: '402px', order: 2 } : { maxWidth: '440px' })}
                                        label='البريد الالكتروني'
                                        value={social.email}
                                        onChange={handleSocialChange('email')}
                                         />
                                    <FormControl sx={{ order: 4, maxWidth: '440px' }}>
                                        <AutocompleteFilter
                                            style={(isMobile ? { maxWidth: '96%', order: 2 } : { maxWidth: '440px' })}
                                            value={cities.find(c => c.city === social.city) || null}
                                            onChange={(event, newValue) => {
                                                const cityValue = newValue ? newValue.city : ""
                                                setSocial({ ...social, ['city']: cityValue })
                                            }}
                                            options={cities}
                                            getOptionLabel={(option) => option.city}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="المدينة"
                                                    InputLabelProps={{
                                                        sx: {
                                                            color: '#a0a0a0',
                                                            transform: social.city ? 'translate(10px, 0px)' : 'translate(10px, 11px)',
                                                            fontSize: social.city ? "12px" : "18px",
                                                            '&.Mui-focused': {
                                                                color: '#a0a0a0',
                                                                transform: 'translate(10px, 0px)',
                                                                fontSize: "12px"
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                    <MenuItemCustom value={option.city} key={option.city_id}>
                                                        {option.city}
                                                    </MenuItemCustom>
                                                </li>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </RTLProvider>
                        </Grid>
                    </GridItem>
                </Grid>
                <LoadingBackDrop loading={loading} />
                <ButtonSubmit onClick={onSubmit} >حفظ التغييرات</ButtonSubmit>
            </GridContainer>
        </Box>
    )
}
export default EditAccountSection