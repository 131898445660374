/* eslint-disable no-undef */
import { Box, Grid, useMediaQuery } from '@mui/material'
import React from 'react'
import { OtherDetails, QuestionText, QuestionWrapper, SpecialityText, SpecialityWrapper } from './QuestionStyles'
import { DateItem } from 'components/NewQuestions/NewQuestionsStyles'
import { useNavigate } from 'react-router-dom'
import { formatDateArabic } from 'helpers/formatTime'
import SpecializationItem from 'components/LawyerComponents/SpecializationItem'

const QuestionBox = ({question}) => {
    const isMobile = useMediaQuery('(max-width:768px)')
    const navigate = useNavigate()
    const handleSpecializationClick = (text) => {
      sessionStorage.setItem('quesitonsSpecializations', JSON.stringify([text]))
      navigate('/questions-list')
    }
  
  return (
    <QuestionWrapper width='1380px' mr={isMobile? '20px':'60px'}>
        <Box pt='30px' pr='32px'>
        <Grid display='flex' gap='18px'>
                <img src={`${process.env.PUBLIC_URL}/NavbarLogo.svg`} alt='logo' width='68.6px' />
                {question?.created_at && <DateItem>| {formatDateArabic(question?.created_at)}</DateItem>}
        </Grid>
        <QuestionText mt='27px'>{question?.question}</QuestionText>
        <OtherDetails mt='21px' ml='37px'>{question?.other_details}</OtherDetails>
        <Box display='flex' alignItems='center' pb={isMobile?'30px':'18px'} pr={isMobile? '0':2} mt='27px' gap={isMobile? '10px':'40px'} flexWrap='wrap'>
        {question?.specializations?.map((speciality, index) => (
         <SpecializationItem text={speciality} key={index} handleClick={handleSpecializationClick}/> 
        ))}
        </Box>
        </Box>
    </QuestionWrapper>
  )
}

export default QuestionBox