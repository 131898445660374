import React from 'react'
import { Box, Grid,  useMediaQuery } from '@mui/material'
import { LawyerItemTitle } from '../LawyersProfile/LawyersProfileStyles'

const LawyerTitleItem = ({ text, marginTop, id }) => {
    const isMobile = useMediaQuery('(max-width:768px)')

    return (
        <Grid id = {id} display='flex' gap={isMobile ? '12px' : '20px'} mt={marginTop} mr={isMobile ? '20px' : ''} alignItems='center' >
            <Box width='6px' height='29px' bgcolor='#82592533'></Box>
            <LawyerItemTitle >{text}</LawyerItemTitle>
        </Grid>
    )
}

export default LawyerTitleItem