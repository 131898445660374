import { Box, useMediaQuery } from '@mui/material'
import React from 'react'
import { ExtraPagesDetails, ExtraPagesHeader, ExtraPagesHeading, ExtraPagesSubHeader, ExtraPagesTitle } from './AboutusStyles'

const AboutusComponent = () => {
    const isMobile = useMediaQuery('(max-width:768px)')

    return (
        <Box>
            <ExtraPagesHeading>
                <ExtraPagesHeader>حول قانوني</ExtraPagesHeader>
                <ExtraPagesSubHeader>تحديات قانونية تتطلب فهمًا دقيقًا واستشارة قانونية مختصة.</ExtraPagesSubHeader>
            </ExtraPagesHeading>
            <Box mr={isMobile?'30px':'60px'} mt='62px'>
                <ExtraPagesTitle mb='49px'>قانوني بين القانون والتدقيق </ExtraPagesTitle>
                <Box ml={isMobile?'30px' :'78px'}>
                <ExtraPagesDetails>قانوني هو منصتك القانونية الشاملة التي تسهل عليك الوصول إلى الخدمات القانونية المتخصصة بكل سهولة. سواء كنت بحاجة إلى استشارة قانونية سريعة أو تبحث عن محامٍ معتمد في مجال معين، يتيح لك قانوني القيام بذلك بكل يسر عبر الإنترنت.</ExtraPagesDetails>
                <ExtraPagesDetails mt='26px'>يتميز الموقع بتصميم مبتكر يضع احتياجاتك في المقام الأول. عبر دليل المحامين، يمكنك البحث بسهولة عن المحامي الأنسب لك بناءً على تخصصه وموقعه الجغرافي، مما يوفر لك الوقت والجهد في العثور على المحامي المناسب لقضيتك.</ExtraPagesDetails>
                <ExtraPagesDetails mt='26px'>أما إذا كنت تفضل الحصول على استشارات سريعة، فقسم الأسئلة والأجوبة هو المكان المثالي لك. هنا، يمكنك طرح استفساراتك القانونية والحصول على إجابات موثوقة من محامين ذوي خبرة، مما يمنحك الثقة والمعرفة اللازمة لاتخاذ القرارات الصحيحة.</ExtraPagesDetails>
                <ExtraPagesDetails mt='26px' mb={isMobile? '158px':'660px'}>مع قانوني، أصبح الوصول إلى الخبرة القانونية أكثر سهولة وشفافية من أي وقت مضى.</ExtraPagesDetails>
                </Box>
            </Box>
        </Box>
    )
}

export default AboutusComponent