import { Box, Grid, useMediaQuery } from '@mui/material'
import PhoneTextField from 'components/FormComponents/PhoneTextField'
import { ButtonText, Container, CustomButton } from 'components/Login/LoginStyles'
import React, { useState } from 'react'
import { ForgetPasswordText } from './ForgetPasswordStyles'
import { forgetPassword } from 'api/auth'
import LoadingBackDrop from 'components/Loading/LoadingBackDrop'
import { useNavigate } from 'react-router-dom'

const ForgetPasswordBox = ({ setError, onLoaded }) => {
    const [mobile, setMobile] = useState('')
    const [loading, setLoading] = useState(false)
    
    const navigate = useNavigate()
    const gridStyles = { display: 'flex', justifyContent: 'center', alignItems: 'center' }
    const isMobile = useMediaQuery('(max-width:600px)')

    const validateFields = () => {
      if (!mobile) {
        return 'رقم الجوال مطلوب'
      }
      if (mobile.length != 9) {
        return 'رقم الجوال يجب ان يكون 9 ارقام'
      }
      return null
    }
  
    const onForgetPassword = async () => {
        setLoading(true)
      const errorMessage = validateFields()
      if (errorMessage) {
        setError(errorMessage)
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        setLoading(false)
        return
      }
        try {
          await forgetPassword({ mobile_number: mobile })
          navigate('/forget-password-otp', { state: { mobile_number: mobile } })
          setLoading(false)
        } catch (error) {
          setError(error.message.split('Error: ')[1])
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
          setLoading(false)
        }
      }
  return (
        <Box position='relative' maxWidth="790px" maxHeight="100" mx='auto' zIndex={10} display={isMobile ? 'flex' : 'block'}>
              <Container sx={{ zIndex: '10 !important', position: 'relative' }} marginTop="40px" customHeight="600px">
                <Grid container justifyContent='center' alignItems={isMobile ? 'start' : 'center'} width={isMobile ? '300px' : '100%'}>
                  <Grid item xs={12} md={12} mt={isMobile? '53px' : '80px'} sx={gridStyles}>
                    <ForgetPasswordText >يرجى إدخال رقم الجوال أدناه للحصول على رمز إعادة تعيين كلمة المرور</ForgetPasswordText>
                  </Grid>
                  <Grid item xs={12} md={12} mt={isMobile ? '23px' : '52px'} sx={gridStyles}>
                        <PhoneTextField value={mobile} setValue={(e) => setMobile(e.target.value)} CustomWidth={isMobile ? '300px' : '380px'} />
                    </Grid>
                    <Grid item xs={12} md={12} mt={isMobile? '80px': '66px'} mb={isMobile? '170px': '256px'} sx={gridStyles}>
                    <CustomButton onClick={onForgetPassword} CustomColor='#825925' CustomWidth={isMobile ? '300px' : '380px'}>
                        <ButtonText customFontSize='18px'>إعادة تعيين كلمة المرور</ButtonText>
                        <img src='login-arrow.svg' />
                    </CustomButton>
                    </Grid>
                </Grid>
            </Container>
            {!useMediaQuery('(max-width:990px)') && <Box overflow={'overlay'} mt={-12} mr={-30}>
                <img src='login-background.svg' alt='forgetpassword' onLoad={onLoaded} />
            </Box>}
            <LoadingBackDrop loading={loading}/>
        </Box>
  )
}

export default ForgetPasswordBox