import React, { useRef, useEffect } from 'react'
import { OTPInput } from './OTPStyles'
import { Box } from '@mui/material'

const OTPField = ({ value, onChange, isMobile }) => {
  const styles = { display: 'flex', justifyContent: "center", width: '380px', maxWidth: '252px' }
  // eslint-disable-next-line
  const inputRefs = Array(4).fill(null).map(() => useRef(null))

  const handleInputChange = (index, event) => {
    if (event.target.value.length === 1) {
      if (index < 3) {
        inputRefs[index + 1].current.focus()
      }
    }
    if (event.target.value.length === 0) {
      if (index > 0) {
        inputRefs[index - 1].current.focus()
      }
    }
    let newValues = [...value]
    newValues[index] = event.target.value
    onChange(newValues.join(''))
  }

  useEffect(() => {
    // This is to ensure if user pastes a code, it spreads across the inputs
    if (value.length && value.length <= 4) {
      value.split('').forEach((num, index) => {
        if (inputRefs[index].current) {
          inputRefs[index].current.value = num
        }
      })
    }
  }, [value])

  return (
    <Box style={styles} gap={isMobile ? '25px' : '41px'}>
      {Array(4).fill(null).map((_, index) => (
        <OTPInput
          key={index}
          ref={inputRefs[index]}
          maxLength={1}
          type='text'
          inputMode='numeric'
          value={value[index] || ''}
          onChange={(e) => handleInputChange(index, e)}
        />
      ))}
    </Box>
  )
}

export default OTPField