import React from 'react'
import { Grid, useMediaQuery } from '@mui/material'
import { ReviewDateText, ReviewName, ReviewText } from '../LawyersProfile/LawyersProfileStyles'
import Stars from './Stars'

const Review = ({ date, rate, review, name, lawyer }) => {
    const isMobile = useMediaQuery('(max-width:768px)')

    return (
        <Grid display='grid' mb={isMobile ? '20px' : '30px'} mr={isMobile ? '25px' : '60px'} ml={isMobile ? '15px' : '0'}>
            <Grid display='flex' gap='20px'  >
                <Grid display='grid' gap='4px'>
                    <Stars rate={rate} size='15px' />
                    <ReviewDateText>{date}</ReviewDateText>
                </Grid>
                <ReviewName>{name}</ReviewName>
            </Grid>
            <ReviewText mt='8px' width='78%'>{review}</ReviewText>
        </Grid>

    )
}

export default Review