import BASE_URL from "./BaseURL"

export const login = async userData => {
    try {
      const response = await fetch(`${BASE_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }
  
export const signup = async userData => {
    try {
      const response = await fetch(`${BASE_URL}/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }

export const verifyOTP = async OTP => {
    try {
      const response = await fetch(`${BASE_URL}/verify-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(OTP)
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }
  
  export const resendOTP = async OTP => {
    try {
      const response = await fetch(`${BASE_URL}/resend-otp`, {
        method: 'Post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(OTP)
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }
  export const forgetPassword = async OTP => {
    try {
      const response = await fetch(`${BASE_URL}/forget-password`, {
        method: 'Post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(OTP)
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }
    
  export const resetPassword = async passwordData => {
    try {
      const response = await fetch(`${BASE_URL}/reset-password`, {
        method: 'Post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(passwordData)
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }
  
  export const getUserStatus = async id => {
    try {
      const response = await fetch(`${BASE_URL}/user-status/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      })
      const data = await response.json()
      if (data.error) {
        throw Error(data.message)
      }
      return data
    } catch (error) {
      throw Error(error.message) || error
    }
  }
