import styled from "@emotion/styled"
import { Box, Typography } from "@mui/material"

export const SuccessWrapper = styled(Box)`
width: 98%;
height: 55px;
flex-shrink: 0;
border-radius: 10px;
background: #A3C37B;
margin: auto;
margin-top: 31px;
`

export const SuccessMessageText = styled(Typography)`
color: #FFF;
text-align: right;
font-family: Tajawal;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-right: 20px;
padding-top: 18px;
`