import React, { useState, useEffect } from 'react'
import { GridContainer, PageTitle, FilterButton, ClearButton, SpinningImage } from './NewQuestionsStyles'
import QuestionItem from 'components/QuestionComponents/Question'
import { useMediaQuery, Box, Grid, FormControl, MenuItem, Checkbox, Paper, List, ListItemText } from '@mui/material'
import { Container } from 'react-bootstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'
import PageNav from '../AccountComponents/PageNav'
import { getQuestions, getTotalCountFilter } from 'api/questions'
import { DeletePopupWrapper } from 'components/Account/AccountStyles'
import { FilterPopup, ListItemCustom, SelectFilter, InputLabelCustom } from 'components/QuestionsList/QuestionsListStyles'
import {
    FilterApplyButton,
    TitleGridPopup,
    PopupTitle
} from 'components/LawyersListing/LawyersListingStyle'
import RTLProvider from 'helpers/RTLProvider'
import { categories } from 'constants/user'
import LoadingBackDrop from 'components/Loading/LoadingBackDrop'

const debounce = (func, delay) => {
    let inDebounce
    return function () {
        const context = this
        const args = arguments
        clearTimeout(inDebounce)
        inDebounce = setTimeout(() => func.apply(context, args), delay)
    }
}

const NewQuestionsSection = () => {
    const [searchSpecializations, setSearchSpecializations] = useState([])
    const [filterChanged, setFilterChanged] = useState(false)
    const [anchor, setAnchor] = useState(null)
    const [questions, setQuestions] = useState([])
    const [loading, setLoading] = useState(false)
    const [totalQuestions, setTotalQuestions] = useState(0)
    const [offset, setOffset] = useState(0)
    const [searchParams] = useSearchParams()
    const isTest = searchParams.get('isTest') === 'true'
    const isMobile = useMediaQuery('(max-width:768px)')
    const navigate = useNavigate()
    const formattedQuestions = questions.map(question => {
        const date = new Date(question.created_at)
        const day = date.toLocaleDateString('en-US', { day: 'numeric' })
        const month = date.toLocaleDateString('ar-EG', { month: 'long' })
        const year = date.toLocaleDateString('en-US', { year: 'numeric' })
        const formattedDate = `${month} ${day} - ${year}`
        return {
            ...question,
            created_at: formattedDate
        }
    })
    const open = Boolean(anchor)
    const filterPopupItem = open ? 'simple-popup' : undefined
    const deletePopupOpen = async (event) => {
        event.stopPropagation() 
        setAnchor(anchor ? null : event.currentTarget)
        document.body.style.setProperty('overflow', (anchor ? 'unset' : 'hidden'))
    }
    const handleClose = () => {
        setAnchor(null)
    }
    const handleChange = (event) => {
        const value = event.target.value
        setSearchSpecializations(typeof value === 'string' ? value.split(',') : value)
    }
    const handleToggle = (value) => {
        const currentIndex = searchSpecializations.indexOf(value)
        const newChecked = [...searchSpecializations]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        setSearchSpecializations(newChecked)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const params = {
                    isTest
                }

                if (searchSpecializations !== '' && searchSpecializations.length !== 0) {
                    params.specialization = searchSpecializations
                }

                const totalCountFetch = await getTotalCountFilter(params)
                setTotalQuestions(totalCountFetch[0].count)
                setOffset(0)
                await loadQuestions(0)
            } catch (e) {
                console.error(`Error fetching data: ${e}`)
            }
        }
        setQuestions([])
        fetchData()
    }, [filterChanged])

    const loadQuestions = async (offset) => {
        setLoading(true)
        try {
            const params = {
                'offset': offset,
                isTest
            }
            if (searchSpecializations !== '' && searchSpecializations.length !== 0) {
                params.specialization = searchSpecializations
            }
            const data = await getQuestions(params)
            setQuestions(prevQuestions => {
                const existingIds = new Set(prevQuestions.map(q => q.question_id))
                const newQuestions = data.filter(q => !existingIds.has(q.question_id))
                return [...prevQuestions, ...newQuestions]
            })
        } catch (e) {
            console.log(`Error in getting questions: ${e}`)
        } finally {
            setLoading(false)
        }
    }

    const handleScroll = debounce(() => {
        const scrollPosition = window.innerHeight + document.documentElement.scrollTop
        const threshold = document.documentElement.offsetHeight - 400
        if (questions.length == totalQuestions) {
            return
        }
        if (scrollPosition >= threshold && questions.length < totalQuestions) {
            loadQuestions(offset + 4)
            setOffset(offset => offset + 4)
        }
    }, 100)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [questions, totalQuestions, offset])

    const applyFilters = async () => {
        setFilterChanged(prev => !prev) 
    }

    const clearFilter = async () => {
        setSearchSpecializations([])
        applyFilters()
    }
    return (
        <Box style={{ padding: (isMobile ? '0' : ''),  margin: (isMobile ? "" : '0 60px'), }} >
            <Box minHeight={isMobile ? 'calc(100vh - 556px)' : 'calc(100vh - 197px)'} paddingTop={isMobile ? '24px' : '50px'} mb='37px'>
                <GridContainer position='relative' >
                    <div style={{ padding: (isMobile ? '0 12px' : '') }} >
                        <PageNav parentText={'حسابي'} childText={'أسئلة تنتظرك!'} navigateTo='../lawyer'></PageNav>
                    </div>
                    <PageTitle style={{ padding: (isMobile ? '0 20px' : ''), marginBottom: (isMobile ? '0' : '83px') }} >{isMobile ? 'أسئلة لم يتم الإجابة عليها' : 'أسئلة تنتظرك!'}</PageTitle>
                    <Grid mt={isMobile ? '13px' : '3px'} style={{
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                    }} justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
                        <FilterButton style={{ padding: (isMobile ? '0 20px' : ''), marginBottom: (isMobile ? '16px' : '0') }} onClick={ deletePopupOpen }>
                            <img src={isMobile ? './filter-mobile.svg' : './filter.svg'} alt='filter' width='18px' />
                            تصفية حسب التخصص
                        </FilterButton>
                        <ClearButton
                            onClick={clearFilter}
                            style={ (searchSpecializations.length != 0) ? { display: 'block' } : {display: 'none'}}
                        >حذف خيارات التصفية</ClearButton>
                        {formattedQuestions.map((question, index) => (
                            <QuestionItem
                                key={index}
                                title={question.question}
                                content={question.other_details}
                                date={question.created_at}
                                questionLinkText={'قم بالإجابة عليها الآن'}
                                questionLink={question.question_id}
                            ></QuestionItem>
                        ))}
                        {loading && (
                            <SpinningImage src='./loading_question.svg' isMobile={isMobile} />
                        )}
                    </Grid>
                </GridContainer>
            </Box>
            <DeletePopupWrapper id={filterPopupItem} open={open} anchor={anchor} onClick={handleClose} >
                <FilterPopup  onClick={(e) => e.stopPropagation()}>
                    <TitleGridPopup>
                        <PopupTitle>تصفية حسب التخصص</PopupTitle>
                        <img onClick={deletePopupOpen} src="cross.svg" alt="cross" width={18} style={isMobile ? { order: '-1' } : { cursor: 'pointer' }} />
                    </TitleGridPopup>
                    <RTLProvider>
                        <Grid display='grid' width='100%' justifyContent='center' mt={isMobile ? '16px' : '110px'}>
                            {!isMobile && (
                                <FormControl sx={{ m: 0, minWidth: 120 }}>
                                    <InputLabelCustom id="demo-multiple-checkbox-label">التخصصات</InputLabelCustom>
                                    <SelectFilter
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={searchSpecializations}
                                        onChange={handleChange}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {categories.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                <Checkbox checked={searchSpecializations.indexOf(name) > -1}  />
                                                <ListItemText sx={{'span': {fontFamily: 'Tajawal'}}} primary={name} />
                                            </MenuItem>
                                        ))}
                                    </SelectFilter>
                                </FormControl>
                            )}
                            {isMobile && (
                                <Paper style={{ maxHeight: 250, overflow: 'auto', boxShadow: 'none', direction: 'ltr', width: '90vw', padding: '0' }}>
                                    <List style={{ display: 'grid', gap: '20px' }}>
                                        {categories.map((category) => (
                                            <ListItemCustom
                                                key={category}
                                                role={undefined}
                                                dense
                                                button
                                                onClick={() => handleToggle(category)}
                                            >
                                                <Checkbox
                                                    edge="start"
                                                    checked={searchSpecializations.indexOf(category) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                                <ListItemText primary={category} />
                                            </ListItemCustom>
                                        ))}
                                    </List>
                                </Paper>
                            )}

                            <FilterApplyButton style={isMobile ? { marginTop: '20px' } : { marginTop: 0 }} onClick={(e) => { e.stopPropagation(); applyFilters(); deletePopupOpen(e)  }}>عرض النتائج</FilterApplyButton>
                        </Grid>
                    </RTLProvider>
                </FilterPopup>
            </DeletePopupWrapper>
        </Box >
    )
}
export default NewQuestionsSection