/* eslint-disable no-undef */
import { Box, Rating, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AnswerTextField, AnswerUserAnswers, AnswerUserBox, AnswerUserLocation, AnswerUserVerified, AnswerUsername, AnswerWrapper, UserPhotoContainer, WriteAnswer } from './AnswerStyles'
import { ButtonText, CustomButton } from 'components/Login/LoginStyles'
import RTLProvider from 'helpers/RTLProvider'
import { useAuth } from 'context/auth'
import { addAnswer } from 'api/answers'
import { useParams, useSearchParams } from 'react-router-dom'
import LoadingBackDrop from 'components/Loading/LoadingBackDrop'
import { rules, validateFields } from 'helpers/validation'

const AddAnswer = ({setError}) => {
    const { user } = useAuth()
    const [answer, setAnswer] = useState('')
    const [loading, setLoading] = useState(false)
    const [isFocused, setIsFocused] = useState(false)
    let { id } = useParams()
    const isMobile = useMediaQuery('(max-width:768px)')
    const [imgSrc, setImgSrc] = useState(`${process.env.PUBLIC_URL}/edit-account-default.svg`)
    const fields = { answer }
    const [searchParams] = useSearchParams()
    const isTest = searchParams.get('isTest') === 'true'
    const validationRules = {
      answer: [
          rules.required('الاجابة', 'الاجابة مطلوبة')
      ]
    }
    useEffect(()=>{
      setImgSrc(`https://storage.googleapis.com/qanooni-lawyer-image/${user?.id}`)
    }, [user])

    const handleImageError = () => {
        setImgSrc(`${process.env.PUBLIC_URL}/edit-account-default.svg`)
    }

    const onAddAnswer = async () => {
        try {
          setLoading(true)
          const errorMessage = validateFields(fields, validationRules)
            if (errorMessage) {
            setError(errorMessage)
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
            setLoading(false)
            return
        }
          await addAnswer({answer, user_id: user?.id, question_id: id, isTest})
          setLoading(false)
          window.location.reload()
        } catch (e) {
          setLoading(false)
          console.log(`Error submitting form: ${e}`)
        }
    }

    return (
        <AnswerWrapper mr={isMobile? '0px':'60px'}>
            <Box pt='40px' pr='38px' mb='20px'>
            <Box display='flex' gap='10px'>
                <UserPhotoContainer>
                <Box 
      sx={{
        height:'120px' ,
        width: '100px',
        minWidth: '100px' ,
        maxWidth: '100px',
        margin: '0 auto',
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden', 
        borderRadius: '10px',
      }}
    >
      <img 
        src={imgSrc}
        alt='profile picture' 
        onError={handleImageError}
        style={{ 
          height: '100%', 
          width: '100%',
          objectFit: 'cover', 
          borderRadius: '4px',
        }} 
      />
    </Box>
                </UserPhotoContainer>
                <AnswerUserBox>
                    <AnswerUsername>{user?.full_name}</AnswerUsername>
                    <AnswerUserVerified>محامي مرخص</AnswerUserVerified>
                    <AnswerUserLocation>المدينة: {user?.city}</AnswerUserLocation>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Rating name="read-only" size="small" value={user?.rating} readOnly />
                        <Typography sx={{ mr: "6px", mt: "2px", fontWeight: '500', fontSize: '15px', lineHeight: 'normal' }}>
                            {Math.round(user?.rating) ?? 0}/5
                        </Typography>
                    </Box>
                    <AnswerUserAnswers>عدد الإجابات: ({answer?.total_answers_by_user ?? 0}) إجابة</AnswerUserAnswers>
                </AnswerUserBox>

            </Box>
            <Box mt={isMobile? '65px':'38px'}>
                <WriteAnswer>أكتب اجابتك</WriteAnswer>
                <RTLProvider>
                    <AnswerTextField
                        label='اكتب أجابتك هنا'
                        multiline
                        rows={isMobile? 12:4}
                        value={answer}
                        isFocused ={isFocused}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        onChange={(e) => setAnswer(e.target.value)}
                    />
                </RTLProvider>
                <Box mt='25px' pb={isMobile? '35px':'165px'}>
                    <CustomButton CustomWidth={isMobile? "100%":"190px"} CustomColor="#B4975A" onClick={onAddAnswer}>
                        <ButtonText>أرسل</ButtonText>
                    </CustomButton>
                </Box>
            </Box>
        </Box>
        <LoadingBackDrop loading={loading} />
        </AnswerWrapper>
    )
}

export default AddAnswer