import React, { useState } from 'react'
import { ButtonText, Container, CustomButton, ForgetPassword, NewAccountText } from './LoginStyles'
import { Box, Grid, useMediaQuery } from '@mui/material'
import PhoneTextField from 'components/FormComponents/PhoneTextField'
import PasswordTextField from 'components/FormComponents/PasswordTextField'
import { login } from 'api/auth'
import { useNavigate } from 'react-router-dom'
import LoadingBackDrop from 'components/Loading/LoadingBackDrop'
import { useAuth } from 'context/auth'
import { rules, validateFields } from 'helpers/validation'

const LoginBox = ({setError, onLoaded }) => {
  const [mobile, setMobile] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const {authLogin} = useAuth()
  const navigate = useNavigate()
  const gridStyles = { display: 'flex', justifyContent: 'center', alignItems: 'center' }
  const isMobile = useMediaQuery('(max-width:600px)')
  const fields = { mobile, password }

  const validationRules = {
    mobile: [
        rules.required('رقم الجوال '),
        rules.regex('رقم الجوال', /^\d{9}$/, 'رقم الجوال يجب أن يكون 9 أرقام ويحتوي على أرقام فقط')

    ],
    password: [
      rules.required('كلمة المرور'),
      rules.length('كلمة المرور', 8),
      rules.custom('كلمة المرور', (val) => val.trim().length >= 8, 'كلمة المرور يجب ألا تكون فارغة أو تحتوي فقط على مسافات')
    ]
}

  const onLogin = async () => {
    setLoading(true)
    const errorMessage = validateFields(fields, validationRules)
    if (errorMessage) {
      setError(errorMessage)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      setLoading(false)
      return
    }
    try {
      await authLogin({ mobile_number: mobile, password })
      navigate('/lawyer')
      setLoading(false)
    } catch (error) {
      setError(error.message)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      setLoading(false)
    }
  }

  return (
    <Box position='relative' maxWidth="790px" maxHeight="100" mx='auto' zIndex={10}>
    <Container width={isMobile?'380px': '100%'} sx={{zIndex: '10 !important', position: 'relative'}}>
      <Grid container justifyContent='center' alignItems='center' paddingX={isMobile ? '32px' : 0}>
        <Grid item xs={12} md={12} justifyContent='center' marginTop={isMobile? '52px':'100px'} sx={gridStyles}>
          <PhoneTextField value={mobile} setValue={(e) => setMobile(e.target.value)} CustomWidth={isMobile ? '300px' : '380px'} />
        </Grid>
        <Grid item xs={12} md={12} mt='40px' sx={gridStyles}>
          <PasswordTextField label='كلمة المرور' value={password} setValue={(e) => setPassword(e.target.value)} CustomWidth={isMobile ? '300px' : '380px'}/>
        </Grid>
        <Grid item xs={12} md={12} sx={gridStyles}>
          <ForgetPassword onClick={() => navigate('/forget-password')}>هل نسيت كلمة المرور ؟</ForgetPassword>
        </Grid>
        <Grid item xs={12} md={12} mt={isMobile? '47px':'48px'} sx={gridStyles}>
          <CustomButton onClick={onLogin} CustomColor='#825925' CustomWidth={isMobile ? '300px' : '380px'}>
            <ButtonText>تسجيل الدخول</ButtonText>
            <img src='login-arrow.svg' />
          </CustomButton>
        </Grid>
        <Grid item xs={12} md={12} mt={isMobile? '76px': '65px'} sx={gridStyles}>
          <NewAccountText>محامي وليس لديك حساب؟ سجل معنا الآن</NewAccountText>
        </Grid>
        <Grid item xs={12} md={12} mt={isMobile? '17px':'22px'} mb={isMobile?'105px': '67px'} sx={gridStyles}>
          <CustomButton onClick={() => navigate('/signup')} CustomWidth={isMobile ? '300px' : '380px'}>
            <ButtonText>إنشاء حساب جديد</ButtonText>
          </CustomButton>
        </Grid>
      </Grid>
    </Container>
      {!useMediaQuery('(max-width:990px)') && <Box overflow={'overlay'} mt={-12} mr={-30}>
        <img src='login-background.svg' alt='login' onLoad={onLoaded} />
      </Box>}
      <LoadingBackDrop loading={loading}/>
    </Box>
  )
}
export default LoginBox